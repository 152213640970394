import { Card } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import validationFileExtension from "../../validationFile";
import "./attachment.scss";
import { ReactComponent as DownloadIcon } from "../../img/icons/download.svg";
import { ReactComponent as DocumentIcon } from "../../img/icons/document-text.svg";
function Attachment({ attachments }) {
  const regIMG =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+(?:png|jpg|jpeg|gif|svg|bmp|webp|tiff?|apng|avif)+$/;

  return (
    <div className="row gap-5 attachment">
      {attachments?.length !== 0
        ? attachments?.map((attachment, index) => {
            return (
              <div
                key={index}
                className="d-flex align-items-center"
                style={{
                  maxWidth: "500px",
                  minHeight: "150px",
                  borderRadius: "10px",
                  background: "#483F50",
                }}
              >
                <div className="d-flex justify-content-between align-items-center w-100">
                  {regIMG?.test(attachment?.fileurl) ? (
                    <img
                      src={
                        attachment?.fileurl
                          ? attachment?.fileurl
                          : "/default/user.png"
                      }
                      alt=""
                      style={{
                        height: "120px",
                        width: "120px",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "120px",
                        width: "120px",
                        borderRadius: "10px",
                        background: "#E8E8E8",
                      }}
                    >
                      <DocumentIcon />

                      {/* {validationFileExtension(value?.fileurl)} */}
                    </div>
                  )}
                  <span
                    className="text-white montserrat-font"
                    style={{ fontSize: "23px" }}
                  >
                    {attachment?.fileurl?.split(".").pop()} file
                  </span>
                  <a
                    href={attachment?.fileurl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadIcon
                      className="text-white m-4"
                      style={{ height: "34px", width: "34px" }}
                    />
                  </a>
                </div>
              </div>
              // <Card className="card_attachment" key={index}>
              //   <Card.Body className="p-0">
              //     {regIMG?.test(value?.fileurl) ? (
              //       <a href={value?.fileurl} target="_blank" rel="noreferrer">
              //         <img
              //           src={
              //             value?.fileurl && value?.fileurl !== undefined
              //               ? value?.fileurl
              //               : "/default/user.png"
              //           }
              //           className="text-center"
              //           alt="user_image"
              //           style={{ width: "13rem", height: "14rem" }}
              //         />
              //       </a>
              //     ) : (
              //       <div className="file">
              //         <a href={value?.fileurl} target="_blank" rel="noreferrer">
              //           {validationFileExtension(value?.fileurl)}
              //         </a>
              //       </div>
              //     )}
              //   </Card.Body>
              // </Card>
            );
          })
        : null}
    </div>
  );
}

export default Attachment;
