import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import { useTranslation } from "react-i18next";
import Card from "../../Components/Card/card";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";

const NearByEvents = ({ nearByEvents }) => {
  const { t } = useTranslation();
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <MdKeyboardArrowLeft
        className={className}
        style={{
          ...style,
          color: "#483f50",
          height: "55px",
          width: "55px",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <MdKeyboardArrowRight
        className={className}
        style={{
          ...style,
          color: "#483f50",
          height: "55px",
          width: "55px",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: nearByEvents?.length > 2 ? 3 : nearByEvents?.length,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };
  return (
    <div>
      <h1 className="events-section-header">{t("Nearby Events")}</h1>

      {nearByEvents?.length > 0 ? (
        <div
          style={{
            background: "#EBD4FF",
            padding: "25px",
            borderRadius: "24px",
            minHeight: "367px",
          }}
        >
          <Swiper
            breakpoints={{
              100: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
            }}
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {nearByEvents?.map((event, index) => (
              <SwiperSlide key={index}>
                <Card className="card" event={event} index={index} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        // <Slider {...settings}>
        //   {nearByEvents?.map((event, index) => (
        //     <Card className="card" key={index} event={event} index={index} />
        //   ))}
        // </Slider>
        // <div className="row gap-3 p-3">
        //   {nearByEvents?.map((event, index) => (
        //     <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"><Card className="card" key={index} event={event} index={index} /></div>
        //   ))}
        // </div>

        <div
          className="events-div"
          style={{
            background: "#EBD4FF",
            padding: "20px",
            borderRadius: "24px",
          }}
        >
          <span className="events-section-header text-center">
            You Don't Have Any Nearby Event Right Now
          </span>
        </div>
      )}
    </div>
  );
};

export default NearByEvents;
