import { Modal } from "react-bootstrap";
import GoogleLog from "../../Google/googleLogin";
import "./loginModal.scss";

function LoginModal({ show, handleCloseLogin, lng, lat }) {
  return (
    <div>
      <Modal show={show} onHide={() => handleCloseLogin()}>
        <Modal.Header
          closeButton
          style={{ fontSize: "10px", borderBottom: "none" }}
          className="close"
        ></Modal.Header>
        <Modal.Body className="save-body flex flex-col justify-start items-start">
          <h5 className="text-xl mb-3 font-poppins">Log in or sign up with Google</h5>
          <div className="modal-btn">
            <GoogleLog
              lng={lng}
              lat={lat}
              handleCloseLogin={() => handleCloseLogin()}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default LoginModal;
