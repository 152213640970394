import React from "react";
import ProductCategories from "./ProductCategories";
import BharatShopIndex from "./BharatWebShopIndex";
import NewlyAddedItemsIndex from "./NewlyAddedItems";
import ShopPopularItemsIndex from "./ShopPopularItems";
import GoogleAdSense from "../../GoogleAdSense/GoogleAdSense";
import Helmetcomponent from "../Helmet/helmetcomponent";

function ShopButtonIndex() {
  return (
    <div style={{ marginTop: "85px" }}>
      <Helmetcomponent index={"Shop"} path={""} data={""} />
      <div>
        {" "}
        <ProductCategories />
      </div>
      <GoogleAdSense/>
      <div>
        {" "}
        <BharatShopIndex />
      </div>
      <div>
        <NewlyAddedItemsIndex />
      </div>
      <div>
        <ShopPopularItemsIndex />
      </div>
    </div>
  );
}

export default ShopButtonIndex;
