import React from "react";
import { useTranslation } from "react-i18next";
import useMatchMakingFetch from "../../../Hooks/useMatchMakingFetch";

const MatchPercentage = () => {
  const { t } = useTranslation();
  const url = `https://json.astrologyapi.com/v1/match_percentage`;
  const { data: matchPercentage } = useMatchMakingFetch(url);

  return (
    <>
      {matchPercentage && (
        <div className="shadow rounded-5">
          <div className="text-center details-header">
            {t("Match Percentage")}
          </div>
          <div className="details-body kundli-gradient-3">
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                {t("Is Match Good?")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {matchPercentage?.manglik?.is_match_good ? t("yes") : t("no")}
              </span>
            </div>
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                {t("Ashtakoot Match Percentage")}
              </h5>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-black fw-semibold"
                  role="progressbar"
                  style={{
                    width: `${matchPercentage?.ashtakoota_percentage}%`,
                    fontFamily: "montserrat",
                    fontSize: "13px",
                  }}
                  aria-valuenow={matchPercentage?.ashtakoota_percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {matchPercentage?.ashtakoota_percentage}%
                </div>
              </div>
            </div>
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                {t("Manglik Match Percentage")}
              </h5>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-black fw-semibold"
                  role="progressbar"
                  style={{
                    width: `${matchPercentage?.manglik_match_percentage}%`,
                    fontFamily: "montserrat",
                    fontSize: "13px",
                  }}
                  aria-valuenow={matchPercentage?.manglik_match_percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {matchPercentage?.manglik_match_percentage}%
                </div>
              </div>
            </div>
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                {t("Match Percentage")}
              </h5>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-black fw-semibold"
                  role="progressbar"
                  style={{
                    width: `${matchPercentage?.match_percentage}%`,
                    fontFamily: "montserrat",
                    fontSize: "13px",
                  }}
                  aria-valuenow={matchPercentage?.match_percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {matchPercentage?.match_percentage}%
                </div>
              </div>
            </div>
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                {t("Rajju Match Percentage")}
              </h5>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-black fw-semibold"
                  role="progressbar"
                  style={{
                    width: `${matchPercentage?.rajju_match_percentage}%`,
                    fontFamily: "montserrat",
                    fontSize: "13px",
                  }}
                  aria-valuenow={matchPercentage?.rajju_match_percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {matchPercentage?.rajju_match_percentage}%
                </div>
              </div>
            </div>
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                {t("Vedha Match Percentage")}
              </h5>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-black fw-semibold"
                  role="progressbar"
                  style={{
                    width: `${matchPercentage?.vedha_match_percentage}%`,
                    fontFamily: "montserrat",
                    fontSize: "13px",
                  }}
                  aria-valuenow={matchPercentage?.vedha_match_percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {matchPercentage?.vedha_match_percentage}%
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MatchPercentage;
