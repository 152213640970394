import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home/home'
import Signin from './Components/Signin/signIn'
import Signup from './Components/SignUp/signUp'
import Events from './Components/Events/events'
import SingleEvent from './Components/Events/SingleEvent/SingleEvent'
import UserProfile from './pages/UserProfile/userProfile'
import Suggested_Account from './Components/suggested_account/suggested_account'
import Horoscope from './Components/Horoscope/Horoscope'
import PanchangHome from './Components/Panchang/PanchangHome'
import Kundli from './Components/Kundli/Kundli'
import Articles from './Components/Articles/Articles'
import SingleArticle from './Components/Articles/SingleArticle'
import Matrimony from './Components/MatrimonyPackage/Matrimony'
import ShopApiContext from './Components/BharatWebShopContext/ShopTodaysDeals'
import ShopButtonIndex from './Components/BharatWebShop/ShopButtonIndex'
import AllTodayDeals from './Components/BharatWebShop/AllTodayDeals'
import AllNewlyAddedItems from './Components/BharatWebShop/AllNewlyAddedItems'
import AllPopularItems from './Components/BharatWebShop/AllPopularItems'
import CategoryItems from './Components/BharatWebShop/CategoryItems'
import ItemDetails from './Components/BharatWebShop/ShopItemDetails'
import FestivalContext from './Components/Festivals/FestivalContext'
import FestivalPage from './Components/Festivals/FestivalPage'
import HolidaysContext from './Components/Holidays/HolidaysContext'
import HolidaysPage from './Components/Holidays/HolidaysPage'
import KundaliBuyIndex from './Components/KundaliShop/KundaliBuyIndex'
import OrderNowForm from './Components/KundaliShop/OrderNowForm'
import Terms from './pages/TermsAndConditions/Terms'
import AboutUs from './pages/AboutUs/aboutus'
import ContactUs from './pages/ContactUs/ContactUs'
import FaqPage from './pages/FAQ/FAQPAGE'
import Profile from './pages/Profile/profile'
import PrivateRoute from './Components/routes/privateRoute'
import ProtectedRoute from './Components/routes/protectedRoute'
import ManageEvents from './Components/ManageEvents/Events'
import Detailedinfo from './Newdesign/detailedinfo/detailedinfo'

const Router = ({ userData }) => {
    return (
        <Routes>
            <Route
                exact
                path="/"
                element={
                    <PrivateRoute
                        isAuthenticated={userData?.isAuthenticated}
                        newUser={userData?.newUser}
                    />
                }
            >
                <Route exact path="/" element={<Home />} />
                <Route exact path="/signin" element={<Signin />} />
                <Route exact path="/signup" element={<Signup />} />
                <Route exact path="/event" element={<Events />} />
                {/* <Route exact path="/event/:id" element={<EventView />} />     */}
                <Route exact path="/event/:id" element={<SingleEvent />} />
                <Route exact path="/userprofile/:id" element={<UserProfile />} />
                <Route exact path="/suggested" element={<Suggested_Account />} />
                <Route exact path="/horoscope" element={<Horoscope />} />
                <Route exact path="/panchang" element={<PanchangHome />} />
                <Route exact path="/kundli" element={<Kundli />} />
                <Route exact path="/articles" element={<Articles />} />
                <Route exact path="/article/:id" element={<SingleArticle />} />
                <Route exact path="/detailedinfo/:id" element={<Detailedinfo />} />
            </Route>
            <Route exact path="/matrimony" element={<Matrimony />} />
            <Route
                element={
                    <ProtectedRoute isAuthenticated={userData?.isAuthenticated} />
                }
            >
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/e/manage/:events" element={<ManageEvents />} />
            </Route>
            <Route
                exact
                path="/shop"
                element={
                    <ShopApiContext>
                        <ShopButtonIndex />
                    </ShopApiContext>
                }
            />
            <Route
                exact
                path="/all-today-deals"
                element={
                    <ShopApiContext>
                        <AllTodayDeals />
                    </ShopApiContext>
                }
            />
            {/* <Route
                exact
                path="/all-today-deals/:itemId"
                element={
                <ShopApiContext>
                    <ItemDetails />
                </ShopApiContext>
                }
            /> */}
            <Route
                exact
                path="/all-newly-added"
                element={
                    <ShopApiContext>
                        <AllNewlyAddedItems />
                    </ShopApiContext>
                }
            />
            <Route
                exact
                path="/all-popular-items"
                element={
                    <ShopApiContext>
                        <AllPopularItems />
                    </ShopApiContext>
                }
            />
            <Route
                path="/category/:categoryId"
                element={
                    <ShopApiContext>
                        <CategoryItems />
                    </ShopApiContext>
                }
            />

            <Route
                exact
                path="/item/:itemId"
                element={
                    <ShopApiContext>
                        <ItemDetails />
                    </ShopApiContext>
                }
            />
            <Route
                exact
                path="/festivals"
                element={
                    <FestivalContext>
                        <FestivalPage />
                    </FestivalContext>
                }
            />
            <Route
                exact
                path="/holidays"
                element={
                    <HolidaysContext>
                        <HolidaysPage />
                    </HolidaysContext>
                }
            />
            <Route exact path="/aboutus" element={<AboutUs />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/faq" element={<FaqPage />} />
            <Route exact path="/buykundli" element={<KundaliBuyIndex />} />
            <Route exact path="/checkout" element={<OrderNowForm />} />
            <Route exact path="/termsandconditions" element={<Terms />} />
        </Routes>
    )
}

export default Router