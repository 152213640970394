import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#f16334",
};
const Loading = () => {
  let loading = true;
  let color = "#f16334";
  return (
    <div className="sweet-loading mt-5 pt-5">
      <ClipLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loading;
