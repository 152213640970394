import React from "react";
import { useTranslation } from "react-i18next";
import useKundliFetch from "../../../Hooks/useKundliFetch";

const PujaSuggestion = () => {
  const { t } = useTranslation();

  const url = `https://json.astrologyapi.com/v1/puja_suggestion`;
  const { data: pujaSuggestion } = useKundliFetch(url);

  return (
    <>
      {pujaSuggestion.length !== 0 && (
        <div>
          <div className="text-center details-header">
            {t("Puja Suggestion")}
          </div>
          <div className="details-body kundli-gradient-4 shadow-lg">
            <p className="montserrat-font fw-semibold mb-1 mb-lg-2">
              {pujaSuggestion?.summary}
            </p>
            {pujaSuggestion?.suggestions?.map((suggestion, index) => (
              <div key={index}>
                {suggestion?.title && (
                  <div className="mb-2 mb-lg-4">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Title")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {suggestion?.title}
                    </span>
                  </div>
                )}
                {suggestion?.summary && (
                  <div className="mb-2 mb-lg-4">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Summary")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {suggestion?.summary}
                    </span>
                  </div>
                )}
                {suggestion?.priority && (
                  <div className="mb-2 mb-lg-4">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Priority")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {suggestion?.priority}
                    </span>
                  </div>
                )}
                {suggestion?.one_line && (
                  <div className="mb-2 mb-lg-4">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Conclusion")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {suggestion?.one_line}
                    </span>
                  </div>
                )}
                <div div className="mb-2 mb-lg-4">
                  <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                    {t("Status")}
                  </h5>
                  <span className="montserrat-font fw-semibold">
                    {suggestion?.status === true ? "Yes" : "No"}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PujaSuggestion;
