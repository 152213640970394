import React, { useContext, useEffect, useState, useRef } from "react";
import { allShopItemsContext } from "../../Components/BharatWebShopContext/ShopTodaysDeals";
import { Link } from "react-router-dom";
import Rating from "../rating/rating";
import Heart from "../../img/icons/heart.svg";
import bag from "../../img/icons/bag.svg";
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations.json";

const Todaysdeals = () => {
    const { t } = useTranslation();
    const { loading, error, fetchTodayDealsData } = useContext(allShopItemsContext);
    const [shopData, setShopData] = useState([]);
    const lang = localStorage.getItem("lang");
    const [rating, setRating] = useState(4);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const containerRef = useRef(null);
    const [dragStartX, setDragStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    const text = t(selectedTranslation.labels.addtocart);
    const truncatedText = text.length > 15 ? `${text.slice(0, 15)}...` : text;

    useEffect(() => {
        const fetchTodayDeals = async () => {
            try {
                const todayDeals = await fetchTodayDealsData();
                setShopData(todayDeals);
            } catch (error) {
                console.error("Error fetching today's deals:", error.message);
            }
        };

        fetchTodayDeals();
    }, [fetchTodayDealsData]);

    const getImageUrl = (imagePath) => {
        const baseUrl = "https://api.exaweb.in:5200";
        const normalizedPath = imagePath.replace(/^(\.\.\/)+/, "");
        return `${baseUrl}/${normalizedPath}`;
    };

    const handleMouseEnter = (itemId) => {
        setHoveredItemId(itemId);
    };

    const handleMouseLeave = () => {
        setHoveredItemId(null);
    };

    const handleItemClick = (itemId) => {
        window.location.href = `https://shop.bharatcalendars.in/categoryItems/${itemId}`;
    };

    const handleDragStart = (e) => {
        setDragStartX(e.clientX);
        setScrollLeft(containerRef.current.scrollLeft);
        containerRef.current.style.scrollBehavior = "auto";
    };

    const handleDragMove = (e) => {
        if (dragStartX !== 0) {
            const dragOffset = e.clientX - dragStartX;
            containerRef.current.scrollLeft = scrollLeft - dragOffset;
        }
    };

    const handleDragEnd = () => {
        setDragStartX(0);
        containerRef.current.style.scrollBehavior = "smooth";
    };

    return (
        <div className="mt-4 text-gray-900 bg-[#FFF9F0] font-poppins pl-2 md:pl-20" style={{ height: "632px" }}>
            <div className="flex justify-between pt-14 md:pt-16">
                <div>
                    <p className="pl-8 text-xl font-black md:text-2xl">{t(selectedTranslation.labels.todaysdeals)}</p>
                </div>
                <div>
                    <Link
                        to="/all-today-deals"
                        className="flex items-center pr-24 text-sm text-gray-400 no-underline hover:text-gray-400"
                    >
                        {t(selectedTranslation.labels.seeall)}
                    </Link>
                </div>
            </div>
            <div
                className="flex p-3 space-x-4 overflow-x-auto rounded-lg whitespace-nowrap custom-scrollbar md:mt-0"
                ref={containerRef}
                onMouseDown={handleDragStart}
                onMouseMove={handleDragMove}
                onMouseUp={handleDragEnd}
                onMouseLeave={handleDragEnd}
            >
                {shopData.map((item) => {
                    const productName = item.itemName || "";
                    const truncatedName = productName.slice(0, 15)+'...';
                    return (
                        <div
                            key={item._id}
                            className="inline-block m-3 text-black bg-white rounded-xl"
                            style={{ minWidth: "238px", height: "427px" }}
                            onClick={() => handleItemClick(item._id)}
                            onMouseEnter={() => handleMouseEnter(item._id)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="relative">
                                <div className="absolute p-1 top-4 right-3">
                                    <img src={Heart} alt="Heart" className="w-5 h-5" />
                                </div>
                                {hoveredItemId === item._id && (
                                    <div className="absolute left-0 w-full transition-all duration-5000 ease-in-out transform translate-y-full bg-[#FF695F] bottom-9 md:bottom-10 flex items-center justify-center">
                                        <button className="px-4 py-2 text-white transition-colors duration-3000 bg-[#FF695F] rounded-md shadow-l font-bold ">
                                            <div className="flex gap-1">
                                                <img src={bag} alt="bag icon" className="w-5 h-5" />
                                                <span className="text-sm md:text-base">{truncatedText}</span>
                                            </div>
                                        </button>
                                    </div>
                                )}
                                <div className="absolute p-1 top-4 left-3 bg-[#1BC2F9] rounded-md items-center justify-center flex" style={{ height: "30px", width: "50px" }}>
                                    <h6 className="mt-2 text-sm text-white">Sale</h6>
                                </div>
                                <img
                                    className="object-cover rounded-t-xl"
                                    style={{ height: "275px", width: "238px",WebkitUserDrag: "none", WebkitTouchCallout: "none"  }}
                                    src={getImageUrl(item.imageGallery[0])}
                                    alt={item.itemName}
                                />
                            </div>
                            <div className="p-4">
                                <Rating rating={rating} setRating={setRating} />
                                <p className="text-sm break-words text-normal md:text-lg">{truncatedName}</p>
                                <div className="bg-[#008000] flex items-center justify-center p-1 rounded-2xl" style={{ width: "72px", height: "29px" }}>
                                    <h6 className="mb-0 text-sm text-white md:text-base">₹{item.price}</h6>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Todaysdeals;
