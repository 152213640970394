import React from "react";
import { useTranslation } from "react-i18next";
import useMatchMakingFetch from "../../../Hooks/useMatchMakingFetch";

const MatchObstructons = () => {
  const { t } = useTranslation();
  const url = `https://json.astrologyapi.com/v1/match_obstructions`;
  const { data: matchObstructions } = useMatchMakingFetch(url);

  return (
    <>
      {matchObstructions && (
        <div className="shadow rounded-5">
          <div className="text-center details-header">
            {t("Match Obstruction")}
          </div>
          <div className="details-body kundli-gradient-2">
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("Is Obstruction Present?")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {matchObstructions?.is_present === true ? t("yes") : t("no")}
              </span>
            </div>
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("vedha")} {t("Name")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {matchObstructions?.vedha_name === true
                  ? matchObstructions?.vedha_name
                  : t("No Veda is Present")}
              </span>
            </div>
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("vedha")} {t("Report")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {matchObstructions?.vedha_report}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MatchObstructons;
