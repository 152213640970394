import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";

const Rashi = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='flex flex-col items-center justify-center mt-14'>
            <h3>{selectedTranslation.labels.detailsrashi}</h3>
            <div className='grid grid-cols-4 gap-24 p-10 mt-6 ml-6'>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Moonsign")}</span><br /> {details?.Moonsign || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Sunsign")}</span> <br />{details?.Sunsign || "None"}</p>
                </div>

                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Surya Nakshatra")} </span><br />{details?.[selectedTranslation.labels.detailsurya] || "None"}</p>
                </div>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Surya Pada")}</span>  <br />{details?.["Surya Pada"]  || "None"}</p>
                </div>
            </div>
        </div>
    )
}

export default Rashi;