import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";

const Tithinakshatra = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='flex flex-col items-center justify-center mt-14'>
            <h3>{selectedTranslation.labels.detailstithi}</h3>
            <div className='grid grid-cols-3 p-10 ml-6'>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("tithi")}</span> <br />{localStorage.getItem("lang") === 'te' ? details?.Tithulu : details?.Tithi || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{selectedTranslation.labels.detailsnak}</span><br /> {details?.[selectedTranslation.labels.gridNakshatra] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Karana")} </span><br />{localStorage.getItem("lang") === 'te' ? details?.Karanamulu : details?.Karana  || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Yoga")}</span>  <br />{localStorage.getItem("lang") === 'te' ? details?.Yogalu : details?.Yoga  || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Weekday")}</span>  <br />{details?.Weekday || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Paksha")}</span>  <br />{localStorage.getItem("lang") === 'te' ? details?.Pakshamulu : details?.Paksha  || "None"}</p>
                </div>
            </div>
        </div>
    )
}

export default Tithinakshatra;