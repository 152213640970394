import React, { useContext, useEffect, useState } from "react";
import { allShopItemsContext } from "../BharatWebShopContext/ShopTodaysDeals";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { Link } from "react-router-dom";
// import "./NewlyAddedItems.css";
import "./NewAddedItems.scss";
//import Loader from "../../img/Spinner-Loader-shop.gif";
import Helmetcomponent from "../Helmet/helmetcomponent";

function NewlyAddedItemsIndex() {
  const { loading, error, fetchNewlyAddedItemsData } =
    useContext(allShopItemsContext);
  const [shopData, setShopData] = useState([]);

  useEffect(() => {
    const fetchTodayDeals = async () => {
      try {
        const NewlyAddedItems = await fetchNewlyAddedItemsData();
        setShopData(NewlyAddedItems.slice(0, 8));
      } catch (error) {
        console.error("Error fetching newly added items:", error);
      }
    };

    fetchTodayDeals();
  }, [fetchNewlyAddedItemsData]);

  if (loading) {
    return (
      <div>
        <h3 className="m-3 text-center text-warning">
          ...Loading Newly Added Items...
        </h3>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const getImageUrl = (imagePath) => {
    //console.log(imagePath);
    const baseUrl = "https://api.exaweb.in:5200";
    const normalizedPath = imagePath.replace(/^(\.\.\/)+/, "");
    //console.log(`${baseUrl}/${normalizedPath}`);
    return `${baseUrl}/${normalizedPath}`;
  };

  const handleItemClick = (itemId) => {
    window.location.href = `https://shop.bharatcalendars.in/categoryItems/${itemId}`;
  };

  return (
    <>
      <div className="mt-4 text-dark">
        <Helmetcomponent index={"NewlyAddedItems"} path={""} data={""} />
        <div>
          <p
            style={{
              fontFamily: "montserrat-font",
              fontWeight: "900",
              textAlign: "center",
            }}
            className="title fs-2"
          >
            Newly Added
          </p>
        </div>
        <div className="mb-3 row row-cols-1 row-cols-md-2 g-5 justify-content-md-between justify-content-center"
          style={{
            borderRadius: "18px",
            padding: "9px",
            marginTop: "10px",
            backgroundColor: "wheat",
            display: "flex",
            justifyContent: "space-between",
            margin: "0",
          }}
        >
          {shopData.map((item, index) => {
            const itemName = item.itemName || "";
            const truncatedName = itemName.slice(0, 15);
            return (
              <div
                className="rounded bg-dark "
                key={item._id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "170px",
                  height: "280px",
                  margin: "5px",
                  cursor: "pointer",
                }}
                onClick={() => handleItemClick(item._id)}
              >
                <div className="text-center card-img-top">
                  <img
                    src={getImageUrl(item.imageGallery[0])}
                    alt={item.itemName}
                    style={{
                      maxWidth: "100%",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "7px",
                      marginTop: '1rem',
                      display: "block",
                    }}
                  />
                </div>
                <div
                  className=" text-light"
                  style={{ padding: '0.5rem 0rem' }}
                >
                  <p className="item-name fs-6">{truncatedName}</p>
                </div>
                <div className="card-footer" style={{ backgroundColor: 'green', height: '50px', marginBottom: '1rem', borderRadius: '1rem' }}>
                  <p className="pt-2 d-flex justify-content-center align-items-center">
                    <span className="fs-5 text-light">₹{item.discPrice}</span>
                    <span className="fs-6 text-decoration-line-through"  style={{color:'lightgray'}}>
                      ₹{item.price}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <p className="p-1 text-end">
        <Link
          to="/all-newly-added"
          className="text-decoration-none text-danger fs-6"
        >
          See All
          <IoIosArrowDroprightCircle />
        </Link>
      </p>
    </>
  );
}

export default NewlyAddedItemsIndex;

// import React, { useContext, useEffect, useState } from "react";
// import { allShopItemsContext } from "../BharatWebShopContext/ShopTodaysDeals";
// import { Link } from "react-router-dom";

// function NewlyAddedItemsIndex() {
//   const { loading, error, getNewlyAddedItems } =
//     useContext(allShopItemsContext);
//   const [shopData, setShopData] = useState([]);

//   useEffect(() => {
//     const fetchNewlyAddedItems = async () => {
//       try {
//         const newlyAddedItems = await getNewlyAddedItems();
//         setShopData(newlyAddedItems.slice(0, 8));
//       } catch (error) {
//         console.error("Error fetching newly added items:", error);
//       }
//     };

//     fetchNewlyAddedItems();
//   }, [getNewlyAddedItems]);

//   if (loading) {
//     return (
//       <div>
//         <h3 className="text-center text-warning">Loading Newly Added Items</h3>
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   return (
//     <>
//       <div className="mt-4 text-dark">
//         <div>
//           <p
//             style={{
//               fontSize: "35px",
//               fontFamily: "montserrat-font",
//               fontWeight: "900",
//               textAlign: "center",
//             }}
//             className="title"
//           >
//             Newly Added
//           </p>
//         </div>
//         <div
//           className="flex-wrap d-flex justify-content-center"
//           style={{
//             borderRadius: "18px",
//             padding: "9px",
//             backgroundColor: "wheat",
//           }}
//         >
//           {shopData.map((item, index) => (
//             <div
//               className="rounded card bg-dark"
//               key={item._id}
//               style={{
//                 width: "calc(50% - 20px)", // Set card width for two items per row
//                 margin: "10px",
//               }}
//             >
//               <div className="card-img-top">
//                 <img
//                   src={item.imageGallery[0]}
//                   alt={`Item ${index + 1}`}
//                   style={{
//                     maxWidth: "100px",
//                     maxHeight: "100px",
//                     margin: "5px",
//                   }}
//                 />
//               </div>
//               <div className="card-body text-light">
//                 <p className="item-name">{item.itemName.slice(0, 20)}...</p>
//                 <p className="d-flex justify-content-evenly align-content-center">
//                   <span className="fs-5">₹{item.discPrice}</span>
//                   <span className="fs-6 fs-lighter text-decoration-line-through">
//                     ₹{item.price}
//                   </span>
//                 </p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//       <p className="p-1 text-end">
//         <Link to="/all-newly-added" className="text-decoration-none">
//           See All
//         </Link>
//       </p>
//     </>
//   );
// }

// export default NewlyAddedItemsIndex;
