import React from "react";
import { useTranslation } from "react-i18next";
import useKundliFetch from "../../../Hooks/useKundliFetch";

const GemSuggestion = () => {
  const { t } = useTranslation();

  const url = `https://json.astrologyapi.com/v1/basic_gem_suggestion`;
  const { data: gemSuggestion } = useKundliFetch(url);

  let keys;
  if (gemSuggestion.length !== 0) {
    keys = Object?.keys(gemSuggestion);
  }
  return (
    <>
      {gemSuggestion.length !== 0 &&
        keys?.map((key, index) => (
          <div key={index} className="shadow-lg rounded-5">
            <div className="text-center details-header">{t(`${key}`)}</div>
            <ul
              className={`list-group list-group-flush details-body kundli-gradient-${
                index + 2
              }`}
            >
              {Object.keys(gemSuggestion?.[key]).map((suggestionKey, index) => (
                <li key={index} className="list-group-item bg-transparent">
                  <span className="montserrat-font text-white">
                    {t(`${suggestionKey}`)} :{" "}
                  </span>
                  <span className="montserrat-font fw-semibold">
                    {gemSuggestion?.[key][suggestionKey]}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
    </>
  );
};

export default GemSuggestion;
