import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";

function PrivateRoute({ children, isAuthenticated, newUser, ...rest }) {
  const tost = () => {
    toast.warning("Please Complete Your Profile First", { theme: "colored" });
  };

  return isAuthenticated == true && newUser === true ? (
    <>
      {tost()}
      <Navigate to="/profile" />
    </>
  ) : (
    <Outlet />
  );
}

export default PrivateRoute;
