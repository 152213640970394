import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { IoCamera } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getUpdateUserData, getUser } from "../../../store/actions/authActions";
import Select from "react-select";
import {
  categoriesApi,
  showCategories,
} from "../../../store/actions/eventActions";
import { uploadApi } from "../../../store/actions/fileActions";
import "./information.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { authAction } from "../../../store/slice/authSlice";
import { usePlacesWidget } from "react-google-autocomplete";
import DatePicker from "react-datepicker";
import moment from "moment";

function Information() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userData = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const categories = useSelector(showCategories);
  const profile_pic = useSelector((state) => state?.file?.profile);
  const [options, setOptions] = useState([]);
  const [defCategory, setDefCategory] = useState([]);
  const isLoading = useSelector((state) => state?.user?.isLoading);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  useEffect(() => {
    dispatch(getUser({ id: userData?.userId }));
  }, []);

  useEffect(() => {
    if (categories?.length) {
      let category = categories.map((item) => ({
        value: item?._id,
        label: item?.categoryName,
        catObj: item,
      }));
      let defaultCat = userData?.userdetail?.categories?.map((value) => {
        const index = category?.find((item) => item?.value === value._id);
        return index;
      });
      formik.setFieldValue("categoryId", defaultCat);
      setDefCategory(defaultCat);
      setOptions(category);
    }
  }, [
    categories?.length,
    options?.length,
    defCategory?.length,
    categories,
    userData?.userdetail?.categories,
  ]);

  useEffect(() => {
    dispatch(categoriesApi());
  }, [dispatch]);
  const handleUpdatedData = (values) => {
    const UserDetail = {
      firstName: values.firstname,
      imageURL:
        profile_pic?.length !== 0 && profile_pic?.url
          ? profile_pic?.url
          : userData?.userdetail?.imageURL,
      categories: values.categoryId.map((value) => value.catObj),
      city: values.city,
      country: values.country,
      lastName: values.lastname,
      userName: values.username,
      userMob: values.cellphone,
      _id: userData?.userId,
      language: localStorage.getItem("REACT_APP_LANGUAGE"),
      birthday: moment(values.birthday).format(),
      rashi: values.rashi,
      location: {
        type: "Point",
        coordinates: [
          !long ? userData.userdetail.location.coordinates[0] : long,
          !lat ? userData.userdetail.location.coordinates[1] : lat,
        ],
      },
    };
    dispatch(getUpdateUserData(UserDetail));
  };
  const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
      errors.firstname = "Required";
    }
    if (!values.lastname) {
      errors.lastname = "Required";
    }
    if (!values.username) {
      errors.username = "Required";
    }
    if (!values.city) {
      errors.city = "Required";
    }
    if (!values.rashi) {
      errors.rashi = "Required";
    }
    if (!values.birthday) {
      errors.birthday = "Required";
    }
    if (values.categoryId?.length === 0) {
      errors.categoryId = "Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: userData?.userdetail?.userEmail,
      firstname: userData?.userdetail?.firstName,
      lastname: userData?.userdetail?.lastName,
      username: userData?.userdetail?.userName,
      cellphone: userData?.userdetail?.userMob,
      city: userData?.userdetail?.city,
      country: userData?.userdetail?.country,
      categoryId: defCategory?.length > 0 ? defCategory : "",
      email: userData?.userdetail?.userEmail,
      birthday:
        userData?.userdetail?.birthday !== undefined &&
        moment(userData?.userdetail?.birthday).toDate(),
      rashi: userData?.userdetail?.rashi,
    },
    validate,
    onSubmit: (values) => {
      setTimeout(() => {
        handleUpdatedData(values);
      }, 500);
    },
  });
  const handleImage = (e) => {
    dispatch(uploadApi(e.target.files[0]));
  };
  useEffect(() => {
    if (isLoading === true) {
      navigate("/");
      dispatch(authAction.ClearLoading());
    }
  }, [isLoading]);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      formik.setFieldValue(
        "city",
        place?.address_components[0]?.long_name
          ? place?.address_components[0]?.long_name
          : ""
      );
      formik.setFieldValue(
        "country",
        place?.address_components[3]?.long_name
          ? place?.address_components[3]?.long_name
          : ""
      );
      setLat(place?.geometry?.location?.lat());
      setLong(place?.geometry?.location?.lng());
    },
  });
  const rashiValues = [
    { name: "aries", label: "Aries" },
    { name: "taurus", label: "Taurus" },
    { name: "gemini", label: "Gemini" },
    { name: "cancer", label: "Cancer" },
    { name: "leo", label: "Leo" },
    { name: "virgo", label: "Virgo" },
    { name: "libra", label: "Libra" },
    { name: "scorpio", label: "Scorpio" },
    { name: "saggitarius", label: "Saggitarius" },
    { name: "capricorn", label: "Capricorn" },
    { name: "aquarius", label: "Aquarius" },
    { name: "pisces", label: "Pisces" },
  ];

  return (
    <>
      <div className="row main-form-contain-acc font-poppins">
        <div className="col-md-12 col-lg-12 col-sm-12"></div>
        <form onSubmit={formik.handleSubmit}>
          <div className="col-lg-12 col-sm-12 col-md-12">
            <br />
            <label className="label-text">
              {t("Profile_Pic")}
              <span className="text-danger">*</span>
            </label>
            <div className="form-textbox">
              <div className="items-center justify-start gap-10 d-flex">
                <div>
                  <img
                    src={
                      profile_pic?.length !== 0 && profile_pic?.url
                        ? profile_pic?.url
                        : userData && userData?.userdetail?.imageURL
                        ? userData?.userdetail?.imageURL
                        : "/default/user.png"
                    }
                    alt=""
                    className="user-img rounded-circle"
                    height="100px"
                    width="100px"
                  />
                </div>
                <div>
                  <input
                    type="file"
                    name="profile_pic"
                    id="image1"
                    style={{ display: "none" }}
                    accept="image/.x-png,image/.gif,image/.jpg"
                    onChange={handleImage}
                  />
                  <label htmlFor="image1">
                    <span className="flex p-3 text-lg rounded-md bg-[#F16334]">
                      <span className="font-bold">Upload Image</span>
                      <IoCamera style={{ marginBottom: "4px", color: "black" }} className="ml-2 text-2xl"/>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 col-md-12 contect-form">
            <p className="email-account-title">{t("Personal_Information")}</p>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-md-12 form-group">
                <label className="label-text">
                  {t("First_Name")}:<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control form-textbox"
                  name="firstname"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstname}
                />
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className="error">{formik.errors.firstname}</div>
                ) : null}
              </div>
              <div className="col-lg-6 col-sm-12 col-md-12 form-group">
                <label className="label-text">
                  {t("Last_Name")}:<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control form-textbox"
                  name="lastname"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastname}
                />
                {formik.touched.lastname && formik.errors.lastname ? (
                  <div className="error">{formik.errors.lastname}</div>
                ) : null}
              </div>

              <div className="col-lg-6 col-sm-12 col-md-12 form-group">
                <label className="label-text">
                  {t("User_Name")}:<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control form-textbox"
                  name="username"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="error">{formik.errors.username}</div>
                ) : null}
              </div>
              <div className="mb-2 col-lg-6 col-sm-12 col-md-12 form-group">
                <label className="label-text">
                  {t("City")} <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control form-textbox text-capitalize"
                  ref={ref}
                  type="text"
                  name="city"
                  placeholder={t("Placeholder_Address")}
                  value={formik.values.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div className="error">{formik.errors.city}</div>
                ) : null}
              </div>

              <div className="mb-2 col-lg-6 col-sm-12 col-md-12 form-group">
                <label className="label-text">
                  {t("Birth_Day")} <span className="text-danger">*</span>
                </label>
                <DatePicker
                  selected={formik.values.birthday}
                  name="birthday"
                  className="form-control form-textbox"
                  placeholderText="Select Birthdate"
                  onChange={(date) => {
                    formik.setFieldValue("birthday", date);
                  }}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="yyyy-MM-dd"
                />
                {formik.touched.birthday && formik.errors.birthday ? (
                  <div className="error">{formik.errors.birthday}</div>
                ) : null}
              </div>
              <div className="mb-2 col-lg-6 col-sm-6 col-md-6 form-group">
                <label className="label-text">
                  {t("Rashi")} <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control form-textbox"
                  name="rashi"
                  id="rashi"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.rashi}
                >
                  <option value="">{t("Select")}</option>
                  {rashiValues?.length > 0 &&
                    rashiValues?.map((value, index) => {
                      return (
                        <option value={value?.name} key={index}>
                          {value?.label}
                        </option>
                      );
                    })}
                </select>
                {formik.touched.rashi && formik.errors.rashi ? (
                  <div className="error">{formik.errors.rashi}</div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 col-md-12 contect-form">
              <p className="email-account-title">{t("Event_Categories")}</p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-sm-12 col-md-12 form-group">
                <label className="label-text">
                  {t("Categories")}:<span className="text-danger">*</span>
                </label>
                {options?.length !== 0 && (
                  <Select
                    isMulti
                    name="categoryId"
                    options={options}
                    onChange={(option) =>
                      formik.setFieldValue("categoryId", option)
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={formik.values.categoryId}
                    placeholder="select categories"
                  />
                )}
                {formik.touched.categoryId && formik.errors.categoryId ? (
                  <div className="error">{formik.errors.categoryId}</div>
                ) : null}
              </div>
            </div>
            <div className="mb-2 col-lg-12 col-md-12 col-sm-12 form-group">
              <br />
              <p className="email-account-title">{t("Private_info")}</p>
              <div className="row">
                <div className="mb-2 col-lg-6 col-sm-6 col-md-6 form-group">
                  <label className="label-text">{t("Email")}:</label>
                  <input
                    type="text"
                    className="form-control form-textbox"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    readOnly
                    name="email"
                  />
                </div>
                <div className="col-lg-6 col-sm-6 col-md-6 form-group">
                  <label className="label-text">{t("Cell_Phone")}:</label>
                  <input
                    type="text"
                    className="form-control form-textbox"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cellphone}
                    name="cellphone"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-lg-4 form-group">
            <input
              type="submit"
              defaultValue={"Submit"}
              className="pro-choose-file text-uppercase"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default Information;
