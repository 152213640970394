import React, { useEffect } from "react";
import { brideData, groomData } from "./data";
import { FilterModal } from "./FilterModal";
import { Row, Col } from "react-bootstrap";
import { MuiAvatar } from "./MuiAvatar";
const MainMatrimony = () => {
  const [groomCasteValue, setGroomCasteValue] = React.useState("");
  const [brideCasteValue, setBrideCasteValue] = React.useState("");
  const [groomDataFilter, setGroomDataFilter] = React.useState(groomData);
  const [brideDataFilter, setBrideDataFilter] = React.useState(brideData);
  const onGroomCasteChange = (casteVal) => setGroomCasteValue(casteVal);
  const onBrideCasteChange = (casteVal) => setBrideCasteValue(casteVal);

  useEffect(() => {
    if (groomCasteValue === "") setGroomDataFilter(groomData);
    else
      setGroomDataFilter(
        groomData.filter((groom) => groom.caste === groomCasteValue)
      );
  }, [groomCasteValue]);
  useEffect(() => {
    if (brideCasteValue === "") setBrideDataFilter(brideData);
    else
      setBrideDataFilter(
        brideData.filter((bride) => bride.caste === brideCasteValue)
      );
  }, [brideCasteValue]);
  useEffect(() => {
    setGroomDataFilter(groomData);
    setBrideDataFilter(brideData);
  }, []);

  return (
    <Row>
      <Col md={5}>
        <h3>Groom</h3>
        <MuiAvatar overallData={groomDataFilter} />
      </Col>
      <Col md={1}>
        <FilterModal
          id="Groom"
          text="Groom Filter"
          onCasteChange={onGroomCasteChange}
        />
      </Col>
      <Col md={5}>
        <h3>Bride</h3>
        <MuiAvatar overallData={brideDataFilter} />
      </Col>
      <Col md={1}>
        <FilterModal
          id="Bride"
          text="Bride Filter"
          onCasteChange={onBrideCasteChange}
        />
      </Col>
    </Row>
  );
};
export default MainMatrimony;
