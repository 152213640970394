import { useEffect, useState } from "react";
import "./Events.scss";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Card/card";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  getCurrentEvents,
  getFutureEvents,
  getPastEvents,
} from "../../store/actions/eventsActions";
import { useTranslation } from "react-i18next";
import { getEventListApi, showEvents } from "../../store/actions/eventActions";
import Helmetcomponent from "../Helmet/helmetcomponent";

function ManageEvents() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [events, setEvents] = useState("current");
  const user = useSelector((state) => state?.user?.userdetail);
  const pastEvents = useSelector((state) => state?.userEvents?.past);
  const currentEvents = useSelector((state) => state?.userEvents?.current);
  const futureEvents = useSelector((state) => state?.userEvents?.future);
  const myEvents = useSelector(showEvents);
  const  id  = useParams();

  useEffect(() => {
    const pastdata = {
      userId: user?._id,
      filter: "past",
    };
    const futuredata = {
      userId: user?._id,
      filter: "upcoming",
    };
    const currentdata = {
      userId: user?._id,
      filter: "current",
    };
    dispatch(getPastEvents(pastdata));
    dispatch(getCurrentEvents(currentdata));
    dispatch(getFutureEvents(futuredata));
    dispatch(getEventListApi({ userId: user?._id, eventStatus: "APPROVED" }));
  }, []);

  return (
    <>
      <div className="manage_events" style={{ paddingTop: "3.3%" }}>
        <Helmetcomponent index={"ManageEvents"} path={id.events} data={""}/>
        
        <div className="page-main-contain">
          <div className="user-events">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
              </div>
              <h1 className="text-heading mt-5"> {t("Manage Events")}</h1>
              <hr />
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12 mb-4">
                  <div className="event-sidebar" id="sidebar-hover">
                    <ul className="nav nav-tabs" role="tablist">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="current"
                        activeKey={events}
                        onSelect={(k) => setEvents(k)}
                      >
                        <Row>
                          <Col>
                            <Nav className="nav-tabs">
                              <Nav.Item className="w-100">
                                <Nav.Link
                                  eventKey="current"
                                  as={Link}
                                  to="/e/manage/current"
                                >
                                  <span className="events">
                                    {" "}
                                    {t("Current_Events")}
                                  </span>
                                  <span className="label">
                                    {currentEvents?.length !== 0
                                      ? currentEvents?.length
                                      : 0}
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="w-100">
                                <Nav.Link
                                  eventKey="upcoming"
                                  as={Link}
                                  to="/e/manage/future"
                                >
                                  <span className="events">
                                    {" "}
                                    {t("Future_Events")}
                                  </span>
                                  <span className="label">
                                    {futureEvents?.length !== 0
                                      ? futureEvents?.length
                                      : 0}
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="w-100">
                                <Nav.Link
                                  eventKey="past"
                                  as={Link}
                                  to="/e/manage/past"
                                >
                                  <span className="events">
                                    {" "}
                                    {t("Past_Events")}
                                  </span>
                                  <span className="label">
                                    {pastEvents?.length !== 0
                                      ? pastEvents?.length
                                      : 0}
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="w-100">
                                <Nav.Link
                                  eventKey="publish"
                                  as={Link}
                                  to="/e/manage/publish"
                                >
                                  <span className="events">
                                    {t("Publish_Events")}
                                  </span>
                                  <span className="label">
                                    {myEvents?.data?.data?.length !== 0
                                      ? myEvents?.data?.data?.length
                                      : 0}
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </ul>
                  </div>
                </div>
                {events === "past" && (
                  <div className="col-lg-9 col-md-8 col-sm-12">
                    <div className="tab-content">
                      <div className="" id="feature">
                        <div className="eventbox-main">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="eventbox-header">
                                <h2 style={{ fontSize: "22px" }}>
                                  {t("Past_Events")}
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mt-3 ms-3">
                            {pastEvents?.length > 0 ? (
                              pastEvents?.map((value, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                                    style={{ overFlow: "auto" }}
                                  >
                                    <Card event={value} index={index} />
                                  </div>
                                );
                              })
                            ) : (
                              <div className="eventbox-events w-100">
                                <div className="eventbox">
                                  <h3 className="event-title wordwap m-0">
                                    No Events Found
                                  </h3>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {events === "upcoming" && (
                  <div className="col-lg-9 col-md-8 col-sm-12">
                    <div className="tab-content">
                      <div className="" id="feature">
                        <div className="eventbox-main">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="eventbox-header">
                                <h2 style={{ fontSize: "22px" }}>
                                  {t("Future_Events")}
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mt-3 ms-3">
                            {futureEvents?.length > 0 ? (
                              futureEvents?.map((value, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                                  >
                                    <Card event={value} index={index} />
                                  </div>
                                );
                              })
                            ) : (
                              <div className="eventbox-events w-100">
                                <div className="eventbox">
                                  <h3 className="event-title wordwap m-0">
                                    {t("No_Events_Found")}
                                  </h3>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {events === "current" && (
                  <div className="col-lg-9 col-md-8 col-sm-12">
                    <div className="tab-content">
                      <div className="" id="feature">
                        <div className="eventbox-main">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="eventbox-header">
                                <h2 style={{ fontSize: "22px" }}>
                                  {t("Current_Events")}
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap mt-3 ms-3">
                            {currentEvents?.length > 0 ? (
                              currentEvents?.map((value, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                                  >
                                    <Card event={value} index={index} />
                                  </div>
                                );
                              })
                            ) : (
                              <div className="eventbox-events w-100">
                                <div className="eventbox">
                                  <h3 className="event-title wordwap m-0">
                                    {t("No_Events_Found")}
                                  </h3>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {events === "publish" && (
                  <div className="col-lg-9 col-md-8 col-sm-12">
                    <div className="tab-content">
                      <div className="" id="feature">
                        <div className="eventbox-main">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="eventbox-header">
                                <h2 style={{ fontSize: "22px" }}>
                                  {t("Publish_Events")}
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row mt-3 ms-3">
                            {myEvents?.data?.data?.length > 0 ? (
                              myEvents?.data?.data?.map((value, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                                  >
                                    <Card event={value} index={index} />
                                  </div>
                                );
                              })
                            ) : (
                              <div className="eventbox-events w-100">
                                <div className="eventbox">
                                  <h3 className="event-title wordwap m-0">
                                    {t("No_Events_Found")}
                                  </h3>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageEvents;
