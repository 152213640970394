import React from "react";
import { useTranslation } from "react-i18next";
import useKundliFetch from "../../../Hooks/useKundliFetch";

const SadhesatiSuggestion = () => {
  const { t } = useTranslation();

  const url = `https://json.astrologyapi.com/v1/sadhesati_remedies`;
  const { data: sadhesatiSuggestion } = useKundliFetch(url);

  return (
    <>
      {sadhesatiSuggestion.length !== 0 && (
        <div>
          <div className="text-center details-header">{t("Sadhesati")}</div>
          <div className="details-body kundli-gradient-6 shadow-lg">
            <h5 className="montserrat-font text-white mb-0 mb-lg-2">
              {t("What is Sadhesati")}
            </h5>
            <span className="montserrat-font fw-semibold">
              {sadhesatiSuggestion?.what_is_sadhesati}
            </span>
          </div>
          <div>
            <div className="text-center details-header">
              {sadhesatiSuggestion?.remedies[0]}
            </div>
            <ul className="list-group list-group-flush details-body kundli-gradient-2">
              {sadhesatiSuggestion?.remedies?.slice(1)?.map((remedy, index) => (
                <li key={index} className="list-group-item bg-transparent">
                  <span className="montserrat-font fw-semibold">{remedy}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default SadhesatiSuggestion;
