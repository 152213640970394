import { Modal } from "react-bootstrap";
import "./Sharemodal.scss";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { ImFacebook } from "react-icons/im";
import { BsGoogle } from "react-icons/bs";
import Sharefriends from "../../shareFrnds/shareFriends";

function ShareModal({ show, close }) {
  return (
    <div>
      <Modal show={show} onHide={close}>
        <Modal.Header
          closeButton
          style={{ fontSize: "10px", borderBottom: "none" }}
          className="close"
        ></Modal.Header>
        <h5 className="modal-title ">Share with friends</h5>
        <Modal.Body className="share-body">
          <Sharefriends />
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ShareModal;
