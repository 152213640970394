import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";

const RituAndAyana = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center details-header" style={{ fontSize: "3.2vw" }}>
        {`${t("Ritu")} ${t("and")} ${t("Ayana")}`}
      </div>

      <div className="details-body panchang-gradient-4">
        <div className="row mx-auto" style={{ width: "88%" }}>
          {/* Drik Ritu */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">
                {`${t("Drik")} ${t("Ritu")}`}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Drik Ritu"]
                  ? panchangDetails?.["Drik Ritu"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Drik Ayana */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {`${t("Drik")} ${t("Ayana")}`}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Drik Ayana"]
                  ? panchangDetails?.["Drik Ayana"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Dinamana */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Dinamana")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Dinamana"]
                  ? panchangDetails?.["Dinamana"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Madhyahna */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Madhyahna")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Madhyahna"]
                  ? panchangDetails?.["Madhyahna"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Vedic Ritu */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {`${t("Vedic")} ${t("Ritu")}`}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Vedic Ritu"]
                  ? panchangDetails?.["Vedic Ritu"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Vedic Ayana */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {`${t("Vedic")} ${t("Ayana")}`}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Vedic Ayana"]
                  ? panchangDetails?.["Vedic Ayana"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Ratrimana */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Ratrimana")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Ratrimana"]
                  ? panchangDetails?.["Ratrimana"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RituAndAyana;
