import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";

const Chandrabalam = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='flex flex-col items-center justify-center mt-14'>
            <h3>{selectedTranslation.labels.detailschandra}</h3>
            <div className='grid grid-cols-2 gap-24 p-10 mt-6 ml-6'>
                <div className='flex justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Chandrabalam")} </span><br />
                        <div className="mt-4">
                            {details?.Chandrabalam ? (
                                <div>
                                    {details?.Chandrabalam?.map((details, index) => (
                                        <div key={index}>
                                            <h6 className="">
                                                {details?.upto}
                                            </h6>
                                            {details?.values.map((value, index) => (
                                                <p key={index} className="">
                                                    {value}
                                                </p>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <>

                                    <p className="text-center">None</p>
                                </>
                            )}
                        </div>
                    </p>
                </div>
                <div className='flex justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Tarabalam")}</span> <br />
                        <div className="mt-4">
                            {details?.Tarabalam ? (
                                <div>
                                    {details?.Tarabalam?.map((details, index) => (
                                        <div key={index}>
                                            <h6 className="">
                                                {details?.upto}
                                            </h6>
                                            {details?.values.map((value, index) => (
                                                <p key={index} className="">
                                                    {value}
                                                </p>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <>

                                    <p className="text-center">None</p>
                                </>
                            )}
                        </div>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Chandrabalam;