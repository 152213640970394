import httpService from "../../common/http.service";
import { statusAction } from "../slice/statusSlice";

export const geteventStatus = (data) => async (dispatch) => {
  try {
    const response = await httpService.post("eventparticipant", {}, data);
    if (response && response?.data) {
      dispatch(statusAction.getStatusSuccess(response?.data));
    }
  } catch (err) {
    dispatch(statusAction.getStatusFailed(err));
  }
};

export const findEventStatus = (data) => async (dispatch) => {
  try {
    const response = await httpService.post("eventparticipant/find", {}, data);
    if (response && response?.data) {
      dispatch(statusAction.getEventStatusSuccess(response?.data));
    }
  } catch (err) {
    dispatch(statusAction.getEventStatusFailed(err));
  }
};
