import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Eventcard from "./eventcard";

const Popularevents = ({ popularEvents = [] }) => {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - scrollRef.current.offsetLeft;
      const walk = (x - startX) * 3;
      scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleTouchMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.touches[0].pageX - scrollRef.current.offsetLeft;
      const walk = (x - startX) * 3;
      scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [isDragging, startX, scrollLeft]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  return (
    <div className="-mx-20 md:mx-auto font-poppins" style={{ height: "590px" }}>
      <div className="bg-white">
        <h1 className="mb-3 text-xl font-bold md:text-3xl mt-14 ml-28 ">{t("Popular Events")}</h1>
        {Array.isArray(popularEvents) && popularEvents.length > 0 ? (
          <div className="relative">
            <div className="absolute top-0 left-0 h-full" style={{ width: "110px", background: "white" }}></div>
            <div
              className={`overflow-x-auto pl-28 ${popularEvents.length >= 4 ? 'custom-scroll' : ''}`}
              ref={scrollRef}
              onMouseDown={popularEvents.length >= 4 ? handleMouseDown : null}
              onTouchStart={popularEvents.length >= 4 ? handleTouchStart : null}
              style={{ cursor: isDragging ? "grabbing" : "grab", overflowY: "hidden" }}
            >
              <div className="flex w-full gap-4">
                {popularEvents.map((event, index) => (
                  <div key={index} className="inline-block">
                    <Eventcard event={event} index={index} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <span className="block text-lg font-semibold text-center">
            There is no Popular Event Right Now
          </span>
        )}
      </div>
    </div>
  );
};

export default Popularevents;
