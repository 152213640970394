import React, { useState, useContext } from "react";
import "./SuggestionAndRemadies.scss";
import { useTranslation } from "react-i18next";
import BasicDetails from "../BasicDetails";
import KundliInput from "../KundliInput";
import GemSuggestion from "./GemSuggestion";
import PujaSuggestion from "./PujaSuggestion";
import RudrakshaSuggestion from "./RudrakshaSuggestion";
import SadhesatiSuggestion from "./SadhesatiSuggestion";
import Slider from "react-slick";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { KundliContext } from "../Kundli";
const SuggestionAndRemadies = () => {
  const { userInfo } = useContext(KundliContext);
  const { t } = useTranslation();

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <FaArrowCircleLeft
        className={className}
        style={{
          ...style,
          color: "#483f50",
          height: "1.68vw",
          width: "1.68vw",
        }}
        onClick={onClick}
      />
    );
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <FaArrowCircleRight
        className={className}
        style={{
          ...style,
          color: "#483f50",
          height: "1.68vw",
          width: "1.68vw",
        }}
        onClick={onClick}
      />
    );
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  const [buttonName, setButtonName] = useState("pujaSuggestion");
  const [pujaSuggestionClicked, setPujaSuggestionClicked] = useState(true);
  const [gemSuggestionClicked, setGemSuggestionClicked] = useState(false);
  const [rudrakshaSuggesionClicked, setRudrakshaSuggesionClicked] =
    useState(false);
  const [sadhesatiSuggestionClicked, setSadhesatiSuggestionClicked] =
    useState(false);

  const handleButtons = (setButtonClicked, buttonName) => {
    setPujaSuggestionClicked(false);
    setGemSuggestionClicked(false);
    setRudrakshaSuggesionClicked(false);
    setSadhesatiSuggestionClicked(false);
    setButtonClicked(true);
    setButtonName(buttonName);
  };
  return (
    <>
      <KundliInput />
      {userInfo && Object.keys(userInfo).length !== 0 && (
        <>
          <BasicDetails />
          <Slider {...settings}>
            <button
              onClick={() =>
                handleButtons(setPujaSuggestionClicked, "pujaSuggestion")
              }
              type="button"
              className={`suggestion-button w-100 ${
                buttonName === "pujaSuggestion"
                  ? "suggestion-clicked"
                  : "suggestion-not-clicked"
              }`}
            >
              <small>{t("Puja Suggestion")}</small>
            </button>
            <button
              onClick={() =>
                handleButtons(setGemSuggestionClicked, "gemSuggestion")
              }
              type="button"
              className={`suggestion-button w-100 ${
                buttonName === "gemSuggestion"
                  ? "suggestion-clicked"
                  : "suggestion-not-clicked"
              }`}
            >
              <small>{t("Basic Gem")}</small>
            </button>
            <button
              onClick={() =>
                handleButtons(
                  setRudrakshaSuggesionClicked,
                  "rudrakshaSuggestion"
                )
              }
              type="button"
              className={`suggestion-button w-100 ${
                buttonName === "rudrakshaSuggestion"
                  ? "suggestion-clicked"
                  : "suggestion-not-clicked"
              }`}
            >
              <small>{t("Rudraksha")}</small>
            </button>
            <button
              onClick={() =>
                handleButtons(
                  setSadhesatiSuggestionClicked,
                  "sadhesatiSuggestion"
                )
              }
              type="button"
              className={`suggestion-button w-100 ${
                buttonName === "sadhesatiSuggestion"
                  ? "suggestion-clicked"
                  : "suggestion-not-clicked"
              }`}
            >
              <small>{t("Sadhesati")}</small>
            </button>
          </Slider>
          <div className="pt-3 pt-lg-5">
            {pujaSuggestionClicked && <PujaSuggestion />}
            {gemSuggestionClicked && <GemSuggestion />}
            {rudrakshaSuggesionClicked && <RudrakshaSuggestion />}
            {sadhesatiSuggestionClicked && <SadhesatiSuggestion />}
          </div>
        </>
      )}
    </>
  );
};

export default SuggestionAndRemadies;
