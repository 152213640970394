import { useState } from "react";
import ShareModal from "../../Components/Modal/Share/Sharemodal";
import moment from "moment";
import { Link } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "../../img/icons/calendar.svg";
import { ReactComponent as LocationIcon } from "../../img/icons/location.svg";
import { ReactComponent as PublicIcon } from "../../img/icons/public.svg";
import { ReactComponent as PrivateIcon } from "../../img/icons/private.svg";
import Pin from "../../img/icons/pin.svg";
import Users from "../../img/icons/users.svg";
import Date from "../../img/icons/date.svg";

const Eventcard = ({ event, index }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // console.log(event)

  return (
    <>
      <div
        className="gap-2 rounded-2xl font-poppins"
        key={index}
        style={{ height: "514px", width: "362px" }}
      >
        <Link to={`/event/${event?._id}`} style={{ textDecoration: 'none', WebkitUserDrag: "none", WebkitTouchCallout: "none",color: 'inherit'}}>
          <img
            className="cursor-pointer rounded-2xl"
            src={event?.imageURL ? `${event?.imageURL}` : "/img/no-event-icon.jpg"}
            alt={event?.title}
            style={{ height: "285px", width: "362px", WebkitUserDrag: "none", WebkitTouchCallout: "none" }}
            draggable="false"
          />

          <div className="">
            <div className={`flex flex-col mt-3 justify-between ${event?.title.length < 30 && "mb-1"}`}>
              <h5 className="text-base font-medium md:text-xl">{event?.title}</h5>
              <h6 className="text-sm font-normal">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
            </div>
            {event?.userStatus && (
              <div className="flex ml-auto status-div">
                <span className="flex items-center justify-center w-full status-heading">
                  Status
                </span>
                <span className="flex items-center justify-center w-full status-value">
                  {event?.userStatus}
                </span>
              </div>
            )}
            <p className="flex gap-2 text-sm text-gray-400">
              <img src={Date} alt="pin" className="w-4 h-4" />
              <span className="event-time">
                {moment(event?.startDate).format("dddd , ll")} (
                {moment(event?.startDate).format("hh:mm A")} )
              </span>
            </p>
            <p className="flex gap-2 text-sm text-gray-400">
              <img src={Pin} alt="pin" className="w-4 h-4" />
              {event?.address2}
            </p>
            <div className="flex">
              <img src={Users} alt="pin" className="w-4 h-4" />
              <p className="text-sm text-gray-400">100+ people are Going</p>
            </div>
          </div>
        </Link>
      </div>

      {/* <div key={index} className="col">
        <div className="mx-auto card event-card">
          <Link to={`/event/${event?._id}`}>
            <img
              className="card-img-top img-fluid"
              style={{
                cursor: "pointer",
              }}
              src={event?.imageURL ? event?.imageURL : "/img/no-event-icon.jpg"}
              alt="event-img"
            />
          </Link>
          <div className="card-body">
            <div
              className={`d-flex justify-content-between ${
                event?.title.length < 30 && "mb-2"
              }`}
            >
              <h5 className="card-title">{event?.title}</h5>
              <div
                className="d-flex align-items-center event-type"
                style={{
                  backgroundColor: `${
                    event?.categoryId?.categoryName === "Temple Event"
                      ? "#7efd5e"
                      : event?.categoryId?.categoryName === "Family Event"
                      ? "#FF8FE0"
                      : event?.categoryId?.categoryName === "Social Event"
                      ? "#85D3FF"
                      : "#CBFF37"
                  }`,
                }}
              >
                {event?.categoryId?.categoryName}
              </div>
            </div>
            {event?.userStatus && (
              <div className="d-flex status-div ms-auto">
                <span className="d-flex justify-content-center align-items-center w-100 status-heading">
                  Status
                </span>
                <span className="d-flex justify-content-center align-items-center w-100 status-value">
                  {event?.userStatus}
                </span>
              </div>
            )}
            <p className="gap-2 card-text d-flex">
              <Calendar style={{ height: "13px", width: "13px" }} />
              <span className="event-time">
                {moment(event?.startDate).format("dddd | ll")} |
                {moment(event?.startDate).format(" hh:mm A")}
              </span>
            </p>
            <p className="gap-2 card-text event-address d-flex">
              <Location style={{ height: "13px", width: "13px" }} />
              {event?.address2}
            </p>
          </div>
        </div>
      </div> */}
      <ShareModal show={show} close={handleClose} />
    </>
  );
};

export default Eventcard;
