import React from "react";
import "./HolidayPageStyles.scss";

function HolidaysData({ holidays }) {
  if (!holidays) {
    return <p>No holidays available for the selected category.</p>;
  }

  const nonEmptyMonths = holidays.filter((month) => month.length > 0);

  if (nonEmptyMonths.length === 0) {
    return <p>No holidays available for the selected category.</p>;
  }

  return (
    <>
      <div className="mt-5 mb-5">
        <div className="container">
          <div className="row g-4">
            {nonEmptyMonths.map((month, index) => {
              const firstHolidayDate = new Date(month[0].date);

              return (
                <div className="col-lg-4 col-md-4 col-sm-4 col-6" key={index}>
                  <div className="card h-100" style={{ minWidth: "10rem" }}>
                    <div className="card-body">
                      <p className="card-text month-name">
                        {firstHolidayDate.toLocaleString("en-US", {
                          month: "long",
                        })}
                      </p>
                      <ul>
                        {month.map((holiday, i) => (
                          <li key={i}>
                            <strong>{holiday.title}:</strong> {holiday.date}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default HolidaysData;
