import React from "react";
import { useTranslation } from "react-i18next";
import useMatchMakingFetch from "../../../Hooks/useMatchMakingFetch";

const MatchAstroDetails = () => {
  const { t } = useTranslation();
  const url = `https://json.astrologyapi.com/v1/match_astro_details`;
  const { data: matchAstroDetails } = useMatchMakingFetch(url);

  let keys;
  if (matchAstroDetails.length !== 0) {
    keys = Object.keys(matchAstroDetails?.male_astro_details);
  }
  return (
    <>
      {matchAstroDetails && (
        <div className="shadow rounded-5">
          <div className="text-center details-header">
            {t("Match Astro Details")}
          </div>
          <table className="table table-hover border-top details-body kundli-gradient-2">
            <thead>
              <tr
                style={{
                  fontFamily: "Playfair Display",
                  backgroundColor: "#483f50",
                  fontWeight: 700,
                  // fontSize: "1.47vw",
                  color: "#e28e00",
                }}
              >
                <th className="text-center" scope="col">
                  {t("Male")}
                </th>
                <th className="text-center" scope="col">
                  {t("astro details")}
                </th>
                <th className="text-center" scope="col">
                  {t("Female")}
                </th>
              </tr>
            </thead>
            {keys?.map((key, index) => (
              <tbody
                key={index}
                className="montserrat-font text-white text-center"
              >
                <tr>
                  <td>{matchAstroDetails?.male_astro_details[key]}</td>
                  <td
                    className="montserrat-font fw-semibold text-black"
                    // style={{ fontSize: "1.47vw" }}
                  >
                    {t(`${key.toLowerCase()}`)}
                  </td>
                  <td>{matchAstroDetails?.female_astro_details[key]}</td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
    </>
  );
};

export default MatchAstroDetails;
