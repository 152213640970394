import i18n from "i18next";

import Backend from "i18next-http-backend";

import { initReactI18next } from "react-i18next";

import tEn from "./locales/en/translation.json";
import tGu from "./locales/gu/translation.json";
import tHi from "./locales/hi/translation.json";
import tMr from "./locales/mr/translation.json";
import tOr from "./locales/or/translation.json";
import tTa from "./locales/ta/translation.json";
import tBn from "./locales/bn/translation.json";
import tMl from "./locales/ml/translation.json";
import tTe from "./locales/te/translation.json";
import tKn from "./locales/kn/translation.json";

const defaultLanguage = localStorage.getItem("REACT_APP_LANGUAGE");

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: tEn,
      },
      gu: {
        translation: tGu,
      },
      hi: {
        translation: tHi,
      },
      mr: {
        translation: tMr,
      },
      kn: {
        translation: tKn,
      },
      or: {
        translation: tOr,
      },
      ta: {
        translation: tTa,
      },
      bn: {
        translation: tBn,
      },
      ml: {
        translation: tMl,
      },
      te: {
        translation: tTe,
      },
      ms: {
        translation: tEn,
      },
    },
    lng: defaultLanguage ? defaultLanguage.toLowerCase() : "en", //default language

    fallbackLng: defaultLanguage ? defaultLanguage.toLowerCase() : "en", //when specified language translations not present

    //then fallbacklang translations loaded.

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
