import React from "react";
import moment from "moment";
import useHoroscopeFetch from "../../Hooks/useHoroscopeFetch";
import HoroscopeDetails from "./HoroscopeDetails";
import HoroscopeButtons from "./HoroscopeButtons";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Today = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const rashiFromQuery = queryParams.get("rashi")?.toUpperCase() || "ARIES";

  const date = moment(new Date()).format("DD-MM-YYYY");
  const { t } = useTranslation();
  const { data, refetch } = useHoroscopeFetch(date);

  useEffect(() => {
    if (rashiFromQuery) {
      localStorage.setItem("rashi", rashiFromQuery);
    }
  }, [rashiFromQuery]);

  const handleRashi = (rashi) => {
    localStorage.setItem("rashi", rashi);
    refetch();
  };
  return (
    <div>
      <HoroscopeButtons handleRashi={handleRashi} />
      <h5 className="mt-3 montserrat-font mt-lg-5" >
        {t("Today's Horoscope")} : {date}
      </h5>
      {data && <HoroscopeDetails data={data} />}
    </div>
  );
};

export default Today;
