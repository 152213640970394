import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations.json";

const Notes = ({ panchangDetails }) => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div>
            <div className='flex items-center justify-center md:hidden'>
                <div className="mt-8 bg-[#FFF9F0] border border-gray-400 font-poppins font-semibold  rounded-lg flex flex-col items-center justify-center w-11/12">
                    <div className='mt-3 mb-3 text-sm font-semibold '>NOTES</div>
                    <div className='w-6/12 ' >
                        <div className='flex flex-col gap-2 mb-4 text-xs' >
                            <div>
                                {t("Sunrise")} : <span className='text-[#FF695F] font-normal'>
                                    {panchangDetails && panchangDetails['Sunrise'] ? panchangDetails['Sunrise'] : 'No Paksha available'}
                                </span>
                            </div>
                            <div> {t("Gujarati Samvat")}: <span className='text-[#FF695F] font-normal'>
                                {panchangDetails && panchangDetails['Gujarati Samvat'] ? panchangDetails['Gujarati Samvat'] : 'No Gujarati Samvat available'}
                            </span>
                            </div>
                            <div>{t(selectedTranslation.labels.Amanta)}:  <span className='text-[#FF695F] font-normal'>Vaishakha</span></div>
                            <div>{t(selectedTranslation.labels.Pournimanta)}: <span className='text-[#FF695F] font-normal'>Vaishakha</span></div>
                            <div>{t("Weekday")} : <span className='text-[#FF695F] font-normal'>
                                {panchangDetails && panchangDetails['Weekday'] ? panchangDetails['Weekday'] : 'No Weekday available'}
                            </span>
                            </div>
                            <div>{t("Paksha")}: <span className='text-[#FF695F] font-normal'> {panchangDetails && panchangDetails[selectedTranslation.labels.Paksha] ? panchangDetails[selectedTranslation.labels.Paksha] : 'No Paksha available'}
                            </span>
                            </div>
                            <div>{t(selectedTranslation.labels.Tithi)}: <span className='text-[#FF695F] font-normal'> {panchangDetails && panchangDetails[selectedTranslation.labels.gridTithi] ? panchangDetails[selectedTranslation.labels.gridTithi] : 'No Tithi available'}
                            </span>
                            </div>
                            <div>{t(selectedTranslation.labels.nakshatra)}: <span className='text-[#FF695F] font-normal'> {panchangDetails && panchangDetails[selectedTranslation.labels.gridNakshatra] ? panchangDetails[selectedTranslation.labels.gridNakshatra] : 'No Nakshatra available'}
                            </span>
                            </div>
                            <div>{t("Yoga")}: <span className='text-[#FF695F] font-normal'> {panchangDetails && panchangDetails[selectedTranslation.labels.NotesYoga] ? panchangDetails[selectedTranslation.labels.NotesYoga] : 'No Yoga available'}
                            </span>
                            </div>
                            <div>{t(selectedTranslation.labels.karan)}: <span className='text-[#FF695F] font-normal'> {panchangDetails && panchangDetails[selectedTranslation.labels.NotesKarana] ? panchangDetails[selectedTranslation.labels.NotesKarana] : 'No Karana available'}
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col w-9/12'>
                        <div className='h-auto rounded-tr-lg ' >
                            <div className='flex flex-col items-center justify-center'>
                                <h6 className='text-xs font-semibold'>{t(selectedTranslation.labels.vrat)}</h6>
                                <div className='flex flex-col gap-2 '>
                                    {panchangDetails && panchangDetails['festivals'] && panchangDetails['festivals'].length > 0 ? (
                                        panchangDetails['festivals'].map((festival, index) => (
                                            <span key={index} className='text-xs font-semibold '>
                                                {festival}
                                            </span>
                                        ))
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 text-xs rounded-br-lg">
                            <p>
                                {t(selectedTranslation.labels.note)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mt-8 bg-[#FFF9F0] border border-gray-400 font-poppins font-semibold mb-20 rounded-lg w-11/12 hidden md:flex" style={{ height: "469px" }}>
                <div className='w-6/12 custom-scrollbar' style={{ overflow: "auto", whiteSpace: "normal" }} >
                    <div className='flex flex-col gap-2 pt-10 pb-10 pl-10 ' >
                        <div className='mb-3 font-bold'>NOTES</div>
                        <div>
                            {t("Sunrise")} : <span className='text-[#FF695F] font-normal'>
                                {panchangDetails && panchangDetails['Sunrise'] ? panchangDetails['Sunrise'] : 'No Paksha available'}
                            </span>
                        </div>
                        <div> {t("Gujarati Samvat")}: <span className='text-[#FF695F] font-normal'>
                            {panchangDetails && panchangDetails['Gujarati Samvat'] ? panchangDetails['Gujarati Samvat'] : 'No Gujarati Samvat available'}
                        </span>
                        </div>
                        <div>{t(selectedTranslation.labels.Amanta)}:  <span className='text-[#FF695F] font-normal'>Vaishakha</span></div>
                        <div>{t(selectedTranslation.labels.Pournimanta)}: <span className='text-[#FF695F] font-normal'>Vaishakha</span></div>
                        <div>{t("Weekday")} : <span className='text-[#FF695F] font-normal'>
                            {panchangDetails && panchangDetails['Weekday'] ? panchangDetails['Weekday'] : 'No Weekday available'}
                        </span>
                        </div>
                        <div>{t("Paksha")}: <span className='text-[#FF695F] font-normal'> {panchangDetails && panchangDetails[selectedTranslation.labels.Paksha] ? panchangDetails[selectedTranslation.labels.Paksha] : 'No Paksha available'}
                        </span>
                        </div>
                        <div>{t(selectedTranslation.labels.Tithi)}: <span className='text-[#FF695F] font-normal'> {panchangDetails && panchangDetails[selectedTranslation.labels.gridTithi] ? panchangDetails[selectedTranslation.labels.gridTithi] : 'No Tithi available'}
                        </span>
                        </div>
                        <div>{t(selectedTranslation.labels.nakshatra)}: <span className='text-[#FF695F] font-normal'> {panchangDetails && panchangDetails[selectedTranslation.labels.gridNakshatra] ? panchangDetails[selectedTranslation.labels.gridNakshatra] : 'No Nakshatra available'}
                        </span>
                        </div>
                        <div>{t("Yoga")}: <span className='text-[#FF695F] font-normal'> {panchangDetails && panchangDetails[selectedTranslation.labels.NotesYoga] ? panchangDetails[selectedTranslation.labels.NotesYoga] : 'No Yoga available'}
                        </span>
                        </div>
                        <div>{t(selectedTranslation.labels.karan)}: <span className='text-[#FF695F] font-normal'> {panchangDetails && panchangDetails[selectedTranslation.labels.NotesKarana] ? panchangDetails[selectedTranslation.labels.NotesKarana] : 'No Karana available'}
                        </span>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col w-9/12 ' >
                    <div className='p-10 border border-gray-400 rounded-tr-lg custom-scrollbar' style={{ height: "370px", overflow: "auto", whiteSpace: "normal" }}>
                        <div>
                            <h6 className='font-bold'>{t(selectedTranslation.labels.vrat)}</h6>
                            <div className='flex flex-col gap-2 mt-6'>
                                {panchangDetails && panchangDetails['festivals'] && panchangDetails['festivals'].length > 0 ? (
                                    panchangDetails['festivals'].map((festival, index) => (
                                        <span key={index} className='text-sm font-medium '>
                                            {festival}
                                        </span>
                                    ))
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 pl-6 pr-6 text-sm font-normal border border-gray-400 rounded-br-lg custom-scrollbar" style={{ height: "100px", overflow: "auto", whiteSpace: "normal" }}>
                        <p>
                            {t(selectedTranslation.labels.note)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notes;