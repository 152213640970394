import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect } from "react";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import "./SingleArticle.scss";
import GoogleAdSense from "../../GoogleAdSense/GoogleAdSense";
import Helmetcomponent from "../Helmet/helmetcomponent";

const SingleArticle = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  // const REACT_APP_DEV_ENDPOINT = process.env.REACT_APP_DEV_ENDPOINT;REACT_APP_ENDPOINT
  const REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const url = `${REACT_APP_ENDPOINT}article/single/${id}`;

  const { data: article } = useQuery([`articleList`, id], () =>
    axios.get(url).then((data) => data.data.data)
  );

  function removeBrTags(html) {
    return html.replace(/<br\s*[/]?>/gi, "");
  }

  
  const title=article?.name;



  return (
    <>
      
      <div
        // style={{ paddingTop: "2.3%", margin: "20px" }}
        // className="w-75 mx-auto"
        className="container mt-5"
      >
        <Helmetcomponent index="SingleArticle" path={id} data={title}/>
        {article && (
          <div>
            <h3
              style={{ color: "#f16334", fontWeight: 600 }}
              className="mt-5 text-center"
            >
              {article?.name}
            </h3>

            <div className="article-img-div mx-auto shadow m-3">
              <img className="img-fluid" src={article?.image} alt="" />
            </div>

            {article?.publishDate && (
              <div className="card-body py-2 ">
                <h5
                  className="card-title"
                  style={{ color: "#f16334", fontWeight: 600 }}
                >
                  Published at:{" "}
                  <span>
                    {article.publishDate.split("T").join(" ").slice(0, -5)}
                  </span>
                </h5>
              </div>
            )}

            <div className="my-3 shadow-lg border-0 w-100">
              {/* <h5
                className="card-title"
                style={{ color: "#f16334", fontWeight: 600 }}
              >
                Description
              </h5> */}

              <div className="description-container m-5">
                <span>{parse(removeBrTags(article?.description.trim()))}</span>
              </div>
            </div>
          </div>
        )}
        <GoogleAdSense />
      </div>
    </>
  );
};

export default SingleArticle;
