import React, { useEffect } from "react";
import { GoogleLogin } from '@react-oauth/google';
import { BsGoogle } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { getLoginUserData } from "../../store/actions/authActions";
import { jwtDecode } from "jwt-decode";

function GoogleLog({ lng, lat, handleCloseLogin }) {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const dispatch = useDispatch();

  const responseGoogle = async (response) => {
    localStorage.setItem("accessToken", response?.credential);
    const decoded = jwtDecode(response?.credential);

    if (response) {
      handleCloseLogin();
      const UserDetail = {
        userEmail: decoded?.email,
        language: localStorage.getItem("REACT_APP_LANGUAGE"),
        loginType: "1",
        userName: decoded?.email,
        name: decoded?.name,
        imageURL: decoded?.picture,
        firstName: decoded?.given_name,
        lastName: decoded?.family_name,
        location: {
          coordinates: [lng, lat],
        },
        checkuser: true,
      };
      dispatch(getLoginUserData(UserDetail)); 
    }
  };

  useEffect(() => {

  }, []);

  return (
    <>
      <GoogleLogin
        clientId={CLIENT_ID}
        render={(renderProps) => (
          <div className="form-group text-center">
            <button
              type="submit"
              className="login_google"
              name="booking"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <span>
                <BsGoogle style={{ height: "30px", width: "30px" }} />
              </span>
              <span style={{ paddingLeft: "10px", fontWeight: "700" }}>
                Log in with Google
              </span>
            </button>
          </div>
        )}
        onSuccess={(response) => responseGoogle(response)}
        onFailure={(response) => responseGoogle(response)}
      />
    </>
  );
}

export default GoogleLog;
