import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { MatchMakingContext } from "./MatchMaking";

const MatchMakingBasicDetails = () => {
  const { matchMakingInfo } = useContext(MatchMakingContext);

  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column flex-lg-row gap-0 gap-lg-4 pt-3 pt-lg-5">
      <div className="w-100">
        <div className="text-center details-header">
          {t("Basic Details of Male")}
        </div>
        <ul className="list-group list-group-flush details-body kundli-gradient-1">
          <li className="list-group-item bg-transparent">
            <span className="montserrat-font text-white">{t("Name")}: </span>
            <span className="montserrat-font fw-semibold">
              {matchMakingInfo?.m_name}
            </span>
          </li>
          <li className="list-group-item bg-transparent">
            <span className="montserrat-font text-white">
              {t("Date of Birth")}:{" "}
            </span>
            {matchMakingInfo?.m_day < 10 ? (
              <span className="montserrat-font fw-semibold">
                0{matchMakingInfo?.m_day}/
              </span>
            ) : (
              <span className="montserrat-font fw-semibold">
                {matchMakingInfo?.m_day}/
              </span>
            )}
            {matchMakingInfo?.m_month < 10 ? (
              <span className="montserrat-font fw-semibold">
                0{matchMakingInfo?.m_month}/
              </span>
            ) : (
              <span className="montserrat-font fw-semibold">
                {matchMakingInfo?.m_month}/
              </span>
            )}
            <span className="montserrat-font fw-semibold">
              {matchMakingInfo?.m_year}
            </span>
          </li>
          <li className="list-group-item bg-transparent">
            <span className="montserrat-font text-white">
              {t("Time of Birth")}:{" "}
            </span>
            <span className="montserrat-font fw-semibold">
              {matchMakingInfo?.m_hour === 0 ? (
                <>{matchMakingInfo?.m_hour + 12}</>
              ) : matchMakingInfo?.m_hour > 0 &&
                matchMakingInfo?.m_hour < 10 ? (
                <>0{matchMakingInfo?.m_hour}</>
              ) : matchMakingInfo?.m_hour > 12 ? (
                <>{matchMakingInfo?.m_hour - 12}</>
              ) : (
                <>{matchMakingInfo?.m_hour}</>
              )}
              :
              {matchMakingInfo?.m_min < 10 ? (
                <>0{matchMakingInfo?.m_min}</>
              ) : (
                <>{matchMakingInfo?.m_min}</>
              )}
              <> {matchMakingInfo.m_clockTime}</>
            </span>
          </li>
          <li className="list-group-item bg-transparent">
            <span className="montserrat-font text-white">
              {t("Birth Place")}:{" "}
            </span>
            <span className="montserrat-font fw-semibold">
              {matchMakingInfo?.m_place}
            </span>
          </li>
        </ul>
      </div>
      <div className="w-100">
        <div className="text-center details-header">
          {t("Basic Details of Female")}
        </div>
        <ul className="list-group list-group-flush details-body kundli-gradient-2">
          <li className="list-group-item bg-transparent">
            <span className="montserrat-font text-white">{t("Name")}: </span>
            <span className="montserrat-font fw-semibold">
              {matchMakingInfo?.f_name}
            </span>
          </li>
          <li className="list-group-item bg-transparent">
            <span className="montserrat-font text-white">
              {t("Date of Birth")}:{" "}
            </span>
            {matchMakingInfo?.f_day < 10 ? (
              <span className="montserrat-font fw-semibold">
                0{matchMakingInfo?.f_day}/
              </span>
            ) : (
              <span className="montserrat-font fw-semibold">
                {matchMakingInfo?.f_day}/
              </span>
            )}
            {matchMakingInfo?.f_month < 10 ? (
              <span className="montserrat-font fw-semibold">
                0{matchMakingInfo?.f_month}/
              </span>
            ) : (
              <span className="montserrat-font fw-semibold">
                {matchMakingInfo?.f_month}/
              </span>
            )}
            <span className="montserrat-font fw-semibold">
              {matchMakingInfo?.f_year}
            </span>
          </li>
          <li className="list-group-item bg-transparent">
            <span className="montserrat-font text-white">
              {t("Time of Birth")}:{" "}
            </span>
            <span className="montserrat-font fw-semibold">
              {matchMakingInfo?.f_hour === 0 ? (
                <>{matchMakingInfo?.f_hour + 12}</>
              ) : matchMakingInfo?.f_hour > 0 &&
                matchMakingInfo?.f_hour < 10 ? (
                <>0{matchMakingInfo?.f_hour}</>
              ) : matchMakingInfo?.f_hour > 12 ? (
                <>{matchMakingInfo?.f_hour - 12}</>
              ) : (
                <>{matchMakingInfo?.f_hour}</>
              )}
              :
              {matchMakingInfo?.f_min < 10 ? (
                <>0{matchMakingInfo?.f_min}</>
              ) : (
                <>{matchMakingInfo?.f_min}</>
              )}
              <> {matchMakingInfo.f_clockTime}</>
            </span>
          </li>
          <li className="list-group-item bg-transparent">
            <span className="montserrat-font text-white">
              {t("Birth Place")}:{" "}
            </span>
            <span className="montserrat-font fw-semibold">
              {matchMakingInfo?.f_place}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MatchMakingBasicDetails;
