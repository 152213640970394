import React from "react";
import { useTranslation } from "react-i18next";
import useMatchMakingFetch from "../../../Hooks/useMatchMakingFetch";

const MatchMakingReport = () => {
  const { t } = useTranslation();
  const url = `https://json.astrologyapi.com/v1/match_making_report`;
  const { data: matchMakingReport } = useMatchMakingFetch(url);

  return (
    <>
      {matchMakingReport && (
        <>
          <div className="shadow rounded-5">
            <div className="text-center details-header">
              {t("Match Making Report")}
            </div>
            <div className="details-body kundli-gradient-4">
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                  {t("Is Ashtakoot Present?")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {matchMakingReport?.ashtakoota?.status === true
                    ? t("yes")
                    : t("no")}
                </span>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                  {t("Ashtakoot Received Points")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {matchMakingReport?.ashtakoota?.received_points}
                </span>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                  {t("Is Manglik Dosh Present?")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {matchMakingReport?.manglik?.status === true
                    ? t("yes")
                    : t("no")}
                </span>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                  {t("Ashtakoot Male Percentage")}
                </h5>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-black fw-semibold"
                    role="progressbar"
                    style={{
                      width: `${matchMakingReport?.manglik?.male_percentage}%`,
                      fontFamily: "montserrat",
                      fontSize: "13px",
                    }}
                    aria-valuenow={matchMakingReport?.manglik?.male_percentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {matchMakingReport?.manglik?.male_percentage}%
                  </div>
                </div>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                  {t("Ashtakoot Female Percentage")}
                </h5>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-black fw-semibold"
                    role="progressbar"
                    style={{
                      width: `${matchMakingReport?.manglik?.female_percentage}%`,
                      fontFamily: "montserrat",
                      fontSize: "13px",
                    }}
                    aria-valuenow={
                      matchMakingReport?.manglik?.female_percentage
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {matchMakingReport?.manglik?.female_percentage}%
                  </div>
                </div>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                  {t("Is Rajju Dosh Present?")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {matchMakingReport?.rajju_dosha?.status === true
                    ? t("yes")
                    : t("no")}
                </span>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                  {t("Is Vedha Dosh Present?")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {matchMakingReport?.vedha_dosha?.status === true
                    ? t("yes")
                    : t("no")}
                </span>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white mb-1 mb-lg-2">
                  {t("Conclusion")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {matchMakingReport?.conclusion?.match_report}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MatchMakingReport;
