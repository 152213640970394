import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
  name: "event",
  initialState: {
    popular: [],
    nearBy: [],
    eventId: "",
    search: [],
    categories: [],
    events: [],
    eventsAll: [],
    isClose: false,
    categoryList: [],
    shareEmails: [],
    templocation: "",
  },
  reducers: {
    popularEvent: (state, action) => {
      state.popular = action.payload;
    },
    nearByEvent: (state, action) => {
      state.nearBy = action.payload;
    },
    templocation: (state, action) => {
      state.templocation = action.payload;
    },
    findEvent: (state, action) => {
      state.search = action.payload;
    },
    eventById: (state, action) => {
      state.eventId = action.payload;
    },
    categories: (state, action) => {
      state.categories = action.payload;
    },
    eventsAll: (state, action) => {
      state.eventsAll = action.payload;
    },
    events: (state, action) => {
      state.events = action.payload;
      state.isClose = false;
    },
    createEvent: (state, action) => {
      state.isClose = true;
    },
    update_event: (state, action) => {
      state.isClose = true;
      const index = state.events?.data?.data?.findIndex(
        (data) => data?._id === action?.payload?._id
      );
      if (index !== undefined && index !== -1) {
        const edit = state.events?.data?.data;
        edit[index] = action?.payload;
      } else {
        state.eventId = action.payload;
      }
    },
    deleteEvent: (state, action) => {
      state.events?.data?.data?.splice(
        state.events?.data?.data?.findIndex(
          (data) => data?._id === action.payload
        ),
        1
      );
    },
    close_popup: (state, action) => {
      state.isClose = false;
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    getShareContact: (state, action) => {
      state.shareEmails = action.payload?.map((data) => {
        return {
          value: data?.emailAddresses[0].value,
          label: data?.emailAddresses[0].value,
        };
      });
    },
  },
});

export const eventAction = eventSlice.actions;
export const { close_popup } = eventSlice.actions;
export default eventSlice;
