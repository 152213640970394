import React from "react";
import { caste } from "./data";
import {FaFilter} from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";


export const FilterModal=(props)=>{
     const [casteValue, setCaseValue] = React.useState('');
    const handleChange = (event) => {
        props.onCasteChange(event.target.value);
        setCaseValue(event.target.value);
      };
    const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
    return (
        <>    
        <Button variant="primary" onClick={handleOpen}>
        <FaFilter />
        </Button>
        <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.text}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Caste</Form.Label>
              <Form.Select
                id="demo-simple-select-helper"
                value={casteValue}
                label="Select Caste"
                onChange={handleChange}
              >
                <option value="">
                <em>Show All</em>
                </option>
                {caste.map((caste) => (
                    <option  key={caste} value={caste}>
                    {caste}
                    </option>))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
        </>
    )
}