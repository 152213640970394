import React, { useContext, useState } from "react";
import { allHolidaysContext } from "./HolidaysContext";
import { Nav } from "react-bootstrap";
import HolidaysData from "./HolidaysData";
import "./HolidayPageStyles.scss";
import Helmetcomponent from "../Helmet/helmetcomponent";

function HolidaysPage() {
  const { holidays, loading, error } = useContext(allHolidaysContext);
  const [activeTab, setActiveTab] = useState("Hindu");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  if (loading) {
    return (
      <div className="text-center mt-4 fs-3 text-danger">
        ...Loading Holidays...
      </div>
    );
  }

  if (error) {
    return <div>Error fetching holidays: {error.message}</div>;
  }
  window.scrollTo(0, 0);

  return (
    <>
      <div className="text-center">
        <Helmetcomponent index="Holidays" path={""} data={""}/>
        <h1 className="holiday-header fs-2 mt-3">Holidays</h1>

        <Nav fill variant="pills" defaultActiveKey={activeTab}>
          <Nav.Item>
            <Nav.Link
              eventKey="Hindu"
              onClick={() => handleTabClick("Hindu")}
              className="me-1"
              style={{
                backgroundColor: activeTab === "Hindu" ? "orange" : "",
              }}
            >
              Hindu
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Christian"
              onClick={() => handleTabClick("Christian")}
              className="me-1"
              style={{
                backgroundColor: activeTab === "Christian" ? "orange" : "",
              }}
            >
              Christian
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Government"
              onClick={() => handleTabClick("Government")}
              className="me-1"
              style={{
                backgroundColor: activeTab === "Government" ? "orange" : "",
              }}
            >
              Government
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Islamic"
              onClick={() => handleTabClick("Islamic")}
              className="me-1"
              style={{
                backgroundColor: activeTab === "Islamic" ? "orange" : "",
              }}
            >
              Islamic
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <hr style={{ color: "tomato" }} />
      <div className="mt-3">
        <HolidaysData holidays={holidays[activeTab]} />
      </div>
    </>
  );
}

export default HolidaysPage;
