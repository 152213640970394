import React, { useContext, useEffect, useState } from "react";
import { allShopItemsContext } from "../BharatWebShopContext/ShopTodaysDeals";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import "./TodaysDealsStyles.css";
import Helmetcomponent from "../Helmet/helmetcomponent";

function BharatShopIndex() {
  const { loading, error, fetchTodayDealsData } =
    useContext(allShopItemsContext);
  const [shopData, setShopData] = useState([]);
  const lang = localStorage.getItem("lang");

  useEffect(() => {
    const fetchTodayDeals = async () => {
      try {
        const todayDeals = await fetchTodayDealsData();
        setShopData(todayDeals);
      } catch (error) {
        console.error("Error fetching today's deals:", error.message);
      }
    };

    fetchTodayDeals();
  }, [fetchTodayDealsData]);

  if (loading) {
    return (
      <div>
        <h3 className="m-3 text-center text-warning">
          ...Loading Today's Deals...
        </h3>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const getImageUrl = (imagePath) => {
    const baseUrl = "https://api.exaweb.in:5200";
    const normalizedPath = imagePath.replace(/^(\.\.\/)+/, "");
    return `${baseUrl}/${normalizedPath}`;
  };

  const handleItemClick = (itemId) => {
    window.location.href = `https://shop.bharatcalendars.in/categoryItems/${itemId}?lang=${lang}`;
  };

  // console.log(shopData[0])

  return (
    <>
      <div className="mt-4 text-dark">
        <Helmetcomponent index={"TodaysDeals"} path={""} data={""} />
        <div>
          <p
            style={{
              fontFamily: "montserrat-font",
              fontWeight: "900",
              textAlign: "center",
            }}
            className="title fs-2"
          >
            Today's Deals
          </p>
        </div>
        <div className="mb-3 row row-cols-1 row-cols-md-2 g-5 justify-content-md-between justify-content-center"
          style={{
            borderRadius: "18px",
            padding: "9px",
            backgroundColor: "wheat",
            display: "flex",
            justifyContent: "space-between",
            margin: "0",
          }}
        >
          {shopData.map((item, index) => {
            const itemName = item.itemName || "";
            const truncatedName = itemName.slice(0, 15);
            return (
              <div
                className="rounded bg-dark "
                key={item._id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "170px",
                  height: "280px",
                  margin: "5px",
                  cursor: "pointer",
                }}
                onClick={() => handleItemClick(item._id)}
              >
                <div className="text-center card-img-top" style={{ flex: "1" }}>
                  <img
                    className="img-fluid"
                    src={getImageUrl(item.imageGallery[0])}
                    alt={item.itemName}
                    style={{
                      maxWidth: "100%",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "7px",
                      marginTop: '1rem',
                      display: "block",
                    }}
                  />
                </div>
                <div
                  className=" text-light"
                  style={{ padding: '0.5rem 0rem' }}
                >
                  <p className="item-name fs-6">{truncatedName}</p>
                </div>
                <div className="card-footer" style={{ backgroundColor: 'green', height: '50px', marginBottom: '1rem', borderRadius: '1rem' }}>
                  <p className="pt-2 d-flex justify-content-center align-items-center">
                    <span className="fs-5 text-light newPrice">
                      ₹{item.discPrice}
                    </span>
                    <span className="fs-6 text-decoration-line-through oldPrice" style={{color:'lightgray'}}>
                      ₹{item.price}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <p className="p-1 text-end">
        <Link
          to="/all-today-deals"
          className="text-decoration-none text-danger fs-6"
        >
          See All
          <IoIosArrowDroprightCircle />
        </Link>
      </p>
    </>
  );
}

export default BharatShopIndex;

// import React, { useContext, useEffect, useState } from "react";
// import { allShopItemsContext } from "../BharatWebShopContext/ShopTodaysDeals";
// import { IoIosArrowDroprightCircle } from "react-icons/io";
// import { Link } from "react-router-dom";
// import "./TodaysDeals.css";

// function BharatShopIndex() {
//   const { loading, error, getTodayDeals } = useContext(allShopItemsContext);
//   const [shopData, setShopData] = useState([]);

//   useEffect(() => {
//     const fetchTodayDeals = async () => {
//       try {
//         const todayDeals = await getTodayDeals();
//         setShopData(todayDeals);
//       } catch (error) {
//         console.error("Error fetching today's deals:", error);
//       }
//     };

//     fetchTodayDeals();
//   }, [getTodayDeals]);

//   if (loading) {
//     return (
//       <div>
//         <h3 className="m-3 text-center text-warning">
//           ...Loading Today's Deals...
//         </h3>
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   const getImageUrl = (imagePath) => {
//     const baseUrl = "https://shop.bharatcalendars.in";
//     const normalizedPath = imagePath.replace(/^(\.\.\/)+/, "");
//     return `${baseUrl}/${normalizedPath}`;
//   };

//   const handleItemClick = (itemId) => {
//     window.location.href = `https://shop.bharatcalendars.in/categoryItems/${itemId}`;
//   };

//   return (
//     <>
//       <div className="mt-4 text-dark">
//         <div>
//           <p className="mb-3 text-center fs-4 fw-bold">Today's Deals</p>
//         </div>
//         <div className="flex-wrap d-flex justify-content-center">
//           {shopData.map((item) => (
//             <div
//               key={item._id}
//               className="m-2 card"
//               style={{ width: "18rem", cursor: "pointer" }}
//               onClick={() => handleItemClick(item._id)}
//             >
//               <img
//                 src={getImageUrl(item.imageGallery[0])}
//                 className="card-img-top"
//                 alt={`Item ${item._id}`}
//                 style={{ objectFit: "cover", height: "200px" }}
//               />
//               <div className="card-body">
//                 <h5 className="card-title">{item.itemName}</h5>
//                 <p className="card-text">
//                   ₹{item.discPrice} <del>₹{item.price}</del>
//                 </p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//       <p className="p-1 text-end">
//         <Link
//           to="/all-today-deals"
//           className="text-decoration-none text-danger fs-5"
//         >
//           See All <IoIosArrowDroprightCircle />
//         </Link>
//       </p>
//     </>
//   );
// }

// export default BharatShopIndex;
