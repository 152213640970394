import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Search_icon from "../../img/icons/search.svg";
import Location from "../../img/icons/location.svg";
import Language from "../../img/icons/lang.svg";
import Shop from "../../img/icons/shop.svg";
import Cart from "../../img/icons/cart.svg";
import Signin from "../../img/icons/signin.svg";
import translations from "../../Components/Translations/Translations.json";
import { useDispatch, useSelector } from "react-redux";
import { findEventApi, nearByEventApi } from "../../store/actions/eventActions";
import { getSearchUserData } from "../../store/actions/userActions";
import Search from "../../search/search";
import Geocode from "react-geocode";
import { usePlacesWidget } from "react-google-autocomplete";
import ReactGA from "react-ga";
import LoginModal from "../../Components/Modal/Login/LoginModal";
import ProfileDropdown from "../../Components/ProfileDropdown";
import { authAction } from "../../store/slice/authSlice";
import { toast } from "react-toastify";
import CustomLink from "../../Components/Header/CustomLink";
import NewEvent from "../../Components/Modal/newEvent/newEvent";
import Down from "../../img/icons/down.svg";
import { FaBars, FaTimes } from 'react-icons/fa';
import Mobilenav from "./mobilenav";
import { PiDotsThreeVerticalBold } from "react-icons/pi";


const Nav = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [fOptions, setFOptions] = useState([]);
  const [activeLink, setActiveLink] = useState(null);
  const logo = localStorage.getItem("REACT_APP_LOGO");
  const l = localStorage.getItem("REACT_APP_LANGUAGE");
  const selectedLang = localStorage.getItem("lang");
  const [search, setSearch] = useState("");
  const [showsearch, setShowSearch] = useState(false);
  const handleCloseSearch = () => setShowSearch(false);
  const [chanSearch, setchanSearch] = useState("Events");
  const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [status, setStatus] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const[isOpenEvents, setIsOpenEvents] = useState(false);
  // const [menuOpen, setMenuOpen] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Events");
  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
  const handleCloseCreate = () => setShowCreate(false);
  const handleShowCreate = () => setShowCreate(true);
  const [showInput, setShowInput] = useState(false);
  // const [isLocationInputVisible, setLocationInputVisible] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const inputRef = useRef(null);
  const locationInputRef = useRef(null);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);
  const eventsRef = useRef(null);
  const searchRef = useRef(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const userData = useSelector((state) => state?.user);
  const token = localStorage.getItem("token");
  const MEASUREMENT_ID = "G-KWM6FFPZ1T";
  ReactGA.initialize(MEASUREMENT_ID);

  const limit = "15";
  const options = [
    { value: "en", label: t("English") },
    { value: "gu", label: t("Gujarati") },
    { value: "hi", label: t("Hindi") },
    { value: "mr", label: t("Marathi") },
    { value: "kn", label: t("Kannada") },
    { value: "ta", label: t("Tamil") },
    { value: "bn", label: t("Bengali") },
    { value: "ml", label: t("Malayalam") },
    { value: "te", label: t("Telugu") },
    { value: "or", label: t("Odia") },
  ];

  // const handleToggleMenu = () => {
  //   setMenuOpen(!menuOpen);
  // };

  const handleProfileMenuToggle = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };


  const storedLanguage = localStorage.getItem("lang") || "en";
  const selectedTranslation = translations[storedLanguage] || translations.en;

  useEffect(() => {
    if (l && (l.toLowerCase() === "en" || l.toLowerCase() === "ms")) {
      setFOptions(options);
    } else {
      setFOptions(
        options.filter((option) => {
          return option.value === l.toLowerCase() || option.value === "en";
        })
      );
    }
  }, [l]);

  useEffect(() => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          if (position?.coords?.latitude && position?.coords?.longitude) {
            localStorage.setItem(
              "location",
              JSON.stringify({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
            );
          }
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          setStatus("Unable to retrieve your location");
        }
      );
    }
  }, []);

  function handleLogout() {
    dispatch(authAction.userLogout());
    localStorage.removeItem("token");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    sessionStorage.removeItem("tempLocation");
    sessionStorage.removeItem("temp_location_name");
    toast("Logout Successful");
  }

  // const handleLocationIconClick = () => {
  //   setLocationInputVisible(true);
  //   setTimeout(() => {
  //     locationInputRef.current?.focus();
  //   }, 100);
  // };

  // const handleLocationInputBlur = () => {
  //   setLocationInputVisible(false);
  // };

  const completeProfileToast = () => {
    toast.warning("Please Complete Your Profile First", { theme: "colored" });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closedropevents = () => {
    setIsOpenEvents(!isOpenEvents);
  }

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleIconClick = () => {
    setShowInput(true);
  };

  // const handleInputBlur = () => {
  //   setShowInput(false);
  // };

  // const handleInputBlur = (event) => {
  //   if (searchRef.current && !searchRef.current.contains(event.relatedTarget)) {
  //     setShowInput(false);
  //   }
  // };


  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_KEY,
    onPlaceSelected: async (place) => {
      console.log(place);
      sessionStorage.setItem("temp_location_name", place?.formatted_address);
      await sessionStorage.setItem(
        "tempLocation",
        JSON.stringify({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        })
      );
      const data = {
        eventStatus: "APPROVED",
        distance: 300000,
        language: userData?.userdetail?.lang
          ? userData?.userdetail?.language
          : localStorage.getItem("REACT_APP_LANGUAGE"),
        nearBy: true,
        lat: place?.geometry?.location?.lat(),
        long: place?.geometry?.location?.lng(),
      };
      dispatch(nearByEventApi(data));
    },
  });

  useEffect(() => {
    if ((lat, lng)) {
      Geocode.setApiKey(GOOGLE_MAPS_KEY);
      Geocode.setLanguage("en");
      Geocode.setRegion("es");
      Geocode.setLocationType("ROOFTOP");
      Geocode.enableDebug();
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);
        },
        (error) => { }
      );
    }
  }, [lat, lng, GOOGLE_MAPS_KEY]);

  const handleLanguageChange = (selectedOption) => {
    localStorage.setItem("lang", selectedOption.value);
    i18n.changeLanguage(selectedOption.value);
  };

  const handleLinkClick = (index) => {
    setSearch("");
    setActiveLink(index);
    window.scrollTo(0, 0);
  };

  const handleTruncateText = (text, limit) => {
    return text.length > limit ? `${text.substring(0, limit)}..` : text;
  };

  const handledrop = () => {
    setIsOpen(!isOpen);
  };

  function handleSearch(query) {
    setShowSearch(true);
    if (query.length > 2) {
      const searchdata = {
        searchQuery: query,
        language: localStorage.getItem("REACT_APP_LANGUAGE"),
      };
      dispatch(findEventApi(searchdata));
    }
    if (query.length > 2) {
      const searchUser = {
        name: query,
        lang: localStorage.getItem("REACT_APP_LANGUAGE"),
      };
      dispatch(getSearchUserData(searchUser));
    }
  }

  const handleCreateEventsClick = () => {
    setIsOpen(!isOpen);
    console.log("Create Events Clicked");
    ReactGA.event({
      category: "Navigation",
      action: "Click",
      label: "Create Events Link",
    });
    if (token) {
      if (!userData?.newUser) {
        setShowCreate(true);
      } else {
        completeProfileToast();
      }
    } else {
      setShowLogin(true);
    }
    //handleNavItemClick();
  };

  

  // const handlesearchref = (event) => {
  //     if (searchRef.current && !searchRef.current.contains(event.target)) {
  //       setShowInput(false);
  //     }
  // };





  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsProfileMenuOpen(false);
    }
  
    if (eventsRef.current && !eventsRef.current.contains(event.target)) {
      setIsOpenEvents(false);
    }
  
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowInput(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside); 
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  




  useEffect(() => {
    i18n.changeLanguage(selectedLang ? selectedLang : l.toLowerCase());
  }, [selectedLang, l, i18n]);

  console.log(localStorage.getItem("lang"));

  return (
    <nav className="sticky top-0 z-50 w-full bg-white md:px-6 md:py-0 font-poppins">
      <div className="md:hidden">
        <Mobilenav />
      </div>

      <div className="hidden md:block">
        <div
          className="flex items-center justify-between w-full px-4 py-2 "
          style={{ Height: "80px" }}
        >
          <Link to="/" onClick={() => handleLinkClick(-1)}>
            <img
              src={logo}
              alt="Bharat Calendar Logo"
              className="h-16 w-40 min-w-[160px] "
            />
          </Link>
          <div className="flex mx-4">
            <div className="flex items-center gap-4">
              <div className="hidden xl:flex">
                <div className="relative inline-block w-16 min-w-[100px]" ref={eventsRef}>
                  <button
                    onClick={closedropevents}
                    className="w-full bg-gray-200 border border-gray-300 rounded-l-md focus:outline-none"
                    style={{ padding: '5px' }}
                  >
                    <div className="flex items-center w-3/4 gap-1 ml-2">
                      <span>
                        {handleTruncateText(
                          t('Events'),
                          localStorage.getItem('lang') === 'en' ? 6 : 2
                        )}
                      </span>
                      <img
                        src={Down}
                        alt={t('Down')}
                        className="w-5 h-5 left-3"
                      />
                    </div>
                  </button>
                  {isOpenEvents && (
                    <div className="absolute left-0 z-10 flex flex-col p-2 mt-1 bg-white border border-gray-300 rounded-md w-34">
                      <Link
                        to="/event"
                        className="block p-2 text-black no-underline cursor-pointer hover:bg-gray-100"
                        onClick={() => closedropevents()}
                      >
                        {t('Events')}
                      </Link>
                      <Link
                        to="/"
                        className="block p-2 text-black no-underline cursor-pointer hover:bg-gray-100 whitespace-nowrap"
                        onClick={() => handleCreateEventsClick()}
                      >
                        {t('Create Events')}
                      </Link>
                    </div>
                  )}
                </div>
                <div className="relative flex items-center">
                  <input
                    type="text"
                    placeholder={t("search")}
                    className="min-w-[130px] w-4/5 px-10 py-2 border border-gray-300 h-9 rounded-r-md focus:outline-none"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                  <img
                    src={Search_icon}
                    alt={t("Search")}
                    className="absolute w-5 h-5 left-3"
                  />
                </div>
              </div>
              <div className="flex xl:hidden">
                <div className="relative inline-block w-16 min-w-[100px]" ref={dropdownRef}>
                  <button
                    onClick={toggleDropdown}
                    className="w-full bg-gray-200 border border-gray-300 rounded-l-md focus:outline-none"
                    style={{ padding: '5px' }}
                  >
                    <div className="flex items-center w-3/4 gap-1 ml-2">
                      <span>
                        {handleTruncateText(
                          t('Events'),
                          localStorage.getItem('lang') === 'en' ? 6 : 2
                        )}
                      </span>
                      <img
                        src={Down}
                        alt={t('Down')}
                        className="w-5 h-5 left-3"
                      />
                    </div>
                  </button>
                  {isOpen && (
                    <div className="absolute left-0 z-10 flex flex-col p-2 mt-1 bg-white border border-gray-300 rounded-md w-34">
                      <Link
                        to="/event"
                        className="block p-2 text-black no-underline cursor-pointer hover:bg-gray-100"
                        onClick={() => handledrop()}
                      >
                        {t('Events')}
                      </Link>
                      <Link
                        to="/"
                        className="block p-2 text-black no-underline cursor-pointer hover:bg-gray-100 whitespace-nowrap"
                        onClick={() => handleCreateEventsClick()}
                      >
                        {t('Create Events')}
                      </Link>
                    </div>
                  )}
                </div>
                <div className={`relative ${showInput ? 'filter blur-sm' : ''}`}>
                  <div className="relative flex items-center border border-gray-300 cursor-pointer w-9 h-9 rounded-br-md rounded-tr-md" onClick={handleIconClick}>
                    <img src={Search_icon} alt={t("Search")} className="absolute inset-0 w-5 h-5 m-auto" />
                  </div>
                </div>

                {showInput && (
                  <div className="fixed inset-0 z-50 flex items-start justify-center bg-black bg-opacity-50" >
                    <div className="flex flex-col h-full p-4 bg-white rounded-lg" ref={searchRef}>
                      <h1 className="mb-4 text-3xl text-black">Search Events In Bharat Calendar</h1>

                      <div className="relative">
                        <input
                          type="text"
                          className="relative z-50 w-full px-4 py-2 bg-white border border-gray-300 rounded-md focus:outline-none"
                          placeholder={t("Search")}
                          // onBlur={handleInputBlur}
                          
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            handleSearch(e.target.value);
                          }}
                          autoFocus
                        />

                        {search.length > 2 && (
                          <div className="absolute left-0 right-0 z-40 mt-2 bg-white rounded-md bottom-16">
                            <Search
                              show={showsearch}
                              close={handleCloseSearch}
                              setSearch={() => setSearch('')}
                              searchBy={chanSearch}
                              className="w-full"
                              setShowInput={setShowInput}
                            />
                          </div>
                        )}
                      </div>

                    </div>
                  </div>
                )}
              </div>
              <div className="relative flex items-center">
                <input
                  className="w-11/12 min-w-[140px] px-10 py-2 border border-gray-300 rounded-md h-9 focus:outline-none"
                  ref={ref}
                  type="text"
                  defaultValue={
                    sessionStorage.getItem("temp_location_name")
                      ? sessionStorage.getItem("temp_location_name")
                      : null
                  }
                  placeholder={handleTruncateText(t("Enter_Location"), limit)}
                  style={{ textIndent: "0" }}
                // onFocus={handleInputFocus}
                // onBlur={handleInputBlur}
                />
                <img
                  src={Location}
                  alt={t("Location")}
                  className="absolute w-5 h-5 text-gray-400 left-3"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 text-black">
            <div className="" >
              <Select
                options={fOptions}
                classNamePrefix="selectbox"
                onChange={handleLanguageChange}
                className="min-w-[120px] w-11/12"
                defaultValue={
                  selectedLang === null && l === "MS"
                    ? options[0]
                    : selectedLang
                      ? options[
                      options.findIndex((option) => option.value === selectedLang)
                      ]
                      : options[
                      options.findIndex(
                        (option) => option.value === l.toLowerCase()
                      )
                      ]
                }
                placeholder={t("Language")}
              />
            </div>
            <div className="items-center hidden gap-4 xl:flex xl:flex-row">

              <a
                href="https://shop.bharatcalendars.in/"
                className="flex gap-1 text-black no-underline"
              >
                <img src={Shop} alt={t("Shop")} className="w-5 h-5 left-3" />
                <button className="">
                  {handleTruncateText(selectedTranslation.labels.shop, 5)}
                </button>
              </a>
              <a
                href="https://shop.bharatcalendars.in/myCart"
                className="flex gap-1 text-black no-underline"
              >
                <img src={Cart} alt={t("Cart")} className="w-5 h-5 left-3" />
                <button className="">
                  {handleTruncateText(selectedTranslation.labels.cart, 6)}
                </button>
              </a>
              <div className="flex gap-1 ">
                {!token && !userData?.userdetail?.imageURL ? (
                  <CustomLink to="" className="" onClick={() => handleShowLogin()}>
                    {" "}
                    <div className="flex gap-1">
                      <img
                        src={Signin}
                        alt={t("Sign In")}
                        className="w-5 h-5 left-3"
                      />
                      <button className="font-normal text-black whitespace-nowrap">
                        {handleTruncateText(t("Sign In"), localStorage.getItem("lang") === 'en' ? 8 : 6)}
                      </button>
                    </div>
                  </CustomLink>
                ) : (
                  <ProfileDropdown
                    className="mt-2"
                    handleLogout={() => handleLogout()}
                  />
                )}
              </div>
              <Link to="/buykundli">
                <button className="px-4 py-2 text-white bg-pink rounded-3xl whitespace-nowrap">
                  {handleTruncateText(selectedTranslation.labels.buykundli, localStorage.getItem("lang") === 'en' ? 10 : 6)}
                </button>
              </Link>
            </div>
            <div className="relative" ref={menuRef}>
              <div className="flex items-start justify-start xl:hidden">
                <button className="text-black" onClick={handleProfileMenuToggle}>
                  <PiDotsThreeVerticalBold size={24} /> {/* 3-dot icon */}
                </button>
              </div>
              {isProfileMenuOpen && (
                <div className="absolute z-10 flex flex-col items-center justify-center w-40 px-2 py-1 mt-6 bg-white border-2 rounded-md shadow-lg right-4 border-grey-300 xl:hidden">
                  <div className="flex w-full gap-1 p-2 hover:bg-gray-200">
                    {!token && !userData?.userdetail?.imageURL ? (
                      <CustomLink to="" className="" onClick={() => handleShowLogin()}>
                        <div className="flex gap-2">
                          <img src={Signin} alt="Sign In" className="w-5 h-5" />
                          <button className="font-normal text-black whitespace-nowrap">
                            {handleTruncateText(t("Sign In"), localStorage.getItem("lang") === 'en' ? 8 : 6)}
                          </button>
                        </div>
                      </CustomLink>
                    ) : (
                      <ProfileDropdown className="mt-2" handleLogout={() => handleLogout()} />
                    )}
                  </div>
                  <a
                    href="https://shop.bharatcalendars.in/"
                    className="flex items-center w-full gap-2 p-2 text-black no-underline hover:bg-gray-200"
                  >
                    <img src={Shop} alt="Shop" className="w-5 h-5" />
                    <button className="">
                      {handleTruncateText(selectedTranslation.labels.shop, 5)}
                    </button>
                  </a>
                  <a
                    href="https://shop.bharatcalendars.in/myCart"
                    className="flex items-center w-full gap-2 p-2 text-black no-underline hover:bg-gray-200"
                  >
                    <img src={Cart} alt="Cart" className="w-5 h-5" />
                    <button className="">
                      {handleTruncateText(selectedTranslation.labels.cart, 6)}
                    </button>
                  </a>
                  <Link to="/buykundli" className="block p-2">
                    <button className="px-4 py-2 text-white bg-pink rounded-3xl whitespace-nowrap" onClick={handleProfileMenuToggle}>
                      {handleTruncateText(selectedTranslation.labels.buykundli, localStorage.getItem("lang") === 'en' ? 10 : 6)}
                    </button>
                  </Link>
                </div>
              )}
            </div>

          </div>
        </div>
        <div
          className="flex flex-wrap items-center justify-center w-full gap-3 pt-2 pb-3 text-black"
          style={{ Height: "36px" }}
        >
          <Link
            to="/horoscope"
            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 0 ? "text-orange-500" : "text-black"
              }`}
            onClick={() => handleLinkClick(0)}
          >
            {t("Horoscope")}
          </Link>
          <Link
            to="/panchang"
            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 1 ? "text-orange-500" : "text-black"
              }`}
            onClick={() => handleLinkClick(1)}
          >
            {t("Panchang")}
          </Link>
          <Link
            to="/kundli"
            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 2 ? "text-orange-500" : "text-black"
              }`}
            onClick={() => handleLinkClick(2)}
          >
            {t("Kundli")}
          </Link>
          <Link
            to="/festivals"
            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 3 ? "text-orange-500" : "text-black"
              }`}
            onClick={() => handleLinkClick(3)}
          >
            {t(selectedTranslation.labels.festivals)}
          </Link>
          <Link
            to="/event"
            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 4 ? "text-orange-500" : "text-black"
              }`}
            onClick={() => handleLinkClick(4)}
          >
            {t("Events")}
          </Link>
          <Link
            to="/articles"
            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 5 ? "text-orange-500" : "text-black"
              }`}
            onClick={() => handleLinkClick(5)}
          >
            {t(selectedTranslation.labels.articles)}
          </Link>
          <Link
            to="/matrimony"
            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 6 ? "text-orange-500" : "text-black"
              }`}
            onClick={() => handleLinkClick(6)}
          >
            {t(selectedTranslation.labels.matrimony)}
          </Link>
          <Link
            to="/holidays"
            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 7 ? "text-orange-500" : "text-black"
              }`}
            onClick={() => handleLinkClick(7)}
          >
            {t(selectedTranslation.labels.holidays)}
          </Link>
        </div>
        <LoginModal
          show={showLogin}
          handleCloseLogin={() => handleCloseLogin()}
          lng={lng}
          lat={lat}
        />
        {search.length > 2 && (
          <div className="hidden xl:block">
            <Search
              show={showsearch}
              close={handleCloseSearch}
              setSearch={() => setSearch("")}
              searchBy={chanSearch}
            />
          </div>
        )}
        {showCreate && userData?.isAuthenticated === true && (
          <NewEvent show={showCreate} close={handleCloseCreate} />
        )}
      </div>
    </nav>
  );
};

export default Nav;
