import React from 'react';
import Logo from "../../img/footer/bharat_logo.png";
import Instagram from "../../img/icons/instagram.svg";
import Facebook from "../../img/icons/facebook.svg";
import LinkedIn from "../../img/icons/linkedin.svg";
import { Link } from 'react-router-dom';


const Newfooter = () => {
  return (
    <footer className="py-8 text-sm text-white bg-footer font-poppins" style={{height:"484px"}}>
      <div className="container mx-auto text-center">
        <div className="mt-10 mb-4">
          <img src={Logo} alt="Bharat Calendar Logo" className="mx-auto" />
        </div>
        <nav className="mb-10">
          <Link to="/kundli" className="mx-2 text-white no-underline">Kundli</Link>
          <Link to="/event" className="mx-2 text-white no-underline">Browse Events</Link>
          <Link to="/aboutus" className="mx-2 text-white no-underline">About Us</Link>
          <Link to="/faq" className="mx-2 text-white no-underline">FAQ</Link>
          <Link to="/contact-us" className="mx-2 text-white no-underline">Contact Us</Link>
          <Link to="/signup" className="mx-2 text-white no-underline">Sign Up</Link>
        </nav>
        <p className="mb-4">
          Welcome to Bharat Calendars, our aim is to connect all festivals around India in one single place with<br/> the Indian calendar and provide cultural information for its breathtaking diversity and people.
        </p>
        <div className="flex justify-center mt-10 mb-10">
          <a href="https://www.instagram.com/bharatcalendar/" target='_blank' className="mx-2 text-white" aria-label="Instagram">
          <img src={Instagram} alt="Instagram" style={{height:"40px",width:"40px"}}/>
          </a>
          <a href="https://www.facebook.com/indiacalendar" target='_blank' className="mx-2 text-white" aria-label="Facebook">
            <img src={Facebook} alt="Facebook" style={{height:"40px",width:"40px"}}/>
          </a>
          <a href="https://www.linkedin.com/company/anivale/" target='_blank' className="mx-2 text-white" aria-label="LinkedIn">
            <img src={LinkedIn} alt="LinkedIn" style={{height:"40px",width:"40px"}}/>
          </a>
        </div>
        <div className="flex justify-end" style={{gap:"210px"}}>
          <div>
          <p className="text-sm">&copy; 2024 Bharat Calendar. All Rights Reserved.</p>
          </div>
          <div className='font-light font-poppins' >
          <a href="https://shop.bharatcalendars.in/privacyPolicy" className="mx-2 no-underline text-[#FAFAFA] hover:text-[#FAFAFA]" >Privacy Policy</a>
          <Link to="/termsandconditions" className="mx-2 text-gray-100 no-underline hover:text-gray-100">Terms of Use</Link>
          <a href="/#" className="mx-2 text-gray-100 no-underline hover:text-gray-100">Cookies</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Newfooter;
