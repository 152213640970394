import React from "react";
import { useTranslation } from "react-i18next";
import useKundliFetch from "../../../Hooks/useKundliFetch";

const RudrakshaSuggestion = () => {
  const { t } = useTranslation();

  const url = `https://json.astrologyapi.com/v1/rudraksha_suggestion`;
  const { data: rudrakshaSuggestion } = useKundliFetch(url);

  return (
    <>
      {rudrakshaSuggestion.length !== 0 && (
        <div className="">
          <div className="text-center details-header">{t("Rudraksha")}</div>
          <div className="details-body kundli-gradient-6 shadow-lg">
            <div className="mb-3 mb-lg-4">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("Name")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {rudrakshaSuggestion?.name}
              </span>
            </div>
            <div className="mb-3 mb-lg-4">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("Details")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {rudrakshaSuggestion?.detail}
              </span>
            </div>
            <div className="">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("Recommend")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {rudrakshaSuggestion?.recommend}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RudrakshaSuggestion;
