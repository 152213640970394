import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";

const Auspicious = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='flex flex-col items-center justify-center mt-14'>
            <h3>{selectedTranslation.labels.detailsaus}</h3>
            <div className='grid grid-cols-4 gap-10 p-10 mt-6 ml-6'>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Brahma Muhurta")}</span><br /> {details?.["Brahma Muhurta"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Pratah Sandhya")}</span> <br />{details?.["Pratah Sandhya"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Abhijit")} </span><br />{details?.Abhijit}</p>
                </div>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Vijaya Muhurta")}</span>  <br />{details?.["Vijaya Muhurta"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Godhuli Muhurta")}</span><br /> {details?.["Godhuli Muhurta"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{selectedTranslation.labels.detailssan}</span> <br />{details?.["Sayahna Sandhya"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Amrit Kalam")} </span><br />{details?.["Amrit Kalam"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Nishita Muhurta")}</span><br /> {details?.["Nishita Muhurta"] || "None"}</p>
                </div>
            </div>
        </div>
    )
}

export default Auspicious;