import { createSlice } from "@reduxjs/toolkit";

export const eventsSlice = createSlice({
  name: "userEvents",
  initialState: {
    isLoading: true,
    past: [],
    current: [],
    future: [],
  },
  reducers: {
    getPastEvents: (state, action) => {
      state.isLoading = true;
      state.past = [];
    },
    PastEventsSuccess: (state, action) => {
      state.isLoading = false;
      state.past = action?.payload;
    },
    PastEventsFailed: (state, action) => {
      state.isLoading = false;
      state.past = action?.payload;
    },

    getCurrentEvents: (state, action) => {
      state.isLoading = true;
      state.current = [];
    },
    CurrentEventsSuccess: (state, action) => {
      state.isLoading = false;
      state.current = action?.payload;
    },
    CurrentEventsFailed: (state, action) => {
      state.isLoading = false;
      state.current = action?.payload;
    },

    getFutureEvents: (state, action) => {
      state.isLoading = true;
      state.future = [];
    },
    FutureEventsSuccess: (state, action) => {
      state.isLoading = false;
      state.future = action?.payload;
    },
    FutureEventsFailed: (state, action) => {
      state.isLoading = false;
      state.future = action?.payload;
    },
  },
});

export const eventsAction = eventsSlice.actions;
export default eventsSlice;
