import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";

const Epoch = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='flex flex-col items-center justify-center mt-14'>
            <h3>{selectedTranslation.labels.detailsepoch}</h3>
            <div className='grid grid-cols-4 gap-24 p-10 ml-6'>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Julian Date")}</span> <br />{details?.["Julian Date"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Kaliyuga")}</span><br /> {details?.Kaliyuga || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Kali Ahargana")}</span><br />{details?.["Kali Ahargana"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("National Civil Date")}</span>  <br />{details?.["National Civil Date"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Julian Day")}</span>  <br />{details?.["Julian Day"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Lahiri Ayanamsha")}</span>  <br />{details?.["Lahiri Ayanamsha"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Rata Die")}</span>  <br />{details?.["Rata Die"] || "None"}</p>
                </div>

                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("National Nirayana Date")}</span>  <br />{details?.["National Nirayana Date"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Modified Julian Day")}</span> <br />{details?.["Modified Julian Day"] || "None"}</p>
                </div>
            </div>
        </div>
    )
}

export default Epoch;