import React from "react";
import { useTranslation } from "react-i18next";
import useKundliFetch from "../../../Hooks/useKundliFetch";

const SadhesatiCurrentStatus = () => {
  const { t } = useTranslation();
  const url = "https://json.astrologyapi.com/v1/sadhesati_current_status";
  const { data: sadhesatiCurrentStatus } = useKundliFetch(url);

  return (
    <>
      {sadhesatiCurrentStatus.length !== 0 && (
        <div>
          <div className="text-center details-header">
            {t("Sadhesati Current Status")}
          </div>
          <div className="details-body kundli-gradient-6 shadow-lg">
            <div className="pb-0 pb-lg-4">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("What is Sadhesati")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {sadhesatiCurrentStatus?.what_is_sadhesati}
              </span>
            </div>
          </div>
          <div>
            <div className="text-center details-header">{t("Details")}</div>
            <div className="details-body kundli-gradient-2 shadow-lg">
              <div className="pb-0 pb-lg-4">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Consideration Date")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiCurrentStatus?.consideration_date}
                </span>
              </div>
              <div className="card-body py-1 py-lg-2">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Moon Sign")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiCurrentStatus?.moon_sign}
                </span>
              </div>
              <div className="card-body py-1 py-lg-2">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Saturn Sign")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiCurrentStatus?.saturn_sign}
                </span>
              </div>
              <div className="card-body py-1 py-lg-2">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Is Saturn Retrograde?")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiCurrentStatus?.is_saturn_retrograde === true
                    ? "Yes"
                    : "No"}
                </span>
              </div>
              <div className="card-body py-1 py-lg-2">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Sadhesati Status")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiCurrentStatus?.sadhesati_status === true
                    ? t("yes")
                    : t("no")}
                </span>
              </div>
              <div className="card-body py-1 py-lg-2">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Is Sadhesati Undergoing?")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiCurrentStatus?.is_undergoing_sadhesati}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SadhesatiCurrentStatus;
