import React from "react";
import { GoCheck } from "react-icons/go";
import { IoCloseSharp } from "react-icons/io5";
import useMatchMakingFetch from "../../../Hooks/useMatchMakingFetch";
import { useTranslation } from "react-i18next";
const AshtakootPoints = () => {
  const { t } = useTranslation();
  const url = `https://json.astrologyapi.com/v1/match_ashtakoot_points`;
  const { data: ashtakootPoints } = useMatchMakingFetch(url);

  let keys;
  if (ashtakootPoints.length !== 0) {
    keys = Object.keys(ashtakootPoints).filter((key) => key !== "conclusion");
  }

  return (
    <>
      {ashtakootPoints && (
        <div>
          <div className="text-center details-header">
            {t("Match Ashtakoot Points")}
          </div>
          <table className="table table-hover border-top details-body kundli-gradient-3 shadow">
            <thead>
              <tr
                style={{
                  fontFamily: "Playfair Display",
                  backgroundColor: "#483f50",
                  fontWeight: 700,
                  fontSize: "1.47vw",
                  color: "#e28e00",
                }}
              >
                <th scope="col">{t("Male Koot")}</th>
                <th scope="col">{t("Female Koot")}</th>
                <th scope="col">{t("Attributes")}</th>
                <th scope="col">{t("Description")}</th>
                <th className="text-center" scope="col">
                  {t("total")}
                </th>
                <th className="text-center" scope="col">
                  {t("Received")}
                </th>
                <th className="text-center" scope="col">
                  {t("Matched")}
                </th>
              </tr>
            </thead>
            {keys?.map((key, index) => (
              <tbody
                key={index}
                className="montserrat-font text-white"
                style={{ fontSize: "1.17vw" }}
              >
                <tr>
                  <td>{ashtakootPoints[key]?.male_koot_attribute}</td>
                  <td>{ashtakootPoints[key]?.female_koot_attribute}</td>
                  <td
                    className="text-uppercase"
                    style={{ fontSize: key === "total" && "1.75vw" }}
                  >
                    {t(`${key}`)}
                  </td>
                  <td>{ashtakootPoints[key]?.description}</td>
                  <td
                    className="text-center"
                    style={{ fontSize: key === "total" && "1.75vw" }}
                  >
                    {ashtakootPoints[key]?.total_points}
                  </td>
                  <td
                    className="text-center"
                    style={{ fontSize: key === "total" && "1.75vw" }}
                  >
                    {ashtakootPoints[key]?.received_points}
                  </td>
                  <td className="text-center">
                    {ashtakootPoints[key]?.total_points ===
                    ashtakootPoints[key]?.received_points ? (
                      <GoCheck
                        style={{
                          height: `${key === "total" ? "2.56vw" : "1.47vw"}`,
                          width: `${key === "total" ? "2.56vw" : "1.47vw"}`,
                        }}
                      />
                    ) : (
                      <IoCloseSharp
                        style={{
                          height: `${key === "total" ? "2.56vw" : "1.47vw"}`,
                          width: `${key === "total" ? "2.56vw" : "1.47vw"}`,
                        }}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
          <div className="shadow rounded-5">
            <div className="text-center details-header mt-3 mt-lg-5">
              {t("Conclusion")}
            </div>
            <div
              className="details-body kundli-gradient-3 px-2 px-lg-5"
              style={{ textAlign: "justify" }}
            >
              <span className="montserrat-font text-white">
                {ashtakootPoints?.conclusion?.report}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AshtakootPoints;
