import {
  AiFillFilePdf,
  AiFillFilePpt,
  AiFillFileExcel,
  AiFillFileWord,
  AiFillFile,
} from "react-icons/ai";
import { GrDocumentTxt } from "react-icons/gr";
import { FcVideoFile } from "react-icons/fc";
import { FaFileAudio } from "react-icons/fa";
import { GrDocumentZip } from "react-icons/gr";

export default function validationFileExtension(url) {
  const file_ext = url?.substr(url?.lastIndexOf(".") + 1, url?.length);

  switch (file_ext) {
    case "docx":
      return (
        <AiFillFileWord
          style={{ height: "80px", width: "80px", color: "gray" }}
        />
      );
    case "txt":
      return (
        <GrDocumentTxt
          style={{ height: "50px", width: "50px", color: "gray" }}
        />
      );
    case "pdf":
      return (
        <AiFillFilePdf
          style={{ height: "50px", width: "50px", color: "gray" }}
        />
      );
    case "sheet":
      return (
        <AiFillFileExcel
          style={{ height: "50px", width: "50px", color: "gray" }}
        />
      );
    case "pptx":
      return (
        <AiFillFilePpt
          style={{ height: "50px", width: "50px", color: "gray" }}
        />
      );
    case "mp4":
      return (
        <FcVideoFile style={{ height: "50px", width: "50px", color: "gray" }} />
      );
    case "aac":
      return (
        <FaFileAudio style={{ height: "50px", width: "50px", color: "gray" }} />
      );
    case "zip":
      return (
        <GrDocumentZip
          style={{ height: "50px", width: "50px", color: "gray" }}
        />
      );
    default:
      return (
        <AiFillFile style={{ height: "50px", width: "50px", color: "gray" }} />
      );
  }
}
