import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import slide1 from "../../img/slider/slide.png";
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations.json";
import axios from 'axios';
import Loading from "../../Components/Loading/Loading";
const Newslider = () => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    const text = t(selectedTranslation.labels.shopnow);
    const truncatedShopNow = text.length > 8 ? `${text.slice(0, 8)}...` : text;
    const data_language = i18n.language.toUpperCase();
    const [sliderData, setsliderData] = useState([]);
    const [loading, setLoading] = useState(true);
    console.log("data language :", data_language);
    const fetchbanner = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`https://api.exaweb.in:3004/api/webBanners/?language=${data_language}&sort=ASC&status=1&isToday=true`);
            setsliderData(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }
    const handleTruncateText = (text, limit) => {
        return text.length > limit ? `${text.substring(0, limit)}...` : text;
    };

    useEffect(() => {
        fetchbanner();
    }, [data_language]);
    return (
        <div className='relative'>
            {loading ? <div> <Loading /> </div> :
                <Carousel
                    autoPlay={true} interval={3000} showArrows={false} infiniteLoop={true} showThumbs={false} showStatus={false} transitionTime={500} stopOnHover={true}>
                    {
                        sliderData.map((banner) => {
                            return (
                                <div key={banner.id} className='relative font-poppins'>
                                    <img src={banner.image} alt="slide 2" />
                                    <div className='absolute text-center transform -translate-x-1/2 bottom-3 md:bottom-24 left-1/2'>
                                        <h1 className='text-xs text-black md:text-4xl'>{banner.title}</h1>
                                    </div>
                                    <div className='absolute text-center transform -translate-x-1/2 bottom-7 md:bottom-14 left-1/2'>
                                        <h1 className='text-xs text-black md:text-2xl'>
                                            {handleTruncateText(banner.description, localStorage.getItem("lang") === 'en' ? 8 : 21)}
                                        </h1>
                                    </div>
                                    <div className='absolute text-center transform -translate-x-1/2 bottom-14 md:bottom-20 lg:bottom-40 left-1/2'>
                                        <a href={banner.link} target='_blank'>
                                            <button className='p-0 text-xs text-white rounded-full md:text-lg md:px-4 md:py-2 bg-pink shop'>{truncatedShopNow}</button>
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Carousel>
            }
        </div>
    );
};

export default Newslider;
