import React, { useState, useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Search_icon from "../../img/icons/search.svg";
import Location from "../../img/icons/location.svg";
import Language from "../../img/icons/lang.svg";
import Shop from "../../img/icons/shop.svg";
import Cart from "../../img/icons/cart.svg";
import Signin from "../../img/icons/signin.svg";
import translations from "../../Components/Translations/Translations.json";
import { useDispatch, useSelector } from "react-redux";
import { findEventApi, nearByEventApi } from "../../store/actions/eventActions";
import { getSearchUserData } from "../../store/actions/userActions";
import Search from "../../search/search";
import Geocode from "react-geocode";
import { usePlacesWidget } from "react-google-autocomplete";
import ReactGA from "react-ga";
import LoginModal from "../../Components/Modal/Login/LoginModal";
import ProfileDropdown from "../../Components/ProfileDropdown";
import { authAction } from "../../store/slice/authSlice";
import { toast } from "react-toastify";
import CustomLink from "../../Components/Header/CustomLink";
import NewEvent from "../../Components/Modal/newEvent/newEvent";
import Down from "../../img/icons/down.svg";
import { FaBars, FaTimes } from 'react-icons/fa';

const Mobilenav = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [fOptions, setFOptions] = useState([]);
    const [activeLink, setActiveLink] = useState(null);
    const logo = localStorage.getItem("REACT_APP_LOGO");
    const l = localStorage.getItem("REACT_APP_LANGUAGE");
    const selectedLang = localStorage.getItem("lang");
    const [search, setSearch] = useState("");
    const [showsearch, setShowSearch] = useState(false);
    const handleCloseSearch = () => setShowSearch(false);
    const [chanSearch, setchanSearch] = useState("Events");
    const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const [position, setPosition] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);
    const [status, setStatus] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Events");
    const [showLogin, setShowLogin] = useState(false);
    const handleCloseLogin = () => setShowLogin(false);
    const handleShowLogin = () => setShowLogin(true);
    const handleCloseCreate = () => setShowCreate(false);
    const handleShowCreate = () => setShowCreate(true);
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const userData = useSelector((state) => state?.user);
    const dropdownRef = useRef(null);
    const token = localStorage.getItem("token");
    const MEASUREMENT_ID = "G-KWM6FFPZ1T";
    ReactGA.initialize(MEASUREMENT_ID);

    const limit = "15";
    const options = [
        { value: "en", label: t("English") },
        { value: "gu", label: t("Gujarati") },
        { value: "hi", label: t("Hindi") },
        { value: "mr", label: t("Marathi") },
        { value: "kn", label: t("Kannada") },
        { value: "ta", label: t("Tamil") },
        { value: "bn", label: t("Bengali") },
        { value: "ml", label: t("Malayalam") },
        { value: "te", label: t("Telugu") },
        { value: "or", label: t("Odia") },
    ];

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;

    useEffect(() => {
        if (l && (l.toLowerCase() === "en" || l.toLowerCase() === "ms")) {
            setFOptions(options);
        } else {
            setFOptions(
                options.filter((option) => {
                    return option.value === l.toLowerCase() || option.value === "en";
                })
            );
        }
    }, [l]);

    useEffect(() => {
        if (!navigator.geolocation) {
            setStatus("Geolocation is not supported by your browser");
        } else {
            setStatus("Locating...");
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setStatus(null);
                    if (position?.coords?.latitude && position?.coords?.longitude) {
                        localStorage.setItem(
                            "location",
                            JSON.stringify({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            })
                        );
                    }
                    setLat(position.coords.latitude);
                    setLng(position.coords.longitude);
                },
                (error) => {
                    console.error("Error getting geolocation:", error);
                    setStatus("Unable to retrieve your location");
                }
            );
        }
    }, []);

    function handleLogout() {
        dispatch(authAction.userLogout());
        localStorage.removeItem("token");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        sessionStorage.removeItem("tempLocation");
        sessionStorage.removeItem("temp_location_name");
        toast("Logout Successful");
    }

    const completeProfileToast = () => {
        toast.warning("Please Complete Your Profile First", { theme: "colored" });
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const handledrop = () => {
        setIsOpen(!isOpen);
    };

    const { ref } = usePlacesWidget({
        apiKey: GOOGLE_MAPS_KEY,
        onPlaceSelected: async (place) => {
            console.log(place);
            sessionStorage.setItem("temp_location_name", place?.formatted_address);
            await sessionStorage.setItem(
                "tempLocation",
                JSON.stringify({
                    lat: place?.geometry?.location?.lat(),
                    lng: place?.geometry?.location?.lng(),
                })
            );
            const data = {
                eventStatus: "APPROVED",
                distance: 300000,
                language: userData?.userdetail?.lang
                    ? userData?.userdetail?.language
                    : localStorage.getItem("REACT_APP_LANGUAGE"),
                nearBy: true,
                lat: place?.geometry?.location?.lat(),
                long: place?.geometry?.location?.lng(),
            };
            dispatch(nearByEventApi(data));
        },
    });

    useEffect(() => {
        if ((lat, lng)) {
            Geocode.setApiKey(GOOGLE_MAPS_KEY);
            Geocode.setLanguage("en");
            Geocode.setRegion("es");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(lat, lng).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    console.log(address);
                },
                (error) => { }
            );
        }
    }, [lat, lng, GOOGLE_MAPS_KEY]);

    const handleLanguageChange = (selectedOption) => {
        localStorage.setItem("lang", selectedOption.value);
        i18n.changeLanguage(selectedOption.value);
    };

    const handleLinkClick = (index) => {
        setSearch("");
        setActiveLink(index);
    };

    const handleTruncateText = (text, limit) => {
        return text.length > limit ? `${text.substring(0, limit)}...` : text;
    };

    function handleSearch(query) {
        setShowSearch(true);
        if (query.length > 2) {
            const searchdata = {
                searchQuery: query,
                language: localStorage.getItem("REACT_APP_LANGUAGE"),
            };
            dispatch(findEventApi(searchdata));
        }
        if (query.length > 2) {
            const searchUser = {
                name: query,
                lang: localStorage.getItem("REACT_APP_LANGUAGE"),
            };
            dispatch(getSearchUserData(searchUser));
        }
    }

    const handleCreateEventsClick = () => {
        setIsOpen(!isOpen);
        console.log("Create Events Clicked");
        ReactGA.event({
            category: "Navigation",
            action: "Click",
            label: "Create Events Link",
        });
        if (token) {
            if (!userData?.newUser) {
                setShowCreate(true);
            } else {
                completeProfileToast();
            }
        } else {
            setShowLogin(true);
        }
        //handleNavItemClick();
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
    };
    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        i18n.changeLanguage(selectedLang ? selectedLang : l.toLowerCase());
    }, [selectedLang, l, i18n]);
    return (
        <div className="relative sticky top-0 z-50">
            <div className="flex justify-between">
                <div>
                    <button
                        className="p-2 text-3xl"
                        onClick={handleToggleMenu}
                    >
                        {menuOpen ? <FaTimes /> : <FaBars />}
                    </button>
                </div>
                <div>
                    <Link to="/" className="flex items-center space-x-4" onClick={() => handleLinkClick(-1)}>
                        <img
                            src={logo}
                            alt="Bharat Calendar Logo"
                            className="h-12 w-36"
                        />
                    </Link>
                </div>
                <div className="flex w-20 mt-3 text-sm">
                    {!token && !userData?.userdetail?.imageURL ? (
                        <CustomLink to="" className="" onClick={() => handleShowLogin()}>
                            {" "}
                            <div className="flex gap-1">
                                <img
                                    src={Signin}
                                    alt={t("Sign In")}
                                    className="w-5 h-5 "
                                />
                                <button className="font-normal text-black ">
                                    {handleTruncateText(t("Sign In"),localStorage.getItem("lang") === 'en' ? 8 : 5)}
                                </button>
                            </div>
                        </CustomLink>
                    ) : (
                        <ProfileDropdown
                            className="mt-2"
                            handleLogout={() => handleLogout()}
                        />
                    )}
                </div>
            </div>

            {menuOpen && (
                <div className="absolute right-0 z-10 w-full bg-white shadow-md h-72 top-12">
                    <div className="flex flex-col items-center justify-center mt-2 text-sm">
                        <div className="flex mb-2 ml-3 text-sm">
                            <div className="relative inline-block w-18 " ref={dropdownRef}>
                                <button
                                    onClick={toggleDropdown}
                                    className="w-full bg-gray-200 border border-gray-300 rounded-l-md focus:outline-none"
                                    style={{ padding: "5px" }}
                                >
                                    <div className="flex items-center gap-1 ml-2">
                                        <span>{handleTruncateText(t("Events"),localStorage.getItem("lang") === 'en' ? 7 : 5)}</span>
                                        <img
                                            src={Down}
                                            alt={t("Down")}
                                            className="w-3 h-3 left-3"
                                        />
                                    </div>

                                </button>
                                {isOpen && (
                                    <div className="absolute left-0 z-10 flex flex-col p-2 mt-1 bg-white border border-gray-300 rounded-md w-34">
                                        <Link
                                            to="/event"
                                            className="block p-2 text-black no-underline cursor-pointer hover:bg-gray-100"
                                            onClick={() => handledrop()}
                                        >
                                            {t("Events")}
                                        </Link>
                                        <Link
                                            to="/"
                                            className="block p-2 text-black no-underline cursor-pointer hover:bg-gray-100 whitespace-nowrap"
                                            onClick={() => handleCreateEventsClick()}
                                        >
                                            {t("Create Events")}
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <div className="relative flex items-center">
                                <input
                                    type="text"
                                    placeholder={t("search")}
                                    className="w-48 h-8 px-10 py-2 border border-gray-300 rounded-r-md focus:outline-none"
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value);
                                        handleSearch(e.target.value);
                                    }}
                                />
                                <img
                                    src={Search_icon}
                                    alt={t("Search")}
                                    className="absolute w-4 h-4 left-3"
                                />
                            </div>
                        </div>
                        <div className="relative flex items-center h-full mb-2 ml-3">
                            <input
                                className="w-64 h-8 px-10 py-2 border border-gray-300 rounded-md focus:outline-none"
                                ref={ref}
                                type="text"
                                defaultValue={
                                    sessionStorage.getItem("temp_location_name")
                                        ? sessionStorage.getItem("temp_location_name")
                                        : null
                                }
                                placeholder={handleTruncateText(t("Enter_Location"), limit)}
                                style={{ textIndent: "0" }}
                            // onFocus={handleInputFocus}
                            // onBlur={handleInputBlur}
                            />
                            <img
                                src={Location}
                                alt={t("Location")}
                                className="absolute w-5 h-5 text-gray-400 left-3"
                            />
                        </div>

                        <div className="w-32 ml-3">
                            <Select
                                options={fOptions}
                                classNamePrefix="selectbox"
                                onChange={handleLanguageChange}
                                defaultValue={
                                    selectedLang === null && l === "MS"
                                        ? options[0]
                                        : selectedLang
                                            ? options[
                                            options.findIndex((option) => option.value === selectedLang)
                                            ]
                                            : options[
                                            options.findIndex(
                                                (option) => option.value === l.toLowerCase()
                                            )
                                            ]
                                }
                                style={{ height: "22px", fontSize: "4px", width: "50px" }}
                                placeholder={t("Language")}
                            />
                        </div>
                    </div>

                    <div className="flex justify-around p-2 mt-2 text-sm">
                        <a
                            href="https://shop.bharatcalendars.in/"
                            className="flex gap-1 text-black no-underline"
                        >
                            <img src={Shop} alt={t("Shop")} className="w-5 h-5 mt-2 left-3" />
                            <button className="">
                                {handleTruncateText(selectedTranslation.labels.shop,localStorage.getItem("lang") === 'en' ? 4 : 3)}
                            </button>
                        </a>
                        <div>  <Link to="/buykundli">
                            <button className="px-4 py-2 text-white bg-pink rounded-3xl whitespace-nowrap">
                                {handleTruncateText(selectedTranslation.labels.buykundli,localStorage.getItem("lang") === 'en' ? 10 : 5)}
                            </button>
                        </Link></div>
                        <a
                            href="https://shop.bharatcalendars.in/myCart"
                            className="flex gap-1 text-black no-underline"
                        >
                            <img src={Cart} alt={t("Cart")} className="w-5 h-5 mt-2 left-2" />
                            <button className="">{t(selectedTranslation.labels.cart)}</button>
                        </a>
                    </div>

                    <div className="grid grid-cols-4 gap-3 p-2 mt-3 ml-6 text-sm">
                        <Link
                            to="/horoscope"
                            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 0 ? "text-orange-500" : "text-black"
                                }`}
                            onClick={() => handleLinkClick(0)}
                        >
                            {handleTruncateText(t("Horoscope"),localStorage.getItem("lang") === 'en' ? 9 : 5)}
                        </Link>
                        <Link
                            to="/panchang"
                            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 1 ? "text-orange-500" : "text-black"
                                }`}
                            onClick={() => handleLinkClick(1)}
                        >
                            {handleTruncateText(t("Panchang"),localStorage.getItem("lang") === 'en' ? 9 : 5)}
                        </Link>
                        <Link
                            to="/kundli"
                            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 2 ? "text-orange-500" : "text-black"
                                }`}
                            onClick={() => handleLinkClick(2)}
                        >
                            {handleTruncateText(t("Kundli"),localStorage.getItem("lang") === 'en' ? 9 : 5)}
                        </Link>
                        <Link
                            to="/festivals"
                            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 3 ? "text-orange-500" : "text-black"
                                }`}
                            onClick={() => handleLinkClick(3)}
                        >
                            {handleTruncateText(selectedTranslation.labels.festivals,localStorage.getItem("lang") === 'en' ? 9 : 5)}
                        </Link>
                        <Link
                            to="/event"
                            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 4 ? "text-orange-500" : "text-black"
                                }`}
                            onClick={() => handleLinkClick(4)}
                        >
                            {handleTruncateText(t("Events"),localStorage.getItem("lang") === 'en' ? 6 : 4)}
                        </Link>
                        <Link
                            to="/articles"
                            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 5 ? "text-orange-500" : "text-black"
                                }`}
                            onClick={() => handleLinkClick(5)}
                        >
                            {handleTruncateText(selectedTranslation.labels.articles,localStorage.getItem("lang") === 'en' ? 8 : 5)}
                        </Link>
                        <Link
                            to="/matrimony"
                            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 6 ? "text-orange-500" : "text-black"
                                }`}
                            onClick={() => handleLinkClick(6)}
                        >
                            {handleTruncateText(selectedTranslation.labels.matrimony,localStorage.getItem("lang") === 'en' ? 9 : 5)}
                        </Link>
                        <Link
                            to="/holidays"
                            className={`mr-5 hover:no-underline custom-link browse-event ${activeLink === 7 ? "text-orange-500" : "text-black"
                                }`}
                            onClick={() => handleLinkClick(7)}
                        >
                            {handleTruncateText(selectedTranslation.labels.holidays,localStorage.getItem("lang") === 'en' ? 9 : 5)}
                        </Link>
                    </div>
                </div>
            )}
            <LoginModal
                show={showLogin}
                handleCloseLogin={() => handleCloseLogin()}
                lng={lng}
                lat={lat}
            />
            {search.length > 2 && (
                <Search
                    show={showsearch}
                    close={handleCloseSearch}
                    setSearch={() => setSearch("")}
                    searchBy={chanSearch}
                />
            )}
            {showCreate && userData?.isAuthenticated === true && (
                <NewEvent show={showCreate} close={handleCloseCreate} />
            )}
        </div>
    )
}

export default Mobilenav