import React, { useContext } from "react";
import { KundliContext } from "../Kundli";
import BasicDetails from "../BasicDetails";
import KundliInput from "../KundliInput";
import useKundliFetch from "../../../Hooks/useKundliFetch";
import { useTranslation } from "react-i18next";

const NumeroTable = () => {
  const { userInfo } = useContext(KundliContext);
  const { t } = useTranslation();
  const url = `https://json.astrologyapi.com/v1/numero_table`;
  const { data: numeroDetails } = useKundliFetch(url);

  let keys;
  if (numeroDetails.length !== 0) {
    keys = Object.keys(numeroDetails);
  }
  return (
    <>
      <KundliInput />
      {userInfo && Object.keys(userInfo).length !== 0 && <BasicDetails />}
      {numeroDetails.length !== 0 && (
        <div className="shadow-lg rounded-5">
          <div className="text-center details-header">
            {t("Numero Table").toUpperCase()}
          </div>
          <ul className="list-group list-group-flush details-body kundli-gradient-3">
            {keys?.map((key, index) => (
              <li key={index} className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t(`${key}`)} :{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {numeroDetails[key]}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default NumeroTable;
