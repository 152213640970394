import React, { useContext } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { MatchMakingContext } from "./MatchMaking";

const PapasamyamDetails = () => {
  const { matchMakingInfo } = useContext(MatchMakingContext);
  const { t, i18n } = useTranslation();
  const data_language = i18n.language;

  const url = `https://json.astrologyapi.com/v1/papasamyam_details`;
  const config = {
    headers: {
      Authorization:
        "Basic NjA1NjQ2OjU3NTAyNWYwNzc3MjUzZWI1YjhhZWNhNjlhYWMwN2Zm",
      "Accept-language": data_language,
    },
  };

  const maleDetails = {
    day: matchMakingInfo?.m_day,
    month: matchMakingInfo?.m_month,
    year: matchMakingInfo?.m_year,
    hour: matchMakingInfo?.m_hour,
    min: matchMakingInfo?.m_min,
    lat: matchMakingInfo?.m_lat,
    lon: matchMakingInfo?.m_lon,
    tzone: matchMakingInfo?.m_tzone,
    gender: "male",
  };
  const femaleDetails = {
    day: matchMakingInfo?.f_day,
    month: matchMakingInfo?.f_month,
    year: matchMakingInfo?.f_year,
    hour: matchMakingInfo?.f_hour,
    min: matchMakingInfo?.f_min,
    lat: matchMakingInfo?.f_lat,
    lon: matchMakingInfo?.f_lon,
    tzone: matchMakingInfo?.f_tzone,
    gender: "female",
  };

  const { data: malePapasamyamDetails } = useQuery(
    [`malePapasamyamDetails`, maleDetails],
    () =>
      axios.post(url, maleDetails, config).then((data) => {
        return data.data;
      })
  );

  const { data: femalePapasamyamDetails } = useQuery(
    [`femalePapasamyamDetails`, femaleDetails],
    () =>
      axios.post(url, maleDetails, config).then((data) => {
        return data.data;
      })
  );

  let dataKeys;
  if (malePapasamyamDetails) {
    dataKeys = Object.keys(malePapasamyamDetails);
  }

  return (
    <>
      {malePapasamyamDetails && femalePapasamyamDetails && (
        <div className="row g-2 g-lg-4">
          {/* Male Details */}
          <div className="col-12 col-md-6 col-lg-6">
            <h4 className="montserrat-font fs-3">{t("Male Papasamyam")}</h4>
            {dataKeys?.map((dataKey, index) => (
              <div key={index}>
                {dataKey !== "final" ? (
                  <>
                    <div className="text-center details-header">
                      {t(`Male ${dataKey} Dosha`)}
                    </div>
                    <div
                      className={`details-body kundli-gradient-${index + 1}`}
                    >
                      <ul className="list-group list-group-flush">
                        {malePapasamyamDetails[dataKey]?.dosha?.map(
                          (dosha, index) => (
                            <li
                              key={index}
                              className="list-group-item d-flex text-center bg-transparent"
                            >
                              <div className="card-body py-1">
                                <h6 className="montserrat-font text-white mb-0 mb-lg-2">
                                  {t("Planet")}
                                </h6>
                                <span className="montserrat-font fw-semibold">
                                  {dosha?.planet}
                                </span>
                              </div>
                              <div className="card-body py-1">
                                <h6 className="montserrat-font text-white mb-0 mb-lg-2">
                                  {t("Dosha Units")}
                                </h6>
                                <span className="montserrat-font fw-semibold">
                                  {dosha?.doshaUnits}
                                </span>
                              </div>
                              <div className="card-body py-1">
                                <h6 className="montserrat-font text-white mb-0 mb-lg-2">
                                  {t("Position")}
                                </h6>
                                <span className="montserrat-font fw-semibold">
                                  {dosha?.position}
                                </span>
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center details-header">
                      {t("Male final")}
                    </div>
                    <div className="details-body kundli-gradient-4">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex text-center bg-transparent">
                          <div className="card-body">
                            <h6 className="montserrat-font text-white mb-0 mb-lg-2">
                              {t("total")}
                            </h6>
                            <span className="montserrat-font fw-semibold">
                              {malePapasamyamDetails?.final}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          {/* Female Details */}
          <div className="col-12 col-md-6 col-lg-6">
            <h4 className="montserrat-font fs-3">{t("Female Papasamyam")}</h4>
            {dataKeys?.map((dataKey, index) => (
              <div key={index}>
                {dataKey !== "final" ? (
                  <>
                    <div className="text-center details-header">
                      {t(`Female ${dataKey} Dosha`)}
                    </div>
                    <div
                      className={`details-body kundli-gradient-${index + 1}`}
                    >
                      <ul className="list-group list-group-flush">
                        {femalePapasamyamDetails[dataKey]?.dosha?.map(
                          (dosha, index) => (
                            <li
                              key={index}
                              className="list-group-item d-flex text-center bg-transparent"
                            >
                              <div className="card-body py-1">
                                <h6 className="montserrat-font text-white mb-0 mb-lg-2">
                                  {t("Planet")}
                                </h6>
                                <span className="montserrat-font fw-semibold">
                                  {dosha?.planet}
                                </span>
                              </div>
                              <div className="card-body py-1">
                                <h6 className="montserrat-font text-white mb-0 mb-lg-2">
                                  {t("Dosha Units")}
                                </h6>
                                <span className="montserrat-font fw-semibold">
                                  {dosha?.doshaUnits}
                                </span>
                              </div>
                              <div className="card-body py-1">
                                <h6 className="montserrat-font text-white mb-0 mb-lg-2">
                                  {t("Position")}
                                </h6>
                                <span className="montserrat-font fw-semibold">
                                  {dosha?.position}
                                </span>
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center details-header">
                      {t("Female final")}
                    </div>
                    <div className="details-body kundli-gradient-4">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex text-center bg-transparent">
                          <div className="card-body">
                            <h6 className="montserrat-font text-white mb-0 mb-lg-2">
                              {t("total")}
                            </h6>
                            <span className="montserrat-font fw-semibold">
                              {femalePapasamyamDetails?.final}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PapasamyamDetails;
