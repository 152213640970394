import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations.json";

const Weekdays = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className="grid justify-center grid-cols-7 gap-4 mt-10 mb-3 font-bold text-center">
            <div className="flex flex-col items-center justify-center rounded-lg bg-[#FF695F] text-white font-medium" style={{ height: '60px', width: '169px' }}>
                <div>
                    Monday
                </div>
                <div>
                    <span className='font-medium'>{t(selectedTranslation.labels.monday)}</span>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center font-medium rounded-lg bg-[#F6F6F6]" style={{ height: '60px', width: '169px' }}>
                <div>
                    Tuesday
                </div>
                <div>
                    <span className='font-medium'>{t(selectedTranslation.labels.tuesday)}</span>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center font-medium rounded-lg bg-[#F6F6F6]" style={{ height: '60px', width: '169px' }}>
                <div>
                    Wednesday
                </div>
                <div>
                    <span className='font-medium'>{t(selectedTranslation.labels.wednesday)}</span>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center font-medium rounded-lg bg-[#F6F6F6]" style={{ height: '60px', width: '169px' }}>
                <div>
                    Thursday
                </div>
                <div>
                    <span className='font-medium'>{t(selectedTranslation.labels.thursday)}</span>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center font-medium rounded-lg bg-[#F6F6F6]" style={{ height: '60px', width: '169px' }}>
                <div>
                    Friday
                </div>
                <div>
                    <span className='font-medium'>{t(selectedTranslation.labels.friday)}</span>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center font-medium rounded-lg bg-[#F6F6F6]" style={{ height: '60px', width: '169px' }}>
                <div>
                    Saturday
                </div>
                <div>
                    <span className='font-medium'>{t(selectedTranslation.labels.saturday)}</span>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center font-medium rounded-lg bg-[#F6F6F6]" style={{ height: '60px', width: '169px' }}>
                <div>
                    Sunday
                </div>
                <div>
                    <span className='font-medium'>{t(selectedTranslation.labels.sunday)}</span>
                </div>
            </div>
        </div>
    )
}

export default Weekdays