import Card from 'react-bootstrap/Card';
export const MuiCard =(props) =>{
    return(
        <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={props.data.image} />
            <Card.Body>
                <Card.Title>{props.data.name}</Card.Title>
                <Card.Text>
                    Age:: {props.data.age}
                    <br/>
                    Height:: {props.data.height}
                    <br/>
                    Caste:: {props.data.caste}
                    <br/>
                    Place:: {props.data.location}
                    <br/>
                    <br/>
                    {props.data.description}
                </Card.Text>
            </Card.Body>
      </Card>
    )
}