import React from "react";
import { useTranslation } from "react-i18next";
const EventsCategoryButtons = ({
  categoryList,
  categoryName,
  setCategoryName,
  setCategoryId,
}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center flex-column flex-sm-column flex-lg-row gap-3">
      {categoryList?.map((category, index) => (
        <button
          key={index}
          onClick={() => {
            setCategoryName(category?.categoryName);
            setCategoryId(category?._id);
          }}
          className={`event-type-button montserrat-font ${
            categoryName === category?.categoryName
              ? "event-type-clicked shadow"
              : "event-type-not-clicked"
          }`}
          style={{
            background: `${
              index === 0
                ? "#85D3FF"
                : index === 1
                ? "#7EFD5E"
                : index === 2
                ? "#FF8FE0"
                : "#CBFF37"
            }`,
          }}
        >
          {t(`${category?.categoryName}`)}
        </button>
      ))}
      {/* <button
        onClick={() => handleEventType("Temple Event")}
        className={`event-type-button montserrat-font ${
          eventType === "Temple Event"
            ? "event-type-clicked shadow"
            : "event-type-not-clicked"
        }`}
        style={{
          background: "#7efd5e",
        }}
      >
        {t("Temple Event")}
      </button>
      <button
        onClick={() => handleEventType("Social Event")}
        className={`event-type-button montserrat-font ${
          eventType === "Social Event"
            ? "event-type-clicked shadow"
            : "event-type-not-clicked"
        }`}
        style={{
          background: "#85D3FF",
        }}
      >
        {t("Social Event")}
      </button>
      <button
        onClick={() => handleEventType("Family Event")}
        className={`event-type-button montserrat-font ${
          eventType === "Family Event"
            ? "event-type-clicked shadow"
            : "event-type-not-clicked"
        }`}
        style={{
          background: "#FF8FE0",
        }}
      >
        {t("Family Event")}
      </button>
      <button
        onClick={() => handleEventType("Prayer Event")}
        className={`event-type-button montserrat-font ${
          eventType === "Prayer Event"
            ? "event-type-clicked shadow"
            : "event-type-not-clicked"
        }`}
        style={{
          background: "#CBFF37",
        }}
      >
        {t("Prayer Event")}
      </button> */}
    </div>
  );
};

export default EventsCategoryButtons;
