import React, { useContext,useEffect } from "react";
import "./FestivalPageStyles.css";
import { allFestivalsContext } from "./FestivalContext";
import { Helmet } from "react-helmet-async";
//import { useTranslation } from "react-i18next";
import Helmetcomponent from "../Helmet/helmetcomponent";

function FestivalPage() {
  const { festivals, loading, error } = useContext(allFestivalsContext);
  //const { t } = useTranslation();

  if (loading) {
    return (
      <div className="mt-4 text-center fs-3 text-danger">
        ...Loading festivals...
      </div>
    );
  }

  if (error) {
    return <div>Error fetching festivals: {error.message}</div>;
  }

  
  window.scrollTo(0, 0);
  

  document.title = "BharatCalendars - Festivals";


  return (
    <div className="mb-5">
      <Helmetcomponent index={"Festivals"} path={""} data={""}/>
      <h1 className="m-4 text-center festival-header">Festivals</h1>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {festivals.map((item, index) => (
            <div key={index} className="col-6">
              <div className="card h-100" style={{ minWidth: "10rem" }}>
                <div className="card-body">
                  <p className="card-text festival-date">{item.date}</p>
                  <ul style={{ listStyleType: "disc" }}>
                    {item.festivals.map((festival, festivalIndex) => (
                      <li key={festivalIndex} className="festival-name">
                        {festival}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FestivalPage;
