import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";
import Moonrise from "../../img/icons/moonrise.svg";
import Moonset from "../../img/icons/moonset.svg";
import Sunrise from "../../img/icons/sunrise.svg";
import Sunset from "../../img/icons/sunset.svg";
import Pournimanta from "../../img/icons/pournimanta.svg";
import Tithi from "../../img/icons/tithi.svg";
import Paksha from "../../img/icons/paksha.svg";
import Vikramsamvat from "../../img/icons/vikram.svg";

const Panchang = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='mt-10'>
            <div className='flex flex-row items-center justify-center'>
                <h3>{t("Panchang")}</h3>
            </div>
            <div className='p-6'>
                <div className="grid w-full grid-cols-4 grid-rows-2 gap-16" >
                    <div className="flex flex-row items-center gap-3 text-xs">
                        <img src={Moonrise} alt={t("Moonrise")} className="w-14 h-14" />
                        <div className="text-left">
                            <p className="mt-3 text-lg">
                                <span className="text-lg font-semibold">{details?.Moonrise || "None"}</span><br />
                                <span className="">{t("Moonrise")}</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <img src={Moonset} alt={t("Moonset")} className="w-14 h-14" />
                        <div className="text-left">
                            <p className="mt-3 text-lg">
                                <span className="font-semibold">{details?.Moonset || "None"}</span><br />
                                <span className="">{t("Moonset")}</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <img src={Sunrise} alt={t("Sunrise")} className="w-14 h-14" />
                        <div className="text-lg text-left">
                            <p className="mt-3 text-lg ">
                                <span className="font-semibold">{details?.Sunrise || "None"}</span><br />
                                <span className="">{t("Sunrise")}</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <img src={Sunset} alt={t("Sunset")} className="w-14 h-14" />
                        <div className="text-lg text-left">
                            <p className="mt-3 ">
                                <span className="font-semibold">{details?.Sunset || "None"}</span><br />
                                <span className="">{t("Sunset")}</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <img src={Pournimanta} alt={t("Pournimanta")} className="w-14 h-14" />
                        <div className="mt-2 text-lg text-left">
                            <p className="">
                                <span className="font-semibold">{details?.Chandramasa?.[0]?.split("-")[0] || "None"}</span><br />
                                <span className="">{t("Purnimanta")}</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <img src={Tithi} alt={t("Tithi")} className="w-14 h-14" />
                        <div className="mt-2 text-lg text-left">
                            <p className="">
                                <span className="font-semibold">{localStorage.getItem("lang") === 'te' ? details?.Tithulu?.[1] : details?.Tithi?.[1]   || "None"}</span><br />
                                <span className="">{t(selectedTranslation.labels.tithi)}</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <img src={Paksha} alt={t("Paksha")} className="w-14 h-14" />
                        <div className="mt-2 text-left">
                            <p className="text-lg ">
                                <span className="font-semibold">{localStorage.getItem("lang") === 'te' ? details?.Pakshamulu : details?.Paksha  || "None"}</span><br />
                                <span className="">{t("Paksha")}</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                        <img src={Vikramsamvat} alt={t("Vikram Samvat")} className="w-14 h-14" />
                        <div className="mt-2 text-lg text-left ">
                            <p className="">
                                <span className="font-semibold">
                                    {details?.["Vikram Samvat"]
                                        ? details?.["Vikram Samvat"].map((data, index) => (
                                            <span key={index}>{data}</span>
                                        ))
                                        : "None"}
                                </span>
                                <br />
                                <span className="">{t("Vikram Samvat")}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Panchang;