import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";

const Lunarmonth = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='flex flex-col items-center justify-center mt-20'>
            <h3>{selectedTranslation.labels.detailslunar}</h3>
            <div className='grid grid-cols-4 gap-10 p-4 mt-6 ml-6'>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Vikram Samvat")}</span><br />
                        {details?.["Vikram Samvat"]
                            ? details?.["Vikram Samvat"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Shaka Samvat")}</span> <br />{details?.["Shaka Samvat"] || "None"}<br /></p>
                </div>

                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Gujarati Samvat")}</span><br />{details?.["Gujarati Samvat"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Chandramasa")}</span>  <br />{details?.Chandramasa || "None"}</p>
                </div>
            </div>
        </div>
    )
}

export default Lunarmonth;