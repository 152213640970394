import React from "react";
import { useTranslation } from "react-i18next";
import useMatchMakingFetch from "../../../Hooks/useMatchMakingFetch";

const MatchManglikReport = () => {
  const { t } = useTranslation();
  const url = "https://json.astrologyapi.com/v1/match_manglik_report";
  const { data: matchManglikReport } = useMatchMakingFetch(url);

  let maleManglikBasedOnAspect;
  if (matchManglikReport) {
    maleManglikBasedOnAspect =
      matchManglikReport?.male?.manglik_present_rule?.based_on_aspect;
  }
  let maleManglikBasedOnHouse;
  if (matchManglikReport) {
    maleManglikBasedOnHouse =
      matchManglikReport?.male?.manglik_present_rule?.based_on_house;
  }

  let femaleManglikBasedOnAspect;
  if (matchManglikReport) {
    femaleManglikBasedOnAspect =
      matchManglikReport?.female?.manglik_present_rule?.based_on_aspect;
  }
  let femaleManglikBasedOnHouse;
  if (matchManglikReport) {
    femaleManglikBasedOnHouse =
      matchManglikReport?.female?.manglik_present_rule?.based_on_house;
  }

  return (
    <>
      {matchManglikReport && (
        <>
          <div className="d-flex flex-column flex-lg-row flex-md-row gap-2 gap-lg-4">
            <div className="w-100 card rounded-5 bg-transparent border-0">
              <div className="text-center details-header">
                {t("Male Reports")}
              </div>
              <div className="shadow details-body card-body kundli-gradient-1">
                {/* Manglik Dosh Present */}
                <div className="py-1 py-lg-2">
                  <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                    {t("Is Manglik Dosh Present?")}
                  </h5>
                  <span className="montserrat-font fw-semibold">
                    {matchManglikReport?.male?.is_present === true
                      ? t("yes")
                      : t("no")}
                  </span>
                </div>
                {/* Percentage Of Manglik Dosh */}
                {matchManglikReport?.male?.percentage_manglik_present && (
                  <div className="py-1 py-lg-2">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Percentage Of Manglik Present")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {matchManglikReport?.male?.percentage_manglik_present}
                    </span>
                  </div>
                )}
                {/* Mars Manglik Cancelled */}
                <div className="py-1 py-lg-2">
                  <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                    {t("Is Mars Manglik Cancelled?")}
                  </h5>
                  <span className="montserrat-font fw-semibold">
                    {matchManglikReport?.male?.is_mars_manglik_cancelled ===
                    true
                      ? t("yes")
                      : t("no")}
                  </span>
                </div>
                {/* Percentage Of Manglik After Cancellation */}
                {matchManglikReport?.male
                  ?.percentage_manglik_after_cancellation && (
                  <div className="py-1 py-lg-2">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Percentage Of Manglik After Cancellation")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {
                        matchManglikReport?.male
                          ?.percentage_manglik_after_cancellation
                      }
                    </span>
                  </div>
                )}
                {/* Report */}
                {matchManglikReport?.male?.manglik_report && (
                  <div className="py-1 py-lg-2">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Report")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {matchManglikReport?.male?.manglik_report}
                    </span>
                  </div>
                )}
                {/* Manglik Status */}
                {matchManglikReport?.male?.manglik_status && (
                  <div className="py-1 py-lg-2">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Manglik Status")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {matchManglikReport?.male?.manglik_status
                        .split("_")
                        .join(" ")}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 card rounded-5 bg-transparent border-0">
              <div className="text-center details-header">
                {t("Female Reports")}
              </div>
              <div className="shadow details-body card-body kundli-gradient-2">
                {/* Manglik Dosh Present */}
                <div className="py-1 py-lg-2">
                  <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                    {t("Is Manglik Dosh Present?")}
                  </h5>
                  <span className="montserrat-font fw-semibold">
                    {matchManglikReport?.female?.is_present === true
                      ? t("yes")
                      : t("no")}
                  </span>
                </div>
                {/* Percentage Of Manglik Dosh */}
                {matchManglikReport?.female?.percentage_manglik_present && (
                  <div className="py-1 py-lg-2">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Percentage Of Manglik Present")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {matchManglikReport?.female?.percentage_manglik_present}
                    </span>
                  </div>
                )}
                {/* Mars Manglik Cancelled */}
                <div className="py-1 py-lg-2">
                  <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                    {t("Is Mars Manglik Cancelled?")}
                  </h5>
                  <span className="montserrat-font fw-semibold">
                    {matchManglikReport?.female?.is_mars_manglik_cancelled ===
                    true
                      ? t("yes")
                      : t("no")}
                  </span>
                </div>
                {/* Percentage Of Manglik After Cancellation */}
                {matchManglikReport?.female
                  ?.percentage_manglik_after_cancellation && (
                  <div className="py-1 py-lg-2">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Percentage Of Manglik After Cancellation")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {
                        matchManglikReport?.female
                          ?.percentage_manglik_after_cancellation
                      }
                    </span>
                  </div>
                )}
                {/* Report */}
                {matchManglikReport?.female?.manglik_report && (
                  <div className="py-1 py-lg-2">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Report")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {matchManglikReport?.female?.manglik_report}
                    </span>
                  </div>
                )}
                {/* Manglik Status */}
                {matchManglikReport?.female?.manglik_status && (
                  <div className="py-1 py-lg-2">
                    <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                      {t("Manglik Status")}
                    </h5>
                    <span className="montserrat-font fw-semibold">
                      {matchManglikReport?.female?.manglik_status
                        .split("_")
                        .join(" ")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Manglik Present Rule Based On Aspect  */}
          <div className="d-flex flex-column flex-lg-row flex-md-row gap-4">
            <div className="w-100 card rounded-5 bg-transparent border-0">
              <div
                className="text-center details-header"
                // style={{ fontSize: "2.2vw" }}
              >
                {t("Manglik Present Rule based_on_aspect")}
              </div>
              <div className="shadow details-body card-body kundli-gradient-4">
                <ul className="list-group list-group-flush">
                  {maleManglikBasedOnAspect?.map((rule, index) => (
                    <li key={index} className="list-group-item bg-transparent">
                      <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                        {rule}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-100 card rounded-5 bg-transparent border-0">
              <div className="text-center details-header">
                {t("Manglik Present Rule based_on_aspect")}
              </div>
              <div className="shadow details-body card-body kundli-gradient-5">
                <ul className="list-group list-group-flush">
                  {femaleManglikBasedOnAspect?.map((rule, index) => (
                    <li key={index} className="list-group-item bg-transparent">
                      <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                        {rule}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* Manglik Present RuleBased On House  */}
          <div className="d-flex flex-column flex-lg-row flex-md-row gap-4">
            <div className="w-100 card rounded-5 bg-transparent border-0">
              <div className="text-center details-header">
                {t("Manglik Present Rule based_on_house")}
              </div>
              <div className="shadow details-body card-body kundli-gradient-1">
                <ul className="list-group list-group-flush">
                  {maleManglikBasedOnHouse?.map((rule, index) => (
                    <li key={index} className="list-group-item bg-transparent">
                      <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                        {rule}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-100 card rounded-5 bg-transparent border-0">
              <div className="text-center details-header">
                {t("Manglik Present Rule based_on_house")}
              </div>
              <div className="shadow details-body card-body kundli-gradient-2">
                <ul className="list-group list-group-flush">
                  {femaleManglikBasedOnHouse?.map((rule, index) => (
                    <li key={index} className="list-group-item bg-transparent">
                      <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                        {rule}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* Manglik Cancel Rule  */}
          <div className="d-flex flex-column flex-lg-row flex-md-row gap-4">
            <div className="w-100 card rounded-5 bg-transparent border-0">
              <div className="text-center details-header">
                {t("Manglik Cancel Rule")}
              </div>
              <div className="shadow details-body card-body kundli-gradient-4">
                <ul className="list-group list-group-flush">
                  {matchManglikReport?.male?.manglik_cancel_rule?.length !==
                  0 ? (
                    matchManglikReport?.male?.manglik_cancel_rule?.map(
                      (rule, index) => (
                        <li
                          key={index}
                          className="list-group-item bg-transparent"
                        >
                          <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                            {rule}
                          </span>
                        </li>
                      )
                    )
                  ) : (
                    <li className="list-group-item bg-transparent">
                      <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                        {t("You don't have any Manglik Cancel Rule")}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="w-100 card rounded-5 bg-transparent border-0">
              <div className="text-center details-header">
                {t("Manglik Cancel Rule")}
              </div>
              <div className="shadow details-body card-body kundli-gradient-5">
                <ul className="list-group list-group-flush">
                  {matchManglikReport?.female?.manglik_cancel_rule?.length !==
                  0 ? (
                    matchManglikReport?.female?.manglik_cancel_rule?.map(
                      (rule, index) => (
                        <li
                          key={index}
                          className="list-group-item bg-transparent"
                        >
                          <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                            {rule}
                          </span>
                        </li>
                      )
                    )
                  ) : (
                    <li className="list-group-item bg-transparent">
                      <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                        {t("You don't have any Manglik Cancel Rule")}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {/* Conclusion */}
          <div className="text-center details-header">{t("Conclusion")}</div>
          <div className="shadow details-body card-body kundli-gradient-3">
            <div className="pb-2">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("Matched")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {matchManglikReport?.conclusion?.match === true
                  ? t("yes")
                  : t("no")}
              </span>
            </div>
            <div className="pb-2">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("Report")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {matchManglikReport?.conclusion?.match === true
                  ? matchManglikReport?.conclusion?.report
                  : "No Report is Present"}
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MatchManglikReport;
