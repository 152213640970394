import React from "react";
import { useTranslation } from "react-i18next";
import useMatchMakingFetch from "../../../Hooks/useMatchMakingFetch";

const MatchBirthDetails = () => {
  const { t } = useTranslation();
  const url = `https://json.astrologyapi.com/v1/match_birth_details`;
  const { data: matchBirthDetails } = useMatchMakingFetch(url);

  const maleastroDetails = matchBirthDetails?.male_astro_details;
  const femaleastroDetails = matchBirthDetails?.female_astro_details;

  return (
    <>
      {matchBirthDetails && (
        <>
          <div className="shadow rounded-5 w-100">
            <div className="text-center details-header">
              {t("Male Astro Details")}
            </div>
            <ul className="list-group list-group-flush details-body kundli-gradient-4">
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Name")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {maleastroDetails?.name}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Gender")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {maleastroDetails?.gender}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Birth_Day")} -{" "}
                </span>
                {maleastroDetails?.day < 10 ? (
                  <span className="montserrat-font fw-semibold">
                    0{maleastroDetails?.day}-
                  </span>
                ) : (
                  <span className="montserrat-font fw-semibold">
                    {maleastroDetails?.day}-
                  </span>
                )}
                {maleastroDetails?.month < 10 ? (
                  <span className="montserrat-font fw-semibold">
                    0{maleastroDetails?.month}-
                  </span>
                ) : (
                  <span className="montserrat-font fw-semibold">
                    {maleastroDetails?.month}-
                  </span>
                )}
                <span className="montserrat-font fw-semibold">
                  {maleastroDetails?.year}
                </span>{" "}
                {maleastroDetails?.hour < 10 ? (
                  <span className="montserrat-font fw-semibold">
                    0{maleastroDetails?.hour}
                  </span>
                ) : (
                  <span className="montserrat-font fw-semibold">
                    {maleastroDetails?.hour}
                  </span>
                )}
                :
                {maleastroDetails?.minute < 10 ? (
                  <span className="montserrat-font fw-semibold">
                    0{maleastroDetails?.minute}
                  </span>
                ) : (
                  <span className="montserrat-font fw-semibold">
                    {maleastroDetails?.minute}
                  </span>
                )}
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Latitude of Birthplace")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {maleastroDetails?.latitude}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Longitude of Birthplace")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {maleastroDetails?.longitude}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Time Zone")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {maleastroDetails?.timezone}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Sunrise")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {maleastroDetails?.sunrise}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Sunset")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {maleastroDetails?.sunset}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Ayanamsha")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {maleastroDetails?.ayanamsha}
                </span>
              </li>
            </ul>
          </div>
          <div className="shadow-lg rounded-5 w-100">
            <div className="text-center details-header">
              {t("Female Astro Details")}
            </div>
            <ul className="list-group list-group-flush details-body kundli-gradient-2">
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Name")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {femaleastroDetails?.name}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Gender")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {femaleastroDetails?.gender}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Birth_Day")} :{" "}
                </span>
                {femaleastroDetails?.day < 10 ? (
                  <span className="montserrat-font fw-semibold">
                    0{femaleastroDetails?.day}-
                  </span>
                ) : (
                  <span className="montserrat-font fw-semibold">
                    {femaleastroDetails?.day}-
                  </span>
                )}
                {femaleastroDetails?.month < 10 ? (
                  <span className="montserrat-font fw-semibold">
                    0{femaleastroDetails?.month}-
                  </span>
                ) : (
                  <span className="montserrat-font fw-semibold">
                    {femaleastroDetails?.month}-
                  </span>
                )}
                <span className="montserrat-font fw-semibold">
                  {femaleastroDetails?.year}
                </span>{" "}
                {femaleastroDetails?.hour < 10 ? (
                  <span className="montserrat-font fw-semibold">
                    0{femaleastroDetails?.hour}
                  </span>
                ) : (
                  <span className="montserrat-font fw-semibold">
                    {femaleastroDetails?.hour}
                  </span>
                )}
                :
                {femaleastroDetails?.minute < 10 ? (
                  <span className="montserrat-font fw-semibold">
                    0{femaleastroDetails?.minute}
                  </span>
                ) : (
                  <span className="montserrat-font fw-semibold">
                    {femaleastroDetails?.minute}
                  </span>
                )}
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Latitude of Birthplace")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {femaleastroDetails?.latitude}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Longitude of Birthplace")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {femaleastroDetails?.longitude}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Time Zone")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {femaleastroDetails?.timezone}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Sunrise")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {femaleastroDetails?.sunrise}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Sunset")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {femaleastroDetails?.sunset}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Ayanamsha")} -{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {" "}
                  {femaleastroDetails?.ayanamsha}
                </span>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default MatchBirthDetails;
