import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import translations from "../../Components/Translations/Translations";
import { FiSunrise } from "react-icons/fi";
import { FiSunset } from "react-icons/fi";
import { PiMoon } from "react-icons/pi";
import { SlStar } from "react-icons/sl";
import axios from 'axios';
import Calmoon from "../../img/icons/calmoon.svg";
import { normalDayStyles, getDayStyles } from './styles';
import Festivals from './festivals';
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import Notes from './notes';
import Weekdays from './weekdays';
import { ekadashiTranslations, purnimaTranslations, amavasyaTranslations } from './translations';
import { Link } from 'react-router-dom';


const Calendar = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [holidays, setHolidays] = useState([]);
    const { t, i18n } = useTranslation();
    const app_language = "en";
    const data_language = i18n.language;
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    const date = moment(new Date()).format("DD/MM/YY");
    // const currentDate = moment();// for Dynamic month
    // const monthStart = currentDate.startOf('month');// for Dynamic month
    const [monthStart, setMonthStart] = useState(moment().date(1)); // for testing purpose any month by number  january from 1
    const [panchangDetails, setpanchangDetails] = useState([]);
    const monthEnd = monthStart.clone().endOf('month');
    const startDate = monthStart.day();
    const adjustedStartDate = (startDate === 0 ? 6 : startDate - 1);
    const monthName = monthStart.format('MMMM');
    const [currentYear, setCurrentYear] = useState(moment().year());
    const today = moment();
    const Todaysdate = today.format('DD MMMM YYYY dddd');
    const fetchEvents = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LIVE_URL}/api/panchang/monthly?month=${monthName}&year=${currentYear}&app_language=${app_language}&data_language=${data_language}`);
            setEvents(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching events:", error.message);
        }
    }

    const fetchholidays = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LIVE_URL}/api/holidaylist?country=en.indian&year=${currentYear}`);
            setHolidays(response.data || []);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching holidays:", error.message);
        }
    }

    const fetchpanchang = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LIVE_URL}/api/panchang/daily?date=${date}&app_language=${app_language}&data_language=${data_language}`);
            console.log('response:', response.data);
            setpanchangDetails(response.data);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error("Error fetching panchang:", error.message);
        }
    }

    const isTithiPresent = (todayEvents, tithiName, tithiTranslations) => {
        if (storedLanguage === 'te') {
            if (todayEvents && Array.isArray(todayEvents) && todayEvents.length > 0 &&
                Array.isArray(todayEvents[0].Tithulu)) {
                const tithuluSplit = todayEvents[0].Tithulu[0].split(' ')[0].trim();
                if (tithuluSplit === tithiName) {
                    return true;
                }
                return false;
            }
        }
        else {
            return todayEvents && Array.isArray(todayEvents) && todayEvents.length > 0 &&
                todayEvents[0].Tithi && Array.isArray(todayEvents[0].Tithi) && todayEvents[0].Tithi.length > 0 &&
                tithiTranslations.includes(todayEvents[0].Tithi[0].split(' ')[0]);
        }
    };

    const next = () => {
        const newMonthStart = monthStart.clone().add(1, 'months').date(1);
        if (newMonthStart.month() === 0) {
            setCurrentYear(currentYear + 1);
        }
        setMonthStart(newMonthStart);
    }

    const previous = () => {
        const newMonthStart = monthStart.clone().subtract(1, 'months').date(1);
        if (newMonthStart.month() === 11) {
            setCurrentYear(currentYear - 1);
        }
        setMonthStart(newMonthStart);
    };

    function isSameDay(date1, date2) {
        date2 = moment(date2);
        return (
            date1.year() === date2.year() &&
            date1.month() === date2.month() &&
            date1.date() === date2.date()
        );
    }


    useEffect(() => {
        fetchEvents();
        fetchholidays();
    }, [app_language, data_language, monthStart, currentYear]);


    useEffect(() => {
        fetchpanchang();
    }, [app_language, data_language]);


    const renderDays = () => {
        const days = [];
        for (let i = 0; i < adjustedStartDate; i++) {
            days.push(
                <div
                    key={`empty-${i}`}
                    className="border border-gray-200 rounded-lg"
                    style={{ ...normalDayStyles, height: '150px', width: '169px' }}
                ></div>
            );
        }

        for (let i = 1; i <= monthEnd.date(); i++) {
            const currentDate = monthStart.clone().date(i).format('YYYY-MM-DD');
            let monthNumber = monthStart.month();
            let curr = moment().startOf('year').year(currentYear).month(monthNumber).date(i);
            const today = curr.format("DD MMMM YYYY dddd");
            const isCurrentDate = moment().isSame(currentDate, 'day');  // for Dynamic Day 
            // const isCurrentDate = isSameDay(moment('2024-07-07'), currentDate); // for testing purpose any date
            const isSunday = moment(currentDate).day() === 0;
            const temp = monthStart.clone().date(i).format("YYYY-MM-DD");
            const isHoliday = holidays[monthName] && Array.isArray(holidays[monthName]) ? holidays[monthName].find(h => h.date === temp) : null;
            const todayEvents = events[today];
            const isEkadashi = isTithiPresent(todayEvents, 'ఏకాదశి', ekadashiTranslations);
            const isAmavasya = isTithiPresent(todayEvents, 'అమావాస్య', amavasyaTranslations);
            const isPurnima = isTithiPresent(todayEvents, 'పౌర్ణమి', purnimaTranslations);
            let dayStyles = getDayStyles(isCurrentDate, isEkadashi, isPurnima, isAmavasya, isSunday, isHoliday);
            const dynamicBorder = `1px solid ${dayStyles.customBorderColor}`;
            const tithiLabel = (selectedTranslation.labels.gridTithi);
            const tithiArray = events[today] && events[today][0] && Array.isArray(events[today][0][tithiLabel]) ? events[today][0][tithiLabel] : [];
            const moonsignArray = events[today] && Array.isArray(events[today][0].Moonsign) ? events[today][0].Moonsign : [];
            const nakshatraLabel = (selectedTranslation.labels.gridNakshatra);
            const nakshatraArray = events[today] && events[today][0] && Array.isArray(events[today][0][nakshatraLabel]) ? events[today][0][nakshatraLabel] : [];
            const day=curr.format("DDMMYY");
            days.push(
                <Link to={`/detailedinfo/${day}`} key={i} className="relative" style={dayStyles}>
                    <div className="absolute p-1 rounded-lg top-1 left-1 right-2">
                        <h6 className={`absolute right-0 top-3   `} >
                            <span className={`${dayStyles.innernumberColor} ${dayStyles.numberBackground} p-1 rounded-md text-xs`} style={{ border: dynamicBorder }}>
                                {events[today] && events[today][0].local_date.join(',')}
                            </span>
                        </h6>
                        <span className='text-xs font-medium'>{tithiArray.length > 0 && (
                            <>
                                <div>
                                    {tithiArray[0].split(' ')[0]} {tithiArray[0].split(' ')[2] || ''}
                                </div>
                                {tithiArray.length > 1 && (
                                    <div>
                                        {tithiArray[1].split(' ')[0]}
                                    </div>
                                )}
                            </>
                        )}</span>
                        <div className='flex flex-col mt-1'>
                            <div className='flex flex-row gap-1'>
                                <PiMoon />
                                <h6 className='text-xs font-normal'>
                                    {moonsignArray.length > 0 && (
                                        <>
                                            <div>
                                                {moonsignArray[0].split(' ')[0]} {moonsignArray[0].includes('upto') ? moonsignArray[0].split('upto ')[1].replace(/(AM|PM|,.*$)/g, '').trim() : ''}
                                            </div>
                                            {moonsignArray.length > 1 && (
                                                <div>
                                                    {moonsignArray[1]}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </h6>
                            </div>
                            <div className='flex flex-row gap-1'>
                                <SlStar />
                                <h6 className='text-xs font-normal'>{nakshatraArray.length > 0 && (
                                    <>
                                        <div>
                                            {nakshatraArray[0].split(' ')[0]} {nakshatraArray[0].includes('upto') ? nakshatraArray[0].split('upto ')[1].replace(/(AM|PM|,.*$)/g, '').trim() : ''}
                                        </div>
                                        {nakshatraArray.length > 1 && (
                                            <div>
                                                {nakshatraArray[1].split(' ')[0]}
                                            </div>
                                        )}
                                    </>
                                )}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className={`absolute text-xl font-semibold bottom-6 left-2 ${dayStyles.numberColor}`}>{i}</div>
                    <div className="absolute bottom-0 left-2">
                        <div className='flex gap-2'>
                            <div className='flex flex-row gap-1 '>
                                <FiSunrise className='mb-2'/>
                                <h6 className='mt-2 font-normal whitespace-nowrap' style={{fontSize:"8px"}}>{events[today] ? events[today][0].Sunrise : 'N/A'}</h6>
                            </div>
                            <div className='flex flex-row gap-1'>
                                <FiSunset className='mb-2'/>
                                <h6 className='mt-2 font-medium whitespace-nowrap' style={{fontSize:"8px"}}>{events[today] ? events[today][0].Sunset : 'N/A'}</h6>
                            </div>
                        </div>
                    </div>
                </Link>
            );
        }
        return days;
    };

    return (
        <div className="mx-auto mt-20 font-poppins" style={{ maxWidth: "1320px" }}>
            <div className='flex items-center justify-between mt-6'>
                <div className='flex flex-col'>
                    <h6 className='text-2xl font-bold'>
                        {t(selectedTranslation.labels.calendar)},<span className='font-normal'>{selectedTranslation.labels[monthName]}, {currentYear}</span>
                    </h6>
                    <div className='flex gap-2'>
                        <img src={Calmoon} alt="calmoon" className="w-14 h-14" />
                        <p>
                            {panchangDetails && panchangDetails['Vikram Samvat'] && (
                                panchangDetails['Paksha']
                            )}, {t(selectedTranslation.labels.purnima)}<br />
                            {panchangDetails && panchangDetails['Vikram Samvat'] && (
                                panchangDetails['Vikram Samvat']
                            )}, {t(selectedTranslation.labels.vikram)}
                        </p>
                    </div>
                </div>
                <div className='flex flex-col items-end gap-3'>
                    <span className='text-xl font-semibold'>
                        {moment().format("DD ")}
                        {t(selectedTranslation.labels[moment().format("MMMM")])},
                        {moment().format("YYYY")}
                        <span>
                            ({storedLanguage === "en"
                                ? moment().format("dddd")
                                : t(selectedTranslation.labels[moment().format("dddd").toLowerCase()])})
                        </span>
                    </span>
                    <div>
                        {panchangDetails && panchangDetails['festivals'] && panchangDetails['festivals'].length > 0 ? (
                            panchangDetails['festivals'].map((festival, index) => (
                                <span key={index} className='mr-1 text-xs font-medium text-gray-500 underline text'>
                                    {festival},
                                </span>
                            ))
                        ) : null}

                    </div>
                </div>
            </div>
            <div className='flex items-center justify-between mt-4'>
                <div>
                    <button className='p-2 rounded-lg bg-[#F6F6F6] flex items-center justify-center' onClick={previous}><FaChevronLeft /><span className='ml-1'>{t(selectedTranslation.labels.prev)}</span></button>
                </div>
                <div className=''>
                    <button className='p-2 rounded-lg bg-[#F6F6F6] flex items-center justify-center' onClick={next}>
                        <span className='mr-1'>{t(selectedTranslation.labels.next)}</span><FaChevronRight />
                    </button>
                </div>
            </div>
            {loading ? <div> Loading...  </div> : <Weekdays />}
            <div className="grid grid-cols-7 gap-4">
                {loading ? <div></div> : renderDays()}
            </div>
            <Notes panchangDetails={panchangDetails} />
            <Festivals />
        </div>
    );
};

export default Calendar;
