import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";

const Nivas = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='flex flex-col items-center justify-center mt-14'>
            <h3>{selectedTranslation.labels.detailsnivas}</h3>
            <div className='grid grid-cols-4 gap-24 p-10 mt-6 ml-6'>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Homahuti")}</span><br />
                        {details?.["Homahuti"]
                            ? details?.["Homahuti"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Agnivasa") || "None"}</span> <br />
                        {details?.["Agnivasa"]
                            ? details?.["Agnivasa"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}

                    </p>
                </div>

                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Bhadravasa") || "None"}</span><br />
                        {details?.["Bhadravasa"]
                            ? details?.["Bhadravasa"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Shivavasa")}</span>  <br />
                        {details?.["Shivavasa"]
                            ? details?.["Shivavasa"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Disha Shool") || "None"}</span>  <br />
                        {details?.["Disha Shool"]
                            ? details?.["Disha Shool"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Chandra Vasa") || "None"}</span>  <br />
                        {details?.["Chandra Vasa"]
                            ? details?.["Chandra Vasa"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Rahu Vasa") || "None"}</span>  <br />
                        {details?.["Rahu Vasa"]
                            ? details?.["Rahu Vasa"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>

                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Kumbha Chakra") || "None"}</span>  <br />
                        {details?.["Kumbha Chakra"]
                            ? details?.["Kumbha Chakra"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Nivas;