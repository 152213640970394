import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useKundliFetch = (url) => {
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [data, setData] = useState([]);
  const { i18n } = useTranslation();
  const data_language = i18n.language;
  const config = {
    headers: {
      Authorization:
        "Basic NjA1NjQ2OjU3NTAyNWYwNzc3MjUzZWI1YjhhZWNhNjlhYWMwN2Zm",
      "Accept-language": data_language,
    },
  };
  const { refetch, isLoading } = useQuery(
    [`data`, url, storedUserInfo, config],
    () =>
      axios.post(url, storedUserInfo, config).then((data) => {
        setData(data?.data);
        return data.data;
      })
  );

  return { data, refetch, isLoading };
};

export default useKundliFetch;
