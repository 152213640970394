import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";

const OtherCalendarsAndEpoch = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <div>
      <div
        className="text-center details-header"
        style={{ fontSize: "2.75vw" }}
      >
        {t("Other Calendars and Epoch")}
      </div>
      <div className="details-body panchang-gradient-3">
        {" "}
        <div className="row mx-auto" style={{ width: "88%" }}>
          {/* Julian Date */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">{t("Julian Date")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Julian Date"]
                  ? panchangDetails?.["Julian Date"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Kaliyuga */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Kaliyuga")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Kaliyuga"]
                  ? panchangDetails?.["Kaliyuga"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Kali Ahargana */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Kali Ahargana")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Kali Ahargana"]
                  ? panchangDetails?.["Kali Ahargana"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* National Civil Date */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("National Civil Date")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["National Civil Date"]
                  ? panchangDetails?.["National Civil Date"].map(
                      (data, index) => <span key={index}>{data}</span>
                    )
                  : "None"}
              </p>
            </div>
          </div>
          {/* Julian Day */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Julian Day")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Julian Day"]
                  ? panchangDetails?.["Julian Day"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Lahiri Ayanamsha */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Lahiri Ayanamsha")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Lahiri Ayanamsha"]
                  ? panchangDetails?.["Lahiri Ayanamsha"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Rata Die */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Rata Die")}</h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Rata Die"]
                  ? panchangDetails?.["Rata Die"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* National Nirayana Date */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("National Nirayana Date")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["National Nirayana Date"]
                  ? panchangDetails?.["National Nirayana Date"].map(
                      (data, index) => <span key={index}>{data}</span>
                    )
                  : "None"}
              </p>
            </div>
          </div>
          {/* Modified Julian Day */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Modified Julian Day")}
              </h6>
              <p className="d-flex flex-column  montserrat-font fw-semibold">
                {panchangDetails?.["Modified Julian Day"]
                  ? panchangDetails?.["Modified Julian Day"].map(
                      (data, index) => <span key={index}>{data}</span>
                    )
                  : "None"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherCalendarsAndEpoch;
