// rashis.js
import mesha from "../../img/zodiac/mesha.png";
import vrishabha from "../../img/zodiac/vrishabha.png";
import mithuna from "../../img/zodiac/mithuna.png";
import karka from "../../img/zodiac/karka.png";
import simha from "../../img/zodiac/simha.png";
import kanya from "../../img/zodiac/kanya.png";
import tula from "../../img/zodiac/tula.png";
import vrishchika from "../../img/zodiac/vrishchika.png";
import dhanu from "../../img/zodiac/dhanu.png";
import makara from "../../img/zodiac/makara.png";
import kumbha from "../../img/zodiac/kumbha.png";
import meena from "../../img/zodiac/meena.png";
const list = [
  { key:"mesha",title: "Aries", url: mesha },
  { key:"vrishabha",title: "Taurus", url: vrishabha },
  { key:"mithuna",title: "Gemini", url: mithuna },
  { key:"karka",title: "Cancer", url: karka },
  { key:"simha",title: "Leo", url: simha },
  { key:"kanya",title: "Virgo", url: kanya },
  { key:"tula",title: "Libra", url: tula },
  { key:"vrishchika",title: "Scorpio", url: vrishchika },
  { key:"dhanu",title: "Sagittarius", url: dhanu },
  { key:"makara",title: "Capricorn", url: makara },
  { key:"kumbha",title: "Aquarius", url: kumbha },
  { key:"meena",title: "Pisces", url: meena },
];
export default list;
