import { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFollowUser } from "../../store/actions/userActions";
import "./suggested_account.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LoginModal from "../Modal/Login/LoginModal";
import Helmetcomponent from "../Helmet/helmetcomponent";
function SuggestedAccount({ suggestedUsers }) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.userdetail);

  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);

  function handleFollow(id) {
    const data = {
      followingId: user?._id,
      followerId: id,
    };
    dispatch(getFollowUser(data));
  }
  const suggested_users =
    token !== null &&
    suggestedUsers?.length !== 0 &&
    suggestedUsers?.map((value, index) => {
      const followindex = value?.followers?.findIndex(
        (data) => data.userId === user?._id
      );

      return (
        <Card
          style={{
            width: "13rem",
            height: "15rem",
            marginRight: "5px",
            paddingRight: "5px",
            borderRight: "1px solid #ced4da",
            borderRadius: "unset",
          }}
          key={index}
        >
          <Card.Title className="text-center" key={index}>
            <div style={{ height: "100px" }}>
              <img
                src={
                  value?.imageURL && value?.imageURL !== undefined
                    ? value?.imageURL
                    : "/default/user.png"
                }
                className="rounded-circle text-center"
                alt="user_image"
                style={{
                  width: "110px",
                  height: "110px",
                  cursor: "pointer",
                  margin: "20px",
                }}
                onClick={() => {
                  navigate(`/userprofile/${value?._id}`);
                }}
              />
            </div>
          </Card.Title>
          <Card.Body className="text-center p-0">
            <div style={{ marginTop: "30px" }}>
              <div
                className="Name"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/userprofile/${value?._id}`);
                }}
              >
                <span>{value?.firstName}</span>
                <span className="ms-2">{value?.lastName}</span>
              </div>
            </div>
            <div className="align-middle">
              <Button
                size="sm"
                className="follow-s"
                onClick={() =>
                  !token ? setShowLogin(true) : handleFollow(value?._id)
                }
              >
                {followindex === -1 ||
                followindex === undefined ||
                value?.followers?.length === undefined ||
                value?.followers?.length === 0
                  ? "Follow"
                  : "Unfollow"}
              </Button>
            </div>
          </Card.Body>
        </Card>
      );
    });

  return (
    <>
      <div className="suggested">
      <Helmetcomponent index={"Suggested"} path={""} data={""}/>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerclassName="container-with-dots"
          dotListclassName=""
          draggable
          focusOnSelect={false}
          infinite={true}
          itemclassName=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 5,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 3,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          showDots={false}
          sliderclassName=""
          slidesToSlide={1}
          swipeable
          autoPlay={true}
        >
          {suggested_users && suggested_users}
        </Carousel>
      </div>
      <LoginModal
        show={showLogin}
        handleCloseLogin={() => handleCloseLogin()}
      />
    </>
  );
}

export default SuggestedAccount;
