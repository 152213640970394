import React from "react";
import { useTranslation } from "react-i18next";
import useKundliFetch from "../../../Hooks/useKundliFetch";

const SadhesatiLifeDetails = () => {
  const { t } = useTranslation();
  const url = "https://json.astrologyapi.com/v1/sadhesati_life_details";
  const { data: sadhesatiLifeDetails } = useKundliFetch(url);

  return (
    <>
      {sadhesatiLifeDetails.length !== 0 &&
        sadhesatiLifeDetails?.map((sadhesatiLifeDetail, index) => (
          <div key={index} className="shadow-lg">
            <div className="text-center details-header">
              {t("Sadhesati Life Details")} : {index + 1}
            </div>
            <ul
              className={`list-group list-group-flush details-body kundli-gradient-${
                index < 5
                  ? index + 2
                  : index >= 5 && index < 10
                  ? index - 4
                  : index >= 10 && index < 15
                  ? index - 9
                  : index >= 15 && index < 20
                  ? index - 14
                  : index >= 20 && index < 25
                  ? index - 19
                  : index >= 25 && index < 30
                  ? index - 24
                  : index - 30
              }`}
            >
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Date")} :{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiLifeDetail?.date}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Type")} :{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiLifeDetail?.type}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Millisecond")} :{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiLifeDetail?.millisecond}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Moon Sign")} :{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiLifeDetail?.moon_sign}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Saturn Sign")} :{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiLifeDetail?.saturn_sign}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Is Saturn Retrograde?")} :{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiLifeDetail?.is_saturn_retrograde === true
                    ? "Yes"
                    : "No"}
                </span>
              </li>
              <li className="list-group-item bg-transparent">
                <span className="montserrat-font text-white">
                  {t("Summary")} :{" "}
                </span>
                <span className="montserrat-font fw-semibold">
                  {sadhesatiLifeDetail?.summary}
                </span>
              </li>
            </ul>
          </div>
        ))}
    </>
  );
};

export default SadhesatiLifeDetails;
