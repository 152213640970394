import React, { useContext } from "react";
import { KundliContext } from "../Kundli";
import BasicDetails from "../BasicDetails";
import KundliInput from "../KundliInput";
import useKundliFetch from "../../../Hooks/useKundliFetch";
import { useTranslation } from "react-i18next";

const AscendantResult = () => {
  const { userInfo } = useContext(KundliContext);
  const { t } = useTranslation();
  const url = `https://json.astrologyapi.com/v1/general_ascendant_report`;
  const { data } = useKundliFetch(url);

  let ascendantDetails;
  if (data.length !== 0) {
    ascendantDetails = data?.asc_report;
  }

  return (
    <>
      <KundliInput />
      {userInfo && Object.keys(userInfo).length !== 0 && <BasicDetails />}
      {ascendantDetails && (
        <>
          <div className="text-center details-header">
            {t("ascendant")} {t("Report")}
          </div>
          <div className="details-body kundli-gradient-2">
            <div className="montserrat-font fw-bolder">
              {ascendantDetails?.ascendant}
            </div>
            <p className="montserrat-font text-white">
              {ascendantDetails?.report}
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default AscendantResult;
