import React from "react";
import moment from "moment";
import useHoroscopeFetch from "../../Hooks/useHoroscopeFetch";
import HoroscopeDetails from "./HoroscopeDetails";
import HoroscopeButtons from "./HoroscopeButtons";
import { useTranslation } from "react-i18next";

const Weekly = () => {
  const today = new Date();
  const firstDay = moment(
    new Date(today.setDate(today.getDate() - today.getDay()))
  ).format("MMM Do");
  const lastDay = moment(
    new Date(today.setDate(today.getDate() - today.getDay() + 6))
  ).format("MMM Do");
  var oneJan = new Date(today.getFullYear(), 0, 1);
  var numberOfDays = Math.floor((today - oneJan) / (24 * 60 * 60 * 1000));
  var numberOfWeek = Math.ceil((today.getDay() + 1 + numberOfDays) / 7);
  const { t } = useTranslation();
  const { data, refetch } = useHoroscopeFetch(numberOfWeek);

  const handleRashi = (rashi) => {
    localStorage.setItem("rashi", rashi);
    refetch();
  };

  return (
    <div>
      <HoroscopeButtons handleRashi={handleRashi} />

      <h5 className="montserrat-font mt-3 mt-lg-5">
        {t("Weekly Horoscope")} : {firstDay} to {lastDay}
      </h5>
      {data && <HoroscopeDetails data={data} />}
    </div>
  );
};

export default Weekly;
