import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { KundliContext } from "./Kundli";
import "./Kundli.scss";
const BasicDetails = () => {
  const { t } = useTranslation();
  const { userInfo } = useContext(KundliContext);

  return (
    <div className="pt-3 pt-lg-5">
      <div className="text-center details-header">{t("Basic Details")}</div>
      <div className="details-body kundli-gradient-1 d-flex justify-content-evenly gap-2 flex-wrap">
        <div className="d-flex flex-column">
          <span className="montserrat-font text-white">{t("Name")}</span>
          <span className="montserrat-font fw-semibold">{userInfo?.name}</span>
        </div>
        <div className="d-flex flex-column text-center">
          <span className="montserrat-font text-white">{t("Gender")}</span>
          <span className="montserrat-font fw-semibold">
            {userInfo?.gender}
          </span>
        </div>
        <div className="d-flex flex-column text-center">
          <span className="montserrat-font text-white">
            {t("Date of Birth")}
          </span>
          <span className="montserrat-font fw-semibold">
            {userInfo?.day < 10 ? (
              <>0{userInfo?.day}/</>
            ) : (
              <>{userInfo?.day}/</>
            )}
            {userInfo?.month < 10 ? (
              <>0{userInfo?.month}/</>
            ) : (
              <>{userInfo?.month}/</>
            )}
            <>{userInfo?.year}</>
          </span>
        </div>
        <div className="d-flex flex-column text-center">
          <span className="montserrat-font text-white">
            {t("Time of Birth")}
          </span>
          <span className="montserrat-font fw-semibold">
            {userInfo?.hour === 0 ? (
              <>{userInfo?.hour + 12}</>
            ) : userInfo?.hour > 0 && userInfo?.hour < 10 ? (
              <>0{userInfo?.hour}</>
            ) : userInfo?.hour > 12 ? (
              <>{userInfo?.hour - 12}</>
            ) : (
              <>{userInfo?.hour}</>
            )}
            :{userInfo?.min < 10 ? <>0{userInfo?.min}</> : <>{userInfo?.min}</>}
            <> {userInfo.clockTime}</>
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="montserrat-font text-white">{t("Birth Place")}</span>
          <span className="montserrat-font fw-semibold">{userInfo?.place}</span>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
