import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GoVerified } from "react-icons/go";
import { MdVerifiedUser } from "react-icons/md";
import altProfile from "../../img/altprofile.jpg";

export default function ProfileDropdown({ handleLogout }) {
  const userData = useSelector((state) => state?.user);
  const { t, i18n } = useTranslation();
  console.log(userData?.userdetail?.imageURL);
  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-autoclose-true"
        className="dropdown-profile"
        variant="none"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={(el) => {
          if (el && userData?.userdetail?.imageURL) {
            el.style.setProperty("border", "none", "important");
          }
        }}
      >
        {userData?.userdetail?.imageURL ? (
          <>
            <span>
              <img
                alt=""
                src={userData?.userdetail?.imageURL}
                className="border-none rounded-circle text-decoration-none"
                width="44px"
                height="44px"
              />
            </span>
            {/* {userData?.userdetail?.isVerified === true && (
          <span>
            <MdVerifiedUser
              style={{
                color: "green",
                height: "30px",
                width: "30px",
                position: "relative",
                top: "10px",
                right: "28px",
              }}
            />
          </span>
        )} */}
          </>
        ) : (
          <img
            alt="profile-display"
            src={altProfile}
            style={{ width: "30px", height: "30px", borderRadius: "80%" }}
          />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ left: "auto", right: 0 }}>
        <Link className="dropdown-item" to="/e/manage/past">
          {t("Manage Events")}
        </Link>
        <Link className="dropdown-item" to="/profile">
          {t("Manage Profile")}
        </Link>
        <Link className="dropdown-item" to={`/userprofile/${userData?.userId}`}>
          {t("View Profile")}
        </Link>
        <Link className="dropdown-item" to="/" onClick={() => handleLogout()}>
          {t("Logout")}
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
}
