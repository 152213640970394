import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";

const Ritu = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='flex flex-col items-center justify-center mt-14'>
            <h3>{selectedTranslation.labels.detailsritu}</h3>
            <div className='grid grid-cols-4 gap-24 p-10 mt-6 ml-6'>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{selectedTranslation.labels.detailsdrik}</span><br /> {details?.["Drik Ritu"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{selectedTranslation.labels.detailsayana}</span> <br />{details?.["Drik Ayana"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Dinamana")} </span><br />{details?.Dinamana}</p>
                </div>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Madhyahna")} </span>  <br />{details?.Madhyahna}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{selectedTranslation.labels.detailsvedic}</span><br /> {details?.["Vedic Ritu"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{selectedTranslation.labels.detailsveda}</span> <br />{details?.["Vedic Ayana"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Ratrimana")} </span><br />{details?.Ratrimana}</p>
                </div>
            </div>
        </div>
    )
}

export default Ritu;