import httpService from "../../common/http.service";
import { eventsAction } from "../slice/eventsSlice";

export const getPastEvents = (data) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `eventparticipant/?userId=${data.userId}&filter=${data.filter}`
    );
    if (response && response?.data) {
      dispatch(eventsAction.PastEventsSuccess(response?.data?.data?.data));
    }
  } catch (err) {
    dispatch(eventsAction.PastEventsFailed(err));
  }
};

export const getCurrentEvents = (data) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `eventparticipant/?userId=${data.userId}&filter=${data.filter}`
    );
    if (response && response?.data) {
      dispatch(eventsAction.CurrentEventsSuccess(response?.data?.data?.data));
    }
  } catch (err) {
    dispatch(eventsAction.CurrentEventsFailed(err));
  }
};

export const getFutureEvents = (data) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `eventparticipant/?userId=${data.userId}&filter=${data.filter}`
    );
    if (response && response?.data) {
      dispatch(eventsAction.FutureEventsSuccess(response?.data?.data?.data));
    }
  } catch (err) {
    dispatch(eventsAction.FutureEventsFailed(err));
  }
};
