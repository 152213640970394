export const normalDayStyles = {
    position: 'relative',
    padding: '1rem',
    border: '1px solid #ccc',
    borderRadius: '8px',
    height: '150px',
    width: '169px',
    backgroundColor: '#fff',
    color: '#000',
    numberBackground: 'bg-[#E8A74E1A]',
    numberColor: 'text-black',
    innernumberColor: 'text-black',
    customBorderColor: '#CB9D06',
};

export const currentEkadashiStyle = {
    ...normalDayStyles,
    backgroundColor: '#000',                 // grid background color
    color: '#fff',                           // grid text color
    numberBackground: 'bg-[#2c2c2c]',        // inner number background color   
    numberColor: 'text-[#FFFDC6]',           // grid number color
    innernumberColor: 'text-white',          // inner number color
    customBorderColor:'#808080',             // inner number border color    
};

export const currentPournimaStyles = { 
    ...normalDayStyles,
    backgroundColor: '#000',
    color: '#fff',
    numberBackground: 'bg-[#2c2c2c]',
    numberColor: 'text-[#FFE2F9]',
    innernumberColor: 'text-white',
    customBorderColor:'#808080',
};

export const currentAmavasyaStyles = {
    ...normalDayStyles,
    backgroundColor: '#000',
    color: '#fff',
    numberBackground: 'bg-[#2c2c2c]',
    numberColor: 'text-[#DFFBFF]',
    innernumberColor: 'text-white',
    customBorderColor:'#808080',
};

export const currentSundayStyles = {
    ...normalDayStyles,
    backgroundColor: '#000',
    color: '#fff',
    numberBackground: 'bg-[#2c2c2c]',
    numberColor: 'text-[#FF695F]',
    innernumberColor: 'text-white',
    customBorderColor:'#808080',
};

export const currentDayStyles = {
    ...normalDayStyles,
    backgroundColor: '#000',
    color: '#fff',
    numberBackground: 'bg-[#2c2c2c]',
    numberColor: 'text-white',
    innernumberColor: 'text-white',
    customBorderColor:'#808080',
};

export const sundayholidayStyles = {
    ...normalDayStyles,
    backgroundColor: '#FFF0EF',
    color: '#000',
    numberBackground: 'bg-[#FF695F]',
    borderColor: '#FF695F',
    innernumberColor: 'text-white',
    numberColor: 'text-[#FF695F]',
    customBorderColor:'#FF695F',
};

export const EkadashiStyles = {
    ...normalDayStyles,
    backgroundColor: '#FFFDC6',          
    color: '#000',                      
    numberBackground: 'bg-[#F2D32D]',  
    borderColor: '#F2D32D',          
    innernumberColor: 'text-white',  
    numberColor: 'text-black',     
};

export const AmavasyaStyles = {
    ...normalDayStyles,  
    backgroundColor: '#DFFBFF',           // grid background color
    color: '#000',                        // grid text color
    numberBackground: 'bg-[#75CBD7]',     // inner number background color
    borderColor: '#75CBD7',               // grid border color
    innernumberColor: 'text-white',       // inner number color
    numberColor: 'text-black',            // grid number color
    customBorderColor:'#75CBD7',          // inner number border color
};

export const PurnimaStyles = {
    ...normalDayStyles,
    backgroundColor: '#FFE2F9',
    color: '#000',
    numberBackground: 'bg-[#E33ABE]',
    borderColor: '#E33ABE',
    innernumberColor: 'text-white',
    numberColor: 'text-black',
    customBorderColor:'#E33ABE',
};

export const EkadashiholidayStyles = {
    ...normalDayStyles,
    backgroundColor: '#FFFDC6',
    color: '#000',
    numberBackground: 'bg-[#F2D32D]',
    borderColor: '#F2D32D',
    innernumberColor: 'text-white',
    numberColor: 'text-[#FF695F]',
    customBorderColor:'#F2D32D',
};

export const EkadashisundayStyles = {
    ...normalDayStyles,
    backgroundColor: '#FFFDC6',
    color: '#000',
    numberBackground: 'bg-[#F2D32D]',
    borderColor: '#F2D32D',
    innernumberColor: 'text-white',
    numberColor: 'text-[#FF695F]',
    customBorderColor:'#F2D32D',
};

export const PournimaholidayStyles = {
    ...normalDayStyles,
    backgroundColor: '#FFE2F9',
    color: '#000',
    numberBackground: 'bg-[#E33ABE]',
    borderColor: '#E33ABE',
    innernumberColor: 'text-white',
    numberColor: 'text-[#FF695F]',
    customBorderColor:'#E33ABE',
};

export const PournimasundayStyles = {
    ...normalDayStyles,
    backgroundColor: '#FFE2F9',
    color: '#000',
    numberBackground: 'bg-[#E33ABE]',
    borderColor: '#E33ABE',
    innernumberColor: 'text-white',
    numberColor: 'text-[#FF695F]',
    customBorderColor:'#E33ABE',
};

export const AmavasyaholidayStyles = {
    ...normalDayStyles,
    backgroundColor: '#DFFBFF',
    color: '#000',
    numberBackground: 'bg-[#75CBD7]',
    borderColor: '#75CBD7',
    innernumberColor: 'text-white',
    numberColor: 'text-[#FF695F]',
    customBorderColor:'#75CBD7',
};

export const AmavasyasundayStyles = {
    ...normalDayStyles,
    backgroundColor: '#DFFBFF',
    color: '#000',
    numberBackground: 'bg-[#75CBD7]',
    borderColor: '#75CBD7',
    innernumberColor: 'text-white',
    numberColor: 'text-[#FF695F]',
    customBorderColor:'#75CBD7',
};

export const getDayStyles = (isCurrentDate, isEkadashi, isPurnima, isAmavasya, isSunday, isHoliday) => {
    let dayStyles = normalDayStyles;

    if (isCurrentDate && isEkadashi) {
        dayStyles = currentEkadashiStyle;
    }
    else if (isCurrentDate && isPurnima) {
        dayStyles = currentPournimaStyles;
    } 
    else if (isCurrentDate && isAmavasya) {
        dayStyles = currentAmavasyaStyles;
    } 
    else if (isCurrentDate && isSunday) {
        dayStyles = currentSundayStyles;
    }
    else if (isCurrentDate && isHoliday) {
        dayStyles = currentSundayStyles;
    }
     else if (isEkadashi && isHoliday) {
        dayStyles = EkadashiholidayStyles;
    } else if (isEkadashi && isSunday) {
        dayStyles = EkadashisundayStyles;
    } else if (isPurnima && isHoliday) {
        dayStyles = PournimaholidayStyles;
    } else if (isPurnima && isSunday) {
        dayStyles = PournimasundayStyles;
    } else if (isAmavasya && isHoliday) {
        dayStyles = AmavasyaholidayStyles;
    } else if (isAmavasya && isSunday) {
        dayStyles = AmavasyasundayStyles;
    } else if (isEkadashi) {
        dayStyles = EkadashiStyles;
    } else if (isPurnima) {
        dayStyles = PurnimaStyles;
    } else if (isAmavasya) {
        dayStyles = AmavasyaStyles;
    } else if (isSunday || isHoliday) {
        dayStyles = sundayholidayStyles;
    }
    else if (isCurrentDate) {
        dayStyles = currentDayStyles;
    }
    return dayStyles;
}