import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";

const Inauspicious = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='flex flex-col items-center justify-center mt-14'>
            <h3>{selectedTranslation.labels.detailstimmings}</h3>
            <div className='grid grid-cols-4 gap-24 p-10 mt-6 ml-6'>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{selectedTranslation.labels.detailsadal}</span><br />
                        {details?.["Aadal Yoga"]
                            ? details?.["Aadal Yoga"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Gulikai Kalam")}</span> <br />{details?.["Gulikai Kalam"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Dur Muhurtam")} </span><br />{details?.["Dur Muhurtam"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start'>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Ganda Moola")}</span>  <br />
                        {details?.["Ganda Moola"]
                            ? details?.["Ganda Moola"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Bhadra")}</span><br />
                        {details?.["Bhadra"]
                            ? details?.["Bhadra"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Rahu Kalam")}</span> <br />{details?.["Rahu Kalam"] || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Yamaganda")} </span><br />{details?.Yamaganda || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Varjyam")}</span><br /> {details?.Varjyam || "None"}</p>
                </div>
                <div className='flex items-center justify-start '>
                    <p className='mt-2 ml-2'><span className='text-lg font-semibold'>{t("Baana")}</span><br />
                        {details?.["Baana"]
                            ? details?.["Baana"].map((data, index) => (
                                <span key={index}>{data}</span>
                            ))
                            : "None"}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Inauspicious;