import { useFormik } from "formik";
import React, { createContext, useState } from "react";
import MatchMakingBasicDetails from "./MatchMakingBasicDetails";
import MatchMakingButtons from "./MatchMakingButtons";
import MatchMakingInput from "./MatchMakingInput";

export const MatchMakingContext = createContext({});

const validate = (values) => {
  const submitButton = document.getElementById("submit-button");
  const errors = {};
  if (!values.m_name) {
    errors.m_name = "Required";
    submitButton.setAttribute("disabled", true);
  } else if (!values.f_name) {
    errors.f_name = "Required";
    submitButton.setAttribute("disabled", true);
  } else if (values.m_name.length < 3) {
    errors.m_name = "Must be 3 characters or more";
    submitButton.setAttribute("disabled", true);
  } else if (values.f_name.length < 3) {
    errors.f_name = "Must be 3 characters or more";
    submitButton.setAttribute("disabled", true);
  } else {
    submitButton.removeAttribute("disabled");
  }
  if (!values.m_place) {
    errors.m_place = "Required";
    submitButton.setAttribute("disabled", true);
  } else if (!values.f_place) {
    errors.f_place = "Required";
    submitButton.setAttribute("disabled", true);
  } else if (values.m_place.length < 5) {
    errors.m_place = "Must be 5 characters or More";
    submitButton.setAttribute("disabled", true);
  } else if (values.f_place.length < 5) {
    errors.f_place = "Must be 5 characters or More";
    submitButton.setAttribute("disabled", true);
  } else {
    submitButton.removeAttribute("disabled");
  }

  return errors;
};
const MatchMaking = () => {
  const submitButton = document.getElementById("submit-button");
  const [femaleLocationData, setMaleLocationData] = useState({});
  const [maleLocationData, setFemaleLocationData] = useState({});

  const matchMakingInfo = JSON.parse(localStorage.getItem("matchMakingInfo"));

  const formik = useFormik({
    initialValues: {
      m_name: "",
      m_day: new Date().getDate(),
      m_month: new Date().getMonth() + 1,
      m_year: new Date().getFullYear(),
      m_hour:
        new Date().getHours() > 12
          ? new Date().getHours() - 12
          : new Date().getHours(),
      m_min: new Date().getMinutes(),
      m_clockTime: new Date().getHours() >= 12 ? "PM" : "AM",
      m_place: "",
      f_name: "",
      f_day: new Date().getDate(),
      f_month: new Date().getMonth() + 1,
      f_year: new Date().getFullYear(),
      f_hour:
        new Date().getHours() > 12
          ? new Date().getHours() - 12
          : new Date().getHours(),
      f_min: new Date().getMinutes(),
      f_clockTime: new Date().getHours() >= 12 ? "PM" : "AM",
      f_place: "",
    },
    validate,
    onSubmit: (values) => {
      values.m_lat = maleLocationData?.geometry?.location?.lat();
      values.m_lon = maleLocationData?.geometry?.location?.lng();
      values.f_lat = femaleLocationData?.geometry?.location?.lat();
      values.f_lon = femaleLocationData?.geometry?.location?.lng();
      let m_Hour = parseInt(values.m_hour);
      if (values.m_clockTime === "PM") {
        if (m_Hour < 12) {
          m_Hour = m_Hour + 12;
        }
      } else if (values.m_clockTime === "AM") {
        if (m_Hour === 12) {
          m_Hour = 0;
        } else if (m_Hour > 12) {
          m_Hour = m_Hour - 12;
        }
      }

      let f_Hour = parseInt(values.f_hour);
      if (values.f_clockTime === "PM") {
        if (f_Hour < 12) {
          f_Hour = f_Hour + 12;
        }
      } else if (values.f_clockTime === "AM") {
        if (f_Hour === 12) {
          f_Hour = 0;
        } else if (f_Hour > 12) {
          f_Hour = f_Hour - 12;
        }
      }
      values.m_day = parseInt(values.m_day);
      values.m_month = parseInt(values.m_month);
      values.m_year = parseInt(values.m_year);
      values.m_hour = m_Hour;
      values.m_min = parseInt(values.m_min);
      values.m_tzone = 5.5;
      values.f_day = parseInt(values.f_day);
      values.f_month = parseInt(values.f_month);
      values.f_year = parseInt(values.f_year);
      values.f_hour = f_Hour;
      values.f_min = parseInt(values.f_min);
      values.f_tzone = 5.5;
      console.log(values.f_hour);
      localStorage.setItem("matchMakingInfo", JSON.stringify(values));
      formik.resetForm();
      submitButton.setAttribute("disabled", true);
    },
  });

  const matchMakingContextValues = {
    formik,
    setMaleLocationData,
    setFemaleLocationData,
    matchMakingInfo,
  };
  return (
    <MatchMakingContext.Provider value={matchMakingContextValues}>
      <div className="pt-3 pt-lg-5 pb-2 pb-lg-4">
        <MatchMakingInput />
        {matchMakingInfo && Object.keys(matchMakingInfo).length !== 0 && (
          <>
            <MatchMakingBasicDetails />
            <MatchMakingButtons />
          </>
        )}
      </div>
    </MatchMakingContext.Provider>
  );
};

export default MatchMaking;
