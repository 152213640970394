import React from "react";
import { useTranslation } from "react-i18next";
import useHoroscopeFetch from "../../Hooks/useHoroscopeFetch";
import HoroscopeDetails from "./HoroscopeDetails";
import HoroscopeButtons from "./HoroscopeButtons";

const Yearly = () => {
  const year = new Date().getFullYear();
  const { t } = useTranslation();
  const { data, refetch } = useHoroscopeFetch(year);

  const handleRashi = (rashi) => {
    localStorage.setItem("rashi", rashi);
    refetch();
  };

  return (
    <div>
      <HoroscopeButtons handleRashi={handleRashi} />

      <h5 className="montserrat-font mt-3 mt-lg-5">
        {t("Yearly Horoscope")} : {year}
      </h5>
      {data && <HoroscopeDetails data={data} />}
    </div>
  );
};

export default Yearly;
