import React from 'react';
import Phone from "../../img/download/phone.png";
import Play from "../../img/icons/play.svg";
import Apple from "../../img/icons/apple.svg";
const Download = () => {
    const handleplay = () => {
        window.open('https://play.google.com/store/apps/details?id=com.india.hindicalender&hl=en','_blank');
    };

    const handleapple = () => {
        window.open('https://apps.apple.com/pl/app/2024-calendar/id1440257161','_blank');
    };
    return (
        <div>
            <div className="flex flex-col items-center justify-center p-6 text-sm bg-white md:hidden font-poppins" style={{ height: "645px" }}>
            <div className="flex">
                    <img src={Phone} alt="App Screenshot" className="transform rounded-lg rotate-4" style={{ height: "350px", width: "350px" }} />
                </div>
                <div className="mb-6">
                    <div className='mb-4 text-left'>
                        <h1 className="mb-2 text-xl font-bold text-black">Available for iOS <br /> and Android.</h1>
                        <p class="max-w-md  font-normal text-black">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.
                        </p>
                    </div>
                    <div className='flex justify-start mb-6 space-x-4'>
                        <button onClick={handleplay} className="p-1 border-gray-400 rounded-lg border-1" style={{ height: "54px", width: "175px" }}>
                            <div className='flex items-center justify-center gap-3'>
                                <div>
                                    <img src={Play} alt="Play Store" style={{ height: "32px", width: "26px" }} />
                                </div>
                                <div className='flex-col text-left font-inter'>
                                    <span className='text-sm font-bold'>GET IT ON<br /></span>
                                    <span className='text-lg font-bold'>Google Play</span>
                                </div>
                            </div>
                        </button>
                        <button onClick={handleapple} className="p-1 border-gray-400 rounded-lg border-1" style={{ height: "54px", width: "175px" }}>
                            <div className='flex items-center justify-center gap-3'>
                                <div>
                                    <img src={Apple} alt="Play Store" style={{ height: "32px", width: "26px" }} />
                                </div>
                                <div className='flex-col text-left font-inter'>
                                    <span className='text-sm font-bold' >Download on the<br /></span>
                                    <span className='text-lg font-bold'>App Store</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            
            <div className="flex-row items-center justify-center hidden p-6 text-sm bg-white md:flex gap-60 font-poppins" style={{ height: "645px" }}>
                <div className="mb-6">
                    <div className='mb-4 text-left'>
                        <h1 className="mb-2 text-3xl font-bold text-black">Available for iOS <br /> and Android.</h1>
                        <p class="max-w-md  font-normal text-black">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.
                        </p>
                    </div>
                    <div className='flex justify-start mb-6 space-x-4'>
                        <button onClick={handleplay} className="p-1 border-gray-400 rounded-lg border-1" style={{ height: "54px", width: "175px" }}>
                            <div className='flex items-center justify-center gap-3'>
                                <div>
                                    <img src={Play} alt="Play Store" style={{ height: "32px", width: "26px" }} />
                                </div>
                                <div className='flex-col text-left font-inter'>
                                    <span className='text-sm font-bold'>GET IT ON<br /></span>
                                    <span className='text-lg font-bold'>Google Play</span>
                                </div>
                            </div>
                        </button>
                        <button onClick={handleapple} className="p-1 border-gray-400 rounded-lg border-1" style={{ height: "54px", width: "175px" }}>
                            <div className='flex items-center justify-center gap-3'>
                                <div>
                                    <img src={Apple} alt="Play Store" style={{ height: "32px", width: "26px" }} />
                                </div>
                                <div className='flex-col text-left font-inter'>
                                    <span className='text-sm font-bold' >Download on the<br /></span>
                                    <span className='text-lg font-bold'>App Store</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="flex">
                    <img src={Phone} alt="App Screenshot" className="transform rounded-lg rotate-4" style={{ height: "612px", width: "612px" }} />
                </div>
            </div>
        </div>
    );
}

export default Download;
