import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

export const allHolidaysContext = createContext();

function HolidaysContext({ children }) {
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { i18n } = useTranslation();
  const data_language = i18n.language.toUpperCase();
  const url = `https://api.exaweb.in:3004/api/panchang/holiday?app_language=EN&data_language=${data_language}&year=2025`;

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const response = await axios.get(url);
        //console.log(response.data);
        setHolidays(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchHolidays();
  }, [data_language]);
  return (
    <allHolidaysContext.Provider value={{ holidays, loading, error }}>
      {children}
    </allHolidaysContext.Provider>
  );
}

export default HolidaysContext;
