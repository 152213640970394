import React, { useContext, useEffect, useState } from "react";
import { allShopItemsContext } from "../BharatWebShopContext/ShopTodaysDeals";
//import { Link } from "react-router-dom";
//import Loader from "../../img/Spinner-Loader-shop.gif";
//import "./AllItemsView.scss";
import Helmetcomponent from "../Helmet/helmetcomponent";

function AllTodayDeals() {
  const { loading, error, fetchTodayDealsData } =
    useContext(allShopItemsContext);
  const [shopData, setShopData] = useState([]);

  useEffect(() => {
    const fetchTodayDeals = async () => {
      try {
        const todayDeals = await fetchTodayDealsData();
        setShopData(todayDeals);
      } catch (error) {
        console.error("Error fetching all today's deals:", error);
      }
    };

    fetchTodayDeals();
  }, [fetchTodayDealsData]);

  if (loading) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return (
      <div>
        {/* <img
          src={Loader}
          alt="Loading Today's Deals..."
          style={{ width: "100%", height: "400px", borderRadius: "100%" }}
        /> */}
        <h3 className="text-center text-warning">
          ...Loading Today's Deals...
        </h3>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const getImageUrl = (imagePath) => {
    const baseUrl = "https://api.exaweb.in:5200";
    const normalizedPath = imagePath.replace(/^(\.\.\/)+/, "");
    return `${baseUrl}/${normalizedPath}`;
  };

  const handleItemClick = (itemId) => {
    window.location.href = `https://shop.bharatcalendars.in/categoryItems/${itemId}`;
  };

  console.log("shopData", shopData);

  return (
    <>
      <div className="mb-5">
        <Helmetcomponent index={"TodaysDeals"} path={""} data={""}/>
        <div>
          <h1
            style={{
              fontSize: "35px",
              fontFamily: "montserrat-font",
              fontWeight: "900",
              textAlign: "center",
              color: "black",
              marginTop: "19px",
            }}
          >
            Today's Deals
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {shopData.map((item, index) => (
            <div
              className="p-3 bg-white rounded shadow card"
              key={item._id}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                maxWidth: "200px",
                margin: "15px",
                height: "300px",
                padding: "0px",
                cursor: "pointer",
                boxSizing: "border-box",
              }}
              onClick={() => handleItemClick(item._id)}
            >
              <div className="card-img-top">
                <img
                  src={getImageUrl(item.imageGallery[0])}
                  alt={`Item ${index + 1}`}
                  style={{
                    maxWidth: "100%",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "7px",
                    margin: "5px auto",
                    display: "block",
                  }}
                />
              </div>
              <div
                className="text-dark"
                style={{ padding: '0.5rem 0rem' }}
              >
                <p>
                  {item.itemName}
                </p>
              </div>
              <div className="text-center card-footer fw-bolder" style={{backgroundColor: "green",color:'white'}}>
                <p style={{ margin: "0" }}>
                  ₹{item.price}{" "}
                  <span className="text-decoration-line-through" style={{color:'lightgray'}}>
                    ₹{item.discPrice}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default AllTodayDeals;
