import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginModal from "../Components/Modal/Login/LoginModal";
import { showSearch } from "../store/actions/eventActions";
import { getFollowUser } from "../store/actions/userActions";
import "./search.scss";

function Search({ setSearch, searchBy,setShowInput }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchResult = useSelector(showSearch);
  const searchUser = useSelector((state) => state?.SearchUser?.searchUser);
  const userData = useSelector((state) => state?.user);
  const token = localStorage.getItem("token");
  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);

  function handleFollow(followerId) {
    const data = {
      followingId: userData?.userId,
      followerId: followerId,
    };
    dispatch(getFollowUser(data));
  }
  //console.log(searchUser);
  return (
    <>
      <div className="text-xs md:hidden font-poppins">
        <div className="p-4 mt-3 overlays">
          <div className="d-flex align-items-center justify-content-between search-header">
            <span>{searchBy}</span>
            <div onClick={setSearch} style={{ cursor: "pointer" }}>
              <MdOutlineClose />
            </div>
          </div>
          <div>
            {searchResult.length !== 0 && searchBy === t("Events") ? (
              <div className="row">
                {searchResult?.map((value, index) => {
                  return (
                    <div
                      className="p-0 mb-1 d-flex col-md-12 col-12 ms-1"
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/event/${value?._id}`);
                        setSearch();
                      }}
                    >
                      <div className="p-0 m-0 col-md-3 col-sm-3">
                        <div style={{ width: "90%", border: "none" }}>
                          {/* {console.log(value?.imageURL)} */}
                          {/* {console.log(value.attachments[0].fileurl)} */}
                          <img
                            src={
                              value?.imageURL
                                ? value?.imageURL
                                : "/img/1553336600.png"
                              //value.attachments[0].fileurl
                            }
                            alt=""
                            style={{
                              cursor: "pointer",
                              width: "80px",
                              borderRadius: "10px",
                              height: "80px",
                              marginTop: "5px",
                              marginLeft: "10px",
                              marginRight: "10px"
                            }}
                          />
                        </div>
                      </div>
                      <div className="ml-4 col-md-9 col-sm-9">
                        <div className="mb-4 border-0">
                          <div className="col-12 w-100">
                            <h5
                              className="card-title"
                              style={{ fontSize: "14px" }}
                            >
                              <span className="me-1">
                                {moment(value?.startDate).format(
                                  "ddd, MMM DD, YYYY"
                                )}
                              </span>
                              AT
                              <br />
                              <div className="pt-1">
                                {moment(value?.startDate).format("hh:mm A")}
                              </div>
                            </h5>
                            <div
                              className="card-text content-link"
                              style={{ fontSize: "14px" }}
                            >
                              {value?.title}
                            </div>
                            <div
                              className="pt-1 card-text"
                              style={{ fontSize: "14px" }}
                            >
                              {value?.address}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : searchBy === t("Events") ? (
              <div className="no_result">
                No result found! Try with different keywords!
              </div>
            ) : (
              ""
            )}
            {searchUser?.length !== 0 && searchBy === t("Users") ? (
              <div className="row">
                {searchUser?.map((value, index) => {
                  const followIndex =
                    value?.followers !== undefined &&
                    value?.followers?.findIndex(
                      (data) => data.userId === userData?.userId
                    );
                  return (
                    <div className="p-0 m-0 mb-3 col-xs-12" key={index}>
                      <div className="col-4">
                        <div
                          className="text-center card"
                          style={{ width: "100%", border: "none" }}
                        >
                          <div className="p-0 card-body">
                            <img
                              src={
                                value?.imageURL
                                  ? value?.imageURL
                                  : "/default/user.png"
                              }
                              alt="user_img"
                              style={{
                                width: "80px",
                                height: "80px",
                                cursor: "pointer",
                                borderRadius: "10px",
                              }}
                              onClick={() => {
                                navigate(`/userprofile/${value?._id}`);
                                setSearch();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="border-0 card">
                          <div className="p-0 card-body">
                            <h5
                              className="card-title"
                              style={{ fontSize: "14px" }}
                            >
                              <div className="detail">
                                <div
                                  className="Name"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate(`/userprofile/${value?._id}`);
                                    setSearch();
                                  }}
                                >
                                  {value?.firstName} {value?.lastName}
                                </div>
                                <div className="mt-1">
                                  <span>
                                    {value?.city}, {value?.country}
                                  </span>
                                </div>
                                {value?._id !== userData?.userId ? (
                                  <div className="mt-3 d-flex align-items-center">
                                    <button
                                      className="follow me-3"
                                      size="sm"
                                      onClick={() =>
                                        !token
                                          ? setShowLogin(true)
                                          : handleFollow(value?._id)
                                      }
                                    >
                                      {followIndex === -1 ? "Follow" : "Unfollow"}
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : searchBy === t("Users") ? (
              <div className="no_result">
                No result found! Try with different keywords!
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

      </div>
      <div className="hidden p-4 mt-48 overlays md:block">
        <div className="d-flex align-items-center justify-content-between search-header">
          <span>{searchBy}</span>
          <div onClick={setSearch} style={{ cursor: "pointer" }}>
            <MdOutlineClose />
          </div>
        </div>
        <div>
          {searchResult.length !== 0 && searchBy === t("Events") ? (
            <div className="row">
              {searchResult?.map((value, index) => {
                return (
                  <div
                    className="p-0 mb-1 d-flex col-md-12 col-12 ms-1"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/event/${value?._id}`);
                      setSearch();
                      setShowInput(false);
                    }}
                  >
                    <div className="p-0 m-0 col-md-3 col-sm-3">
                      <div style={{ width: "90%", border: "none" }}>
                        {/* {console.log(value?.imageURL)} */}
                        {/* {console.log(value.attachments[0].fileurl)} */}
                        <img
                          src={
                            value?.imageURL
                              ? value?.imageURL
                              : "/img/1553336600.png"
                            //value.attachments[0].fileurl
                          }
                          alt=""
                          style={{
                            cursor: "pointer",
                            width: "80px",
                            borderRadius: "10px",
                            height: "80px",
                            marginTop: "5px",
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-9">
                      <div className="mb-4 border-0">
                        <div className="col-12 w-100">
                          <h5
                            className="card-title"
                            style={{ fontSize: "14px" }}
                          >
                            <span className="me-1">
                              {moment(value?.startDate).format(
                                "ddd, MMM DD, YYYY"
                              )}
                            </span>
                            AT
                            <br />
                            <div className="pt-1">
                              {moment(value?.startDate).format("hh:mm A")}
                            </div>
                          </h5>
                          <div
                            className="card-text content-link"
                            style={{ fontSize: "14px" }}
                          >
                            {value?.title}
                          </div>
                          <div
                            className="pt-1 card-text"
                            style={{ fontSize: "14px" }}
                          >
                            {value?.address}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : searchBy === t("Events") ? (
            <div className="no_result">
              No result found! Try with different keywords!
            </div>
          ) : (
            ""
          )}
          {searchUser?.length !== 0 && searchBy === t("Users") ? (
            <div className="row">
              {searchUser?.map((value, index) => {
                const followIndex =
                  value?.followers !== undefined &&
                  value?.followers?.findIndex(
                    (data) => data.userId === userData?.userId
                  );
                return (
                  <div className="p-0 m-0 mb-3 col-xs-12" key={index}>
                    <div className="col-4">
                      <div
                        className="text-center card"
                        style={{ width: "100%", border: "none" }}
                      >
                        <div className="p-0 card-body">
                          <img
                            src={
                              value?.imageURL
                                ? value?.imageURL
                                : "/default/user.png"
                            }
                            alt="user_img"
                            style={{
                              width: "80px",
                              height: "80px",
                              cursor: "pointer",
                              borderRadius: "10px",
                            }}
                            onClick={() => {
                              navigate(`/userprofile/${value?._id}`);
                              setSearch();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="border-0 card">
                        <div className="p-0 card-body">
                          <h5
                            className="card-title"
                            style={{ fontSize: "14px" }}
                          >
                            <div className="detail">
                              <div
                                className="Name"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigate(`/userprofile/${value?._id}`);
                                  setSearch();
                                }}
                              >
                                {value?.firstName} {value?.lastName}
                              </div>
                              <div className="mt-1">
                                <span>
                                  {value?.city}, {value?.country}
                                </span>
                              </div>
                              {value?._id !== userData?.userId ? (
                                <div className="mt-3 d-flex align-items-center">
                                  <button
                                    className="follow me-3"
                                    size="sm"
                                    onClick={() =>
                                      !token
                                        ? setShowLogin(true)
                                        : handleFollow(value?._id)
                                    }
                                  >
                                    {followIndex === -1 ? "Follow" : "Unfollow"}
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : searchBy === t("Users") ? (
            <div className="no_result">
              No result found! Try with different keywords!
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <LoginModal
        show={showLogin}
        handleCloseLogin={() => handleCloseLogin()}
      />
    </>
  );
}
export default Search;
