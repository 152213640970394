import { MuiCard } from "./MuiCard";
import { useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Button } from "react-bootstrap";
import AvatarEditor from "react-avatar-editor";

export const MuiAvatar = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [details, setDetails] = useState([]);
  const handleClick = (data) => {
    setDetails(data);
    if (activeIndex === data.id) setActiveIndex(null);
    else setActiveIndex(data.id);
  };
  return (
    <>
      {props.overallData.length > 0 ? (
        props.overallData.map((data, index) => {
          return (
            <div key={index} style={{ backgroundColor: "white" }}>
              <ListGroup variant="flush" key={data.id}>
                <ListGroup.Item
                  as={Button}
                  style={{ textAlign: "left", border: "groove" }}
                  onClick={(e) => handleClick(data)}
                >
                  <div style={{ display: "flex" }}>
                    <AvatarEditor
                      image={data.image}
                      width={50}
                      height={50}
                      border={1}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={1.2}
                      rotate={0}
                      //borderRadius={100}
                    />
                    &nbsp;&nbsp;
                    {data.name}
                  </div>
                </ListGroup.Item>
                {activeIndex === data.id && (
                  <div
                    class="card mb-4 border-0"
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <MuiCard data={details} />
                  </div>
                )}
              </ListGroup>
            </div>
          );
        })
      ) : (
        <b id="modal-modal-title" variant="h6" component="h2" fontSize={13}>
          No data Found!!!
        </b>
      )}
    </>
  );
};
