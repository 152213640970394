import React from "react";
import App from "./App";
import "./index.css";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import langCode from "./languages/language.json";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);
const queryClient = new QueryClient();

console.log(window.location.hostname.split(".")[0], window.location.hostname.split('.')[1]);

let language;
if (window.location.hostname.split(".")[0] === "www") {
  language = langCode[window.location.hostname.split(".")[1]];
} else {
  language = langCode[window.location.hostname.split(".")[0]];
}

localStorage.setItem("REACT_APP_LANGUAGE", language?.language || "EN");
localStorage.setItem("REACT_APP_ANROID_URL", language?.anroidUrl);
localStorage.setItem("REACT_APP_IOS_URL", language?.iosUrl);
localStorage.setItem(
  "REACT_APP_BANNER",
  "/img/banner/" + language?.language + ".jpg"
);
localStorage.setItem(
  "REACT_APP_LOGO",
  "/img/logo/" + language?.language + ".jpg"
);
localStorage.setItem("REACT_APP_THEME", language?.theme);

// document.documentElement.style.setProperty(
//   "--primary",
//   localStorage.getItem("REACT_APP_THEME")
// );

root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
