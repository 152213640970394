import React from 'react';
import moment from 'moment';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations.json";


const Festivals = () => {
    const currentDate = moment(); // for Dynamic month
    const monthStart = currentDate.startOf('month'); // for Dynamic month
    const monthName = monthStart.format('MMMM');
    const currentYear = moment().year();
    const { t, i18n } = useTranslation();
    const [festivals, setFestivals] = useState([]);
    const [loading, setLoading] = useState(true);
    const app_language = "en";
    const data_language = i18n.language;
    const today = moment();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;

    const fetchfestivals = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`https://api.exaweb.in:3004/api/panchang/festival?year=${currentYear}&month=${monthName}&app_language=${app_language}&data_language=${data_language}`);
            if (response.data && Array.isArray(response.data)) {
                const flattenedFestivals = response.data.reduce(
                    (acc, day) => [
                        ...acc,
                        {
                            date: day.date,
                            festivals: day.festivals.filter(
                                (festival) => festival !== "NA"
                            ),
                        },
                    ],
                    []
                );
                const filteredFestivals = flattenedFestivals.filter(
                    (day) => day.festivals.length > 0
                );
                setFestivals(filteredFestivals);
                console.log("Festivals:", filteredFestivals);
                setLoading(false);
            } else {
                console.error("Error fetching events:");
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching events:", error.message);
            setLoading(false);
        }
    };

    const sundayTranslations = [
        "Sunday",      // English
        "રવિવાર",     // Gujarati
        "रविवार",     // Hindi
        "रविवार",     // Marathi
        "ಭಾನುವಾರ",     // Kannada
        "ஞாயிறு",  // Tamil
        "রবিবার",    // Bengali
        "ഞായറാഴ്ച",    // Malayalam
        "ఆదివారము",     // Telugu
        "ରବିବାର"      // Odia
    ];

    const truncateText = (text, length) => {
        if (text.length > length) {
            return text.substring(0, length) + '...';
        }
        return text;
    };

    useEffect(() => {
        fetchfestivals();
    }, [app_language, data_language]);

    return (
        <div>
            <div className='pl-3 mt-10 mb-16 '>
                <div className='flex flex-col items-center justify-center md:items-start md:justify-start'>
                    <div className='text-lg font-bold md:text-2xl font-poppins'>
                        {selectedTranslation.labels[monthName]}, {currentYear} {selectedTranslation.labels.Festivals}
                    </div>
                    <div className='text-xs md:text-sm'>
                        Vaishakha - Jyeshtha 2081
                    </div>
                    <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-3 lg:grid-cols-4">
                        {festivals.map((festival, index) => {
                            const dayDate = moment(festival.date, "DD MMMM YYYY dddd");
                            let bgColor = 'bg-[#F3F3F3]';
                            let bCOlor = '1px solid #F3F3F3'
                            if (today.format('DD') === festival.date.split(' ')[0]) {
                                bgColor = 'bg-black text-white';
                                bCOlor = '1px solid #000000'
                            } else if (sundayTranslations.includes(festival.date.split(' ').reverse()[0])) {
                                bgColor = 'bg-[#FF695F] text-white';
                            }
                            const limitedFestivals = festival.festivals.length > 3
                                ? festival.festivals.slice(0, 3)
                                : festival.festivals;

                            return (
                                <div
                                    key={index}
                                    className="flex flex-col items-start transition-all rounded-lg cursor-pointer"
                                    style={{ width: '308px', height: '72px', border: bCOlor }}
                                >
                                    <div className='flex'>
                                        <div className={`flex flex-col items-center justify-center rounded-l-md ${bgColor} `} style={{ width: '114px', height: '72px' }}>
                                            <span className='text-xs font-medium md:text-sm'>{festival.date.split(' ')[0]}</span>
                                            <span className='text-xs font-medium md:text-sm'>{festival.date.split(' ').reverse()[0]}</span>
                                        </div>
                                        <div className='flex flex-col items-center justify-center text-xs font-medium md:text-sm' style={{ width: '194px', height: '72px' }}>
                                            {limitedFestivals.map((event, idx) => (
                                                <span key={idx} className="mx-1">
                                                    {truncateText(event, 18)}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Festivals;
