import React from 'react'
import { useTranslation } from 'react-i18next';
import translations from "../../Components/Translations/Translations";

const Festivals = ({ details }) => {
    const { t, i18n } = useTranslation();
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    return (
        <div className='flex flex-col items-center justify-center mt-14'>
            <h3>{selectedTranslation.labels.detailsfestival}</h3>
            <div className='grid grid-cols-4 gap-24 p-10 mt-6 ml-6'>
                {details?.festivals ? (
                    details?.festivals?.map((festival, index) => (
                        <p key={index} className="">
                            {festival}
                        </p>
                    ))
                ) : (
                    <p className="">
                        None
                    </p>
                )}
            </div>
        </div>
    )
}

export default Festivals;