import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";

const RashiAndNakshatra = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center details-header" style={{ fontSize: "3.2vw" }}>
        {t("Rashi and Nakshatra")}
      </div>
      <div className="details-body panchang-gradient-3">
        <div className="row mx-auto" style={{ width: "88%" }}>
          {/* Moonsign */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">{t("Moonsign")}</h6>
              <p className="montserrat-font fw-semibold">
                {panchangDetails?.["Moonsign"]
                  ? panchangDetails?.["Moonsign"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Sunsign */}
          <div className="col-3">
            <div className="mx-auto">
              <h6 className="montserrat-font text-white">{t("Sunsign")}</h6>
              <p className="montserrat-font fw-semibold">
                {panchangDetails?.["Sunsign"]
                  ? panchangDetails?.["Sunsign"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Surya Nakshatra */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">
                {t("Surya Nakshatra")}
              </h6>
              <p className="montserrat-font fw-semibold">
                {panchangDetails?.["Surya Nakshatra"]
                  ? panchangDetails?.["Surya Nakshatra"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
          {/* Surya Pada */}
          <div className="col-3">
            <div className="mx-auto">
              {" "}
              <h6 className="montserrat-font text-white">{t("Surya Pada")}</h6>
              <p className="montserrat-font fw-semibold">
                {panchangDetails?.["Surya Pada"]
                  ? panchangDetails?.["Surya Pada"].map((data, index) => (
                      <span key={index}>{data}</span>
                    ))
                  : "None"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RashiAndNakshatra;
