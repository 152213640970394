
export const groomData=[
    {
        id: 1,
        name: 'Hemanth Achari',
        age: 30,
        height: "5'.8''",
        location: 'Mangalore',
        caste: 'ACHARI',
        image: "./staticImages/Male/Hemanth.png",
        description: "If interested, please respond with detailed profile/biodata and recent full-sized photo to: acharihemanth123@gmail.com",
      

    },
    {
        id: 2,
        name: 'Karthik Hubli',
        age: 28,
        height: "5'.6''",
        location: 'Hubli',
        caste: 'ADIKARNATAKA',
        image: './staticImages/Male/Karthik.png',
        description: "If interested, please respond with detailed profile/biodata and recent full-sized photo to: karthik678@gmail.com",

    },

];

export const brideData=[
    {
        id: 1,
        name: 'Priya',
        age: 23,
        height: "5'.5''",
        location: 'Bengaluru',
        caste: 'ACHARI',
        image: './staticImages/Female/Priya.png',
        description: "If interested, please respond with detailed profile/biodata and recent full-sized photo to: sudiammu3025@gmail.com",

    },
    {
        id: 2,
        name: 'Ankita',
        age: 25,
        height: "5'.3''",
        location: 'Bengaluru',
        caste: 'ADIKARNATAKA',
        image: './staticImages/Female/Soumya.png',
        description: "If interested, please respond with detailed profile/biodata and recent full-sized photo to: ankitha.pansh@gmail.com",
       
    },

];


export const casteSubcaste=[
    {
        caste: 'ACHARI',
        subCaste: ['VISHWAKARMA'],
    },
    {
        caste: 'ADIDRAVIDA',
        subCaste: ['ARAVDUR','CHALAVADI','HARIJAN','UPPARA'],
    },
    {
        caste: 'ADIKARNATAKA',
        subCaste: ['CHANAIAHNATHA','GANGEMATA','GOWNDAR','HALAKI','HARIJAN','HOLAYA','HOLER','HOLEYA','HOLIYA','KORAMA','KORUR','MADIGA','MEDAR','MOCHI','MUGGUDAVAR'],
        },

];

export const caste=['ACHARI','ADIDRAVIDA','ADIKARNATAKA','AGANARW','AGASA'];