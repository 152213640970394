import React from "react";
import { useTranslation } from "react-i18next";
import useKundliFetch from "../../../Hooks/useKundliFetch";

const ManglikDosh = () => {
  const { t } = useTranslation();
  const url = "https://json.astrologyapi.com/v1/manglik";
  const { data: manglikDosh } = useKundliFetch(url);

  let manglikPresentKeys;
  if (manglikDosh.length !== 0) {
    manglikPresentKeys = Object.keys(manglikDosh.manglik_present_rule);
  }
  return (
    <>
      {manglikDosh.length !== 0 && (
        <div>
          <div className="text-center details-header">{t("Manglik")}</div>
          <div className="details-body kundli-gradient-5 shadow-lg">
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("Is Manglik Dosh Present?")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {manglikDosh?.is_present === true ? t("yes") : t("no")}
              </span>
            </div>
            {manglikDosh?.percentage_manglik_present && (
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Percentage Of Manglik Present")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {manglikDosh?.percentage_manglik_present}
                </span>
              </div>
            )}
            <div className="card-body py-1 py-lg-2">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("Is Mars Manglik Cancelled?")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {manglikDosh?.is_mars_manglik_cancelled === true ? "Yes" : "No"}
              </span>
            </div>
            {manglikDosh?.percentage_manglik_after_cancellation && (
              <div className="card-body py-1 py-lg-2">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Percentage Of Manglik After Cancellation")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {manglikDosh?.percentage_manglik_after_cancellation}
                </span>
              </div>
            )}
            {manglikDosh?.manglik_report && (
              <div className="card-body py-1 py-lg-2">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Report")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {manglikDosh?.manglik_report}
                </span>
              </div>
            )}
            {manglikDosh?.manglik_status && (
              <div className="card-body py-1 py-lg-2">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Manglik Status")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {manglikDosh?.manglik_status.split("_").join(" ")}
                </span>
              </div>
            )}
          </div>
          {/* Manglik Present Rule */}
          <div>
            {manglikPresentKeys?.map((key, index) => (
              <div key={index} className="shadow-lg">
                <div className="text-center details-header">
                  {t(`Manglik Present Rule ${key}`)}
                </div>
                <ul
                  className={`list-group list-group-flush details-body kundli-gradient-${
                    index + 1
                  }`}
                >
                  {manglikDosh?.manglik_present_rule[key]?.map(
                    (rule, index) => (
                      <li
                        key={index}
                        className="list-group-item bg-transparent"
                      >
                        <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                          {rule}
                        </span>
                      </li>
                    )
                  )}
                </ul>
              </div>
            ))}
          </div>
          {/* Manglik Cancel Rule  */}
          <div className="shadow-lg">
            <div className="text-center details-header">
              {t("Manglik Cancel Rule")}
            </div>
            <ul className="list-group list-group-flush details-body kundli-gradient-3">
              {manglikDosh?.manglik_cancel_rule?.length !== 0 ? (
                manglikDosh?.manglik_cancel_rule?.map((rule, index) => (
                  <li key={index} className="list-group-item bg-transparent">
                    <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                      {rule}
                    </span>
                  </li>
                ))
              ) : (
                <li className="list-group-item bg-transparent">
                  <span className="montserrat-font fw-semibold text-white mb-0 mb-lg-2">
                    {t("You don't have any Manglik Cancel Rule")}
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default ManglikDosh;
