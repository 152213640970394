import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const usersSlice = createSlice({
  name: "searchUser",
  initialState: {
    isLoading: true,
    searchUser: [],
    user: "",
    follow: "",
    userlist: [],
    following: [],
    followers: [],
    suggested: [],
    userevents: [],
    invited: [],
    userfollowers: [],
  },
  reducers: {
    getSearchUser: (state, action) => {
      state.isLoading = true;
      state.searchUser = [];
    },
    getSearchUserSuccess: (state, action) => {
      state.isLoading = false;
      state.searchUser = action?.payload;
    },
    getSearchUserFailed: (state, action) => {
      state.isLoading = false;
      state.searchUser = action?.payload;
    },

    getUserData: (state, action) => {
      state.isLoading = true;
      state.user = "";
    },
    getUserDataSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action?.payload;
    },
    getUserDataFailed: (state, action) => {
      state.isLoading = false;
      state.user = action?.payload;
    },

    getFollowUser: (state, action) => {
      state.isLoading = true;
      state.follow = "";
    },
    getFollowUserSuccess: (state, action) => {
      state.isLoading = false;
      state.follow = action?.payload;
      if (state.user?._id === action?.payload?.followerId?._id) {
        state.user = action?.payload?.followerId;
      }
      let followId = state.searchUser.findIndex(
        (data) => data?._id === action?.payload?.followerId?._id
      );
      state.searchUser[followId] = action?.payload?.followerId;
      let followersIndex = state.followers.findIndex(
        (data) => data?._id === action?.payload?.followerId?._id
      );
      if (followersIndex !== undefined) {
        state.followers[followersIndex] = action?.payload?.followerId;
      }
      const follow_sugg_index = state.suggested.findIndex(
        (data) => data?._id === action?.payload?.followerId?._id
      );
      if (follow_sugg_index !== -1) {
        state.suggested[follow_sugg_index] = action?.payload?.followerId;
        state.suggested.splice(follow_sugg_index, 1);
        toast.success(
          `You Are Following ${action?.payload?.followerId?.firstName} ${action?.payload?.followerId?.lastName}`
        );
      }
      state.userlist = state.userlist.map((data) => {
        if (data?.usersId?._id === action?.payload?.followerId?._id) {
          return { ...data, usersId: action?.payload?.followerId };
        }
        return data;
      });
      state.invited = state.invited.map((data) => {
        if (data?.usersId?._id === action?.payload?.followerId?._id) {
          return { ...data, usersId: action?.payload?.followerId };
        }
        return data;
      });
    },
    getFollowUserFailed: (state, action) => {
      state.isLoading = false;
      state.follow = action?.payload;
    },

    getUserList: (state, action) => {
      state.isLoading = true;
      state.userlist = [];
    },
    getUserListSuccess: (state, action) => {
      state.isLoading = false;
      state.userlist = action?.payload;
    },
    getUserListFailed: (state, action) => {
      state.isLoading = false;
      state.userlist = action?.payload;
    },

    getUserFollowing: (state, action) => {
      state.isLoading = true;
      state.following = [];
    },
    getUserFollowingSuccess: (state, action) => {
      state.isLoading = false;
      state.following = action?.payload;
    },
    getUserFollowingFailed: (state, action) => {
      state.isLoading = false;
      state.following = action?.payload;
    },

    getUserFollowers: (state, action) => {
      state.isLoading = true;
      state.followers = [];
    },
    getUserFollowersSuccess: (state, action) => {
      state.isLoading = false;
      state.followers = action?.payload;
    },
    getUserFollowersFailed: (state, action) => {
      state.isLoading = false;
      state.followers = action?.payload;
    },

    getSuggestedAccount: (state, action) => {
      state.isLoading = true;
      state.suggested = [];
    },
    getSuggestedAccountSuccess: (state, action) => {
      state.isLoading = false;
      state.suggested = action?.payload;
    },
    getSuggestedAccountFailed: (state, action) => {
      state.isLoading = false;
      state.suggested = action?.payload;
    },

    getUserEvents: (state, action) => {
      state.isLoading = true;
      state.userevents = [];
    },
    getUserEventsSuccess: (state, action) => {
      state.isLoading = false;
      state.userevents = action?.payload;
    },
    getUserEventsFailed: (state, action) => {
      state.isLoading = false;
      state.userevents = action?.payload;
    },

    getInvitedUsers: (state, action) => {
      state.isLoading = true;
      state.invited = [];
    },
    getInvitedUsersSuccess: (state, action) => {
      state.isLoading = false;
      state.invited = action?.payload;
    },
    getInvitedUsersFailed: (state, action) => {
      state.isLoading = false;
      state.invited = action?.payload;
    },

    getInviteFollowers: (state, action) => {
      state.isLoading = true;
      state.userfollowers = [];
    },
    getInviteFollowersSuccess: (state, action) => {
      state.isLoading = false;
      state.userfollowers = action?.payload;
    },
    getInviteFollowersFailed: (state, action) => {
      state.isLoading = false;
      state.userfollowers = action?.payload;
    },
  },
});

export const usersAction = usersSlice.actions;
export default usersSlice;
