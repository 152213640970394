import "./userProfile.scss";
import Card from "../../Components/Card/card";
import { useDispatch, useSelector } from "react-redux";
import { getEventListApi, showEvents } from "../../store/actions/eventActions";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFollowUser,
  getUserData,
  getUserFollowers,
  getUserFollowing,
} from "../../store/actions/userActions";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { findEventStatus } from "../../store/actions/statusActions";
import LoginModal from "../../Components/Modal/Login/LoginModal";
import Follower from "../../Components/Modal/followers/following/follower_following";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TiTick } from "react-icons/ti";
import Helmetcomponent from "../../Components/Helmet/helmetcomponent";

function UserProfile() {
  const { t } = useTranslation();
  const [status, setStatus] = useState("events");
  const id = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.SearchUser?.user);
  const eventStatus = useSelector((state) => state?.status?.eventstatus);
  const followers = useSelector((state) => state?.SearchUser?.followers);
  const followings = useSelector((state) => state?.SearchUser?.following);
  const events = useSelector(showEvents);
  const userData = useSelector((state) => state?.user);
  const token = localStorage.getItem("token");
  const [isFollower, setFollower] = useState(false);

  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);

  const [showFollower, setShowFollower] = useState(false);
  const handleCloseFollower = () => setShowFollower(false);
  const handleShowFollower = () => setShowFollower(true);

  useEffect(() => {
    if (Object.keys(id).length !== 0) {
      dispatch(getUserData(id));
      dispatch(getEventListApi({ userId: id?.id, eventStatus: "APPROVED" }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (status !== "events") {
      const data = {
        usersId: id?.id,
        status: status,
      };
      dispatch(findEventStatus(data));
    }
  }, [status, id?.id, dispatch]);

  function handleFollow() {
    const data = {
      followingId: userData?.userId,
      followerId: id?.id,
    };
    dispatch(getFollowUser(data));
  }
  const followIndex = user?.followers?.findIndex(
    (data) => data.userId === userData?.userId
  );

  function handleFollower() {
    const follower = {
      userId: id?.id,
      following: false,
    };
    dispatch(getUserFollowers(follower));
  }
  function handleFollowing() {
    const following = {
      userId: id?.id,
      following: true,
    };
    dispatch(getUserFollowing(following));
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="userprofile">
      <Helmetcomponent index={"UserProfile"} path={id.id} data={""}/>
      <div style={{ paddingTop: "3.3%" }}>
        <div className="list-bg"></div>
        <div className="info-container list-widget bg-effect" style={{ }}>
          
          <div className="row" style={{ paddingBottom: "20px" }}>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">

              <h1
                className="text-center"
                style={{ fontSize: "28px", fontWeight: "400" }}
              >
                {t("User_Profile")}
              </h1>
              
            </div>

            <div className="info" style={{ fontSize: "15px" }}>
              <div className="row mt-4 d-flex justify-content-center align-items-start">

                <div className="col-12 col-sm-12 col-md-3 text-center">
                  <div className="gap-1">

                    <img className="rounded-circle profile-img mb-3" src={ user?.imageURL ? user?.imageURL : "/default/user.png"} alt="" />
                    
                    {user?.isVerified === true && (
                      <span
                        style={{
                          display: "block",
                          height: "60px",
                          width: "60px",
                          borderRadius: "50%",
                          backgroundColor: "green",
                          color: "white",
                          textAlign: "center",
                          fontSize: "2em",
                          position: "relative",
                          bottom: "52px",
                          left: "128px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TiTick
                          style={{
                            color: "white",
                            height: "40px",
                            width: "40px",
                          }}
                        />
                      </span>
                    )}
                  </div>
                </div>


                <div className="col-md-6">

                  {user?.firstName && (
                    <div className="mt-1">
                      {t("Name")}:<span>{user?.firstName}</span>
                      <span>{user?.lastName}</span>
                    </div>
                  )}
                  {user?.userMob && (
                    <div className="mt-1">
                      {t("Mobile_Number")}:<span>{user?.userMob}</span>
                    </div>
                  )}
                  {user?.email && (
                    <div className="mt-1">
                      {t("Email")}:<span>{user?.email}</span>
                    </div>
                  )}
                  {user?.address && (
                    <div className="mt-1">
                      {t("Address")}:<span>{user?.address}</span>
                    </div>
                  )}
                  {user?.city && (
                    <div className="mt-1">
                      {t("City")}:<span>{user?.city}</span>
                    </div>
                  )}
                  {user?.Country && (
                    <div className="mt-1">
                      {t("Country")}:<span>{user?.Country}</span>
                    </div>
                  )}

                  <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row followers mt-2 ms-0">
                    <div style={{ cursor: "pointer" }}>

                      <Button
                        variant="light"
                        style={{ width: "120px", background: "#e7e7e7" }}
                        className={"btn_followes mb-1"}
                        onClick={() => {
                          user?.followers?.length > 0 && handleShowFollower();
                          handleFollower();
                          setFollower(true);
                        }}
                      >
                        <span className="ms-0" style={{ color: "black" }}>
                          {user?.followers?.length}
                        </span>
                        <span style={{ color: "black" }}>{t("Followers")}</span>
                      </Button>

                    </div>

                    <div className="" style={{ cursor: "pointer" }}>
                      <Button
                        variant="light"
                        style={{ width: "120px", background: "#e7e7e7" }}
                        className={"btn_followes"}
                        onClick={() => {
                          user?.followings?.length > 0 && handleShowFollower();
                          handleFollowing();
                          setFollower(false);
                        }}
                      >
                        <span style={{ color: "black" }}>
                          {user?.followings?.length}
                        </span>
                        <span style={{ color: "black" }}>
                          {t("Followings")}
                        </span>
                      </Button>
                    </div>

                    <div>
                      <span></span>
                      <span></span>
                    </div>
                    
                  </div>
                </div>
                {userData?.userId === id?.id ? (
                  <div className="col-md-3 text-align-right">
                    <Button
                      onClick={() => navigate("/profile")}
                      style={{
                        width: "150px",
                        height: "40px",
                        fontSize: "16px",
                      }}
                    >
                      {t("Edit_Profile")}
                    </Button>
                  </div>
                ) : (
                  <div className="col-md-3 col-sm-12 col-lg-3 text-aling-right follow-right">
                    <button
                      className="follow"
                      size="sm"
                      onClick={() =>
                        !token ? setShowLogin(true) : handleFollow()
                      }
                      style={{
                        width: "120px",
                        height: "40px",
                        fontSize: "16px",
                      }}
                    >
                      {followIndex === -1 ? t("Follow") : t("Unfollow")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          
          
          <div className="col-lg-12 col-sm-12 col-md-12 mt-5 dropdown-item-design">
            <Tabs
              defaultActiveKey="events"
              id="justify-tab-example"
              activeKey={status}
              onSelect={(k) => setStatus(k)}
              className="mb-3"
              justify
            >
              <Tab eventKey="events" title={t("EVENTS")}>
                <div className="row mx-auto">
                  {events?.data?.data?.length > 0 ? (
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                      <div className="card-design mt-5">
                        {events?.data?.data?.length > 0 &&
                          events?.data?.data?.map((value, index) => {
                            return (
                              <div
                                key={index}
                                className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                              >
                                <Card event={value} index={index} />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className="notFound">{t("No_Events_Found")}</div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="invited" title={t("INVITED")}>
                {eventStatus?.length > 0 ? (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <div className="card-design mt-5">
                      {eventStatus?.length > 0 &&
                        eventStatus?.map((value, index) => {
                          return (
                            <div
                              key={index}
                              className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                            >
                              <Card event={value} index={index} />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="notFound">{t("No_Events_Found")}</div>
                )}
              </Tab>
              <Tab eventKey="attending" title={t("ATTENDING")}>
                {eventStatus?.length > 0 ? (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <div className="card-design mt-5">
                      {eventStatus?.length > 0 &&
                        eventStatus?.map((value, index) => {
                          return (
                            <div
                              key={index}
                              className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                            >
                              <Card event={value} index={index} />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="notFound">{t("No_Events_Found")}</div>
                )}
              </Tab>
              <Tab eventKey="not attending" title={t("NOT_ATTENDING")}>
                {eventStatus?.length > 0 ? (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <div className="card-design mt-5">
                      {eventStatus?.length > 0 &&
                        eventStatus?.map((value, index) => {
                          return (
                            <div
                              key={index}
                              className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                            >
                              <Card event={value} index={index} />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="notFound">{t("No_Events_Found")}</div>
                )}
              </Tab>
              <Tab eventKey="may be attending" title={t("MAY_BE_ATTENDING")}>
                {eventStatus?.length > 0 ? (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <div className="card-design mt-5">
                      {eventStatus?.length > 0 &&
                        eventStatus?.map((value, index) => {
                          return (
                            <div
                              key={index}
                              className="col-lg-4 col-md-6 col-sm-12 hover pe-4 pb-4"
                            >
                              <Card event={value} index={index} />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="notFound">{t("No_Events_Found")}</div>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <LoginModal
        show={showLogin}
        handleCloseLogin={() => handleCloseLogin()}
      />
      <Follower
        show={showFollower}
        close={handleCloseFollower}
        followers={isFollower ? followers : followings}
        isFollower={isFollower}
        token={token}
        userData={userData}
      />
    </div>
  );
}

export default UserProfile;
