import { createSlice } from "@reduxjs/toolkit";

export const fileSlice = createSlice({
  name: "file",
  initialState: {
    image: [],
    file: [],
    profile: [],
  },
  reducers: {
    uploadImage: (state, action) => {
      state.image = action.payload;
    },
    uploadProfile: (state, action) => {
      state.profile = action.payload;
    },
    clearImageData: (state, action) => {
      state.image = [];
    },
    uploadFile: (state, action) => {
      const file_upload = {
        filetype: action.payload.fileType,
        fileurl: action.payload.url,
      };
      state.file = file_upload;
    },
    clearFileData: (state, action) => {
      state.file = [];
    },
    deleteFile: (state, action) => {
      state.file.splice(
        state.file.findIndex((data) => data.fileurl === action.payload),
        1
      );
    },
  },
});

export const fileAction = fileSlice.actions;
export default fileSlice;
