import React from 'react'
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import translations from "../../Components/Translations/Translations";
import { useState, useEffect } from "react";
import axios from "axios";
import Tithinakshatra from './tithinakshatra';
import Lunarmonth from './lunarmonth';
import Rashi from './rashi';
import Ritu from './ritu';
import Auspicious from './auspicious';
import Inauspicious from './inauspicious';
import Anandi from './anandi';
import Nivas from './nivas';
import Epoch from './epoch';
import Chandrabalam from './chandrabalam';
import Panchaka from './panchaka';
import Festivals from './festivals';
import Panchang from './panchang';

const Detailedinfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const { t, i18n } = useTranslation();
    const app_language = "en";
    const data_language = i18n.language;
    const storedLanguage = localStorage.getItem("lang") || "en";
    const selectedTranslation = translations[storedLanguage] || translations.en;
    const [day, month, year] = [id.slice(0, 2), id.slice(2, 4), id.slice(4, 6)];
    const Date = `${day}/${month}/${year}`;

    const fetchpanchang = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LIVE_URL}/api/panchang/daily?date=${Date}&app_language=${app_language}&data_language=${data_language}`);
            setDetails(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data: ", error);
        }
    };

    useEffect(() => {
        fetchpanchang();
        window.scrollTo(0,0);
    }, [app_language, data_language]);

    console.log("Details", details);
    return (
        <div>
            {loading ? (
                <h1>Loading...</h1>
            ) : (
                <div className='flex flex-col items-center justify-center pt-16 font-poppins'>
                    <h1 className='text-4xl font-bold'>{details?.date}</h1>
                    <Panchang details={details} />
                    <Tithinakshatra details={details} />
                    <Lunarmonth details={details} />
                    <Rashi details={details} />
                    <Ritu details={details} />
                    <Auspicious details={details} />
                    <Inauspicious details={details} />
                    <Anandi details={details} />
                    <Nivas details={details} />
                    <Epoch details={details} />
                    <Chandrabalam details={details} />
                    <Panchaka details={details} />
                    <Festivals details={details} />
                </div>
            )}
        </div>
    );
}

export default Detailedinfo;