import { toast } from "react-toastify";
import httpService from "../../common/http.service";
import { authAction } from "../slice/authSlice";

export const getLoginUserData = (data) => async (dispatch) => {
  try {
    const response = await httpService.post("user", {}, data);
    if (response?.data?.data) {
      const userToken = response?.data?.data?.token;
      const userId = response?.data?.data?.user?._id;
      localStorage.setItem("token", userToken);
      localStorage.setItem("userId", userId);
    }
    dispatch(authAction.getLoginUser(response?.data?.data));
  } catch (err) {
    dispatch(authAction.getLoginUserFailed(err));
    throw new Error(err);
  }
};

export const getUpdateUserData = (data) => async (dispatch) => {
  try {
    const response = await httpService.put(`user?id=${data._id}`, {}, data);
    if (response?.data?.status === true) {
      toast.success("Your Profile is Updated Successfully");
      const response1 = await httpService.get(
        `user?language=${data.language}&searchKey=_id&searchValue=${data._id}`
      );
      dispatch(authAction.getUpdateUserSuccess(response1?.data?.data?.data[0]));
    }
  } catch (err) {
    toast.error(err);
    dispatch(authAction.getUpdateUserFailed(err));
  }
};

export const getUser =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = await httpService.get(
        `user?searchKey=_id&searchValue=${id}`
      );
      if (response && response?.data) {
        dispatch(authAction.getLoginUser(response?.data?.data?.data[0]));
      }
    } catch (err) {
      // dispatch(authAction.getUserFailed(err));
    }
  };
