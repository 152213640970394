import { createSlice } from "@reduxjs/toolkit";

export const statusSlice = createSlice({
  name: "eventStatus",
  initialState: {
    isLoading: true,
    status: "",
    eventstatus: [],
  },
  reducers: {
    getStatus: (state, action) => {
      state.isLoading = true;
      state.status = "";
    },
    getStatusSuccess: (state, action) => {
      state.isLoading = false;
      state.status = action?.payload;
    },
    getStatusFailed: (state, action) => {
      state.isLoading = false;
      state.status = action?.payload;
    },

    getEventStatus: (state, action) => {
      state.isLoading = true;
      state.eventstatus = [];
    },
    getEventStatusSuccess: (state, action) => {
      state.isLoading = false;
      state.eventstatus = action?.payload;
    },
    getEventStatusFailed: (state, action) => {
      state.isLoading = false;
      state.eventstatus = action?.payload;
    },
  },
});

export const statusAction = statusSlice.actions;
export default statusSlice;
