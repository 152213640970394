import React, { useCallback, useEffect, useRef, useState } from "react";
import "./OrderNowForm.scss";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import axios from "axios";
import { usePlacesWidget } from "react-google-autocomplete";
import useRazorpay from "react-razorpay";
import { Link } from "react-router-dom";
import Translations from "../Translations/Translations.json";

function OrderNowForm() {
  const formref = useRef(null);
  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = Array.from({ length: 100 }, (_, index) => 2024 - index);
  const hours = Array.from({ length: 12 }, (_, index) => index + 1);
  const minutes = Array.from({ length: 59 }, (_, index) => index + 1);
  const { t, i18n } = useTranslation();
  const l = localStorage.getItem("REACT_APP_LANGUAGE");
  const selectedLang = localStorage.getItem("lang");
  const options = [
    { value: "en", label: t("English") },
    { value: "gu", label: t("Gujarati") },
    { value: "hi", label: t("Hindi") },
    { value: "mr", label: t("Marathi") },
    { value: "kn", label: t("Kannada") },
    { value: "ta", label: t("Tamil") },
    { value: "bn", label: t("Bengali") },
    { value: "ml", label: t("Malayalam") },
    { value: "te", label: t("Telugu") },
    { value: "or", label: t("Odia") },
  ];
  const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const coordinates = JSON.parse(sessionStorage.getItem("tempLocation"));
  const [selectedOption, setSelectedOption] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [Razorpay] = useRazorpay();
  const [downloadLinkVisible, setDownloadLinkVisible] = useState(false);
  const [transactionError, setTransactionError] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const storedLanguage = localStorage.getItem("lang") || "en";
  const selectedTranslation = Translations[storedLanguage] || Translations.en;

  const saveFormDataToLocalStorage = (formData) => {
    localStorage.setItem("savedFormData", JSON.stringify(formData));
  };

  const loadFormDataFromLocalStorage = () => {
    const savedFormData = localStorage.getItem("savedFormData");
    return savedFormData ? JSON.parse(savedFormData) : null;
  };
  const initialFormData = loadFormDataFromLocalStorage() || {
    name: "",
    gender: "",
    email: "",
    phone: "",
    day: "",
    month: "1",
    year: "",
    hour: "",
    minute: "",
    timeperiod: "",
    lat: coordinates ? coordinates.lat : "",
    lon: coordinates ? coordinates.lng : "",
    // lat: coordinates && coordinates.lat ? coordinates.lat : "",
    // lon: coordinates && coordinates.lng ? coordinates.lng : "",
    place: sessionStorage.getItem("temp_location_name")
      ? sessionStorage.getItem("temp_location_name")
      : "",
    language: "",
  };

  const [formData, setFormData] = useState({
    ...initialFormData,
    validation: {
      name: { isValid: true, error: "" },
      gender: { isValid: true, error: "" },
      email: { isValid: true, error: "" },
      phone: { isValid: true, error: "" },
      day: { isValid: true, error: "" },
      month: { isValid: true, error: "" },
      year: { isValid: true, error: "" },
      hour: { isValid: true, error: "" },
      minute: { isValid: true, error: "" },
      timeperiod: { isValid: true, error: "" },
      country: { isValid: true, error: "" },
      language: { isValid: true, error: "" },
      place: { isValid: true, error: "" },
    },
  });

  const handleChange = (selectedOption) => {
    // console.log(selectedOption);
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLang ? selectedLang : l.toLowerCase());
  }, [l, selectedLang]);

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_KEY,
    onPlaceSelected: async (place) => {
      console.log(place);
      sessionStorage.setItem("temp_location_name", place?.formatted_address);

      await sessionStorage.setItem(
        "tempLocation",
        JSON.stringify({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        })
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        place: place?.formatted_address || "",
        validation: {
          ...prevFormData.validation,
          place: { isValid: true, error: "" },
        },
      }));
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    const parsedValue =
      name === "hour" || name === "minute" ? parseInt(value, 10) : value;
    setFormData({
      ...formData,
      [name]: parsedValue,
    });
  };

  const handleGenderChange = (e) => {
    setFormData({
      ...formData,
      gender: e.target.value,
    });
  };

  const handleDayChange = (e) => {
    setFormData({
      ...formData,
      day: e.target.value,
    });
  };

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;

    console.log("Selected Month:", selectedMonth);

    setFormData({
      ...formData,
      month: selectedMonth,
    });
  };

  const handleYearChange = (e) => {
    setFormData({
      ...formData,
      year: e.target.value,
    });
  };

  const handleHoursChange = (e) => {
    setFormData({
      ...formData,
      hour: e.target.value,
    });
  };

  const handleMinutesChange = (e) => {
    setFormData({
      ...formData,
      minute: e.target.value,
    });
  };

  const handleAmorPmChange = (e) => {
    setFormData({
      ...formData,
      timeperiod: e.target.value,
    });
  };

  useEffect(() => {
    saveFormDataToLocalStorage(formData);
  }, [formData]);

  const validateForm = () => {
    let isFormValid = true;
    let newValidation = { ...formData.validation };

    // Reset all validation errors
    Object.keys(newValidation).forEach((key) => {
      newValidation[key] = { isValid: true, error: "" };
    });

    // Perform validation checks
    if (formData.name.trim() === "") {
      newValidation.name = { isValid: false, error: "Name is required" };
      isFormValid = false;
    }

    if (formData.gender === "") {
      newValidation.gender = { isValid: false, error: "Gender is required" };
      isFormValid = false;
    }

    if (!formData.email.includes("@")) {
      newValidation.email = { isValid: false, error: "Invalid email address" };
      isFormValid = false;
    }

    if (!formData.phone.trim() || isNaN(formData.phone.trim())) {
      newValidation.phone = { isValid: false, error: "Invalid phone number" };
      isFormValid = false;
    } else {
      newValidation.phone = { isValid: true, error: "" };
    }

    if (!formData.day || !formData.month || !formData.year) {
      if (!formData.day) {
        newValidation.day = { isValid: false, error: "Day is required" };
      } else {
        newValidation.day = { isValid: true, error: "" };
      }

      if (!formData.month) {
        newValidation.month = { isValid: false, error: "Month is required" };
      } else {
        newValidation.month = { isValid: true, error: "" };
      }

      if (!formData.year) {
        newValidation.year = { isValid: false, error: "Year is required" };
      } else {
        newValidation.year = { isValid: true, error: "" };
      }

      isFormValid = false;
    } else {
      newValidation.day = { isValid: true, error: "" };
      newValidation.month = { isValid: true, error: "" };
      newValidation.year = { isValid: true, error: "" };
    }

    if (!formData.hour || !formData.minute || !formData.timeperiod) {
      newValidation.hour = { isValid: false, error: "Time is required" };
      newValidation.minute = { isValid: false, error: "Time is required" };
      newValidation.timeperiod = {
        isValid: false,
        error: "Time Period is required",
      };
      isFormValid = false;
    } else {
      newValidation.hour = { isValid: true, error: "" };
      newValidation.minute = { isValid: true, error: "" };
      newValidation.timeperiod = { isValid: true, error: "" };
    }

    if (formData.place.trim() === "") {
      newValidation.place = { isValid: false, error: "Place is required" };
      isFormValid = false;
    }

    // Update the state with new validation
    setFormData((prevFormData) => ({
      ...prevFormData,
      validation: newValidation,
    }));

    // console.log("FormData:", formData);
    // console.log("New Validation:", newValidation);

    const firstInvalidField = Object.keys(newValidation).find(
      (key) => !newValidation[key].isValid
    );

    // If there's an invalid field, scroll to it
    if (firstInvalidField) {
      const invalidFieldElement = formref.current.querySelector(
        `[name=${firstInvalidField}]`
      );
      if (invalidFieldElement) {
        invalidFieldElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }

    return isFormValid;
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("savedFormData");
  };

  const handleRazorpayPayment = useCallback(() => {
    // console.log("Inside handleRazorpayPayment. PDF URL:", pdfUrl);
    if (pdfUrl) {
      const options = {
        key: "rzp_test_0Q1joeMpqbJsIz",
        amount: "9900",
        currency: "INR",
        name: "Bharat Calenders",
        description: "Test Transaction",
        handler: (res) => {
          console.log(res);
          setTransactionSuccess(true);
        },
        modal: {
          ondismiss: () => {
            console.log("Payment modal dismissed");
            setTransactionError(true);
          },
        },
        onhidden: () => {
          console.log("Payment modal hidden");
          if (!transactionSuccess) {
            setDownloadLinkVisible(false);
          }
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);

      rzpay.on("payment.success", (response) => {
        console.log("Payment success response:", response);
        if (response.razorpay_payment_id) {
          clearLocalStorage();
          setTransactionSuccess(true);
        }
      });

      rzpay.open();
    }
  }, [pdfUrl]);

  useEffect(() => {
    console.log(pdfUrl);
    if (pdfUrl) {
      handleRazorpayPayment();
      setAgreeToTerms(false);
    }
  }, [pdfUrl, handleRazorpayPayment]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      console.log("Not a valid Form!");
      return;
    }
    setLoading(true);
    const apiData = {
      name: formData.name,
      gender: formData.gender,
      day: formData.day,
      month: formData.month,
      year: formData.year,
      hour: formData.hour,
      min: formData.minute,
      language: selectedOption?.value,
      tzone: 5.5,
      place: sessionStorage.getItem("temp_location_name")
        ? sessionStorage.getItem("temp_location_name")
        : "",
      lat: formData.lat,
      lon: formData.lon,
      chart_style: "NORTH_INDIAN",
      footer_link: "astrologyapi.com",
      logo_url: "logo_url",
      company_name: "Vedic Rishi Astro Solutions Pvt. Ltd.",
      company_info: "Your Company Info",
      domain_url: "https://www.astrologyapi.com",
      company_email: "mail@astrologyapi.com",
      company_landline: "+91-22123222",
      company_mobile: "+91 1212 1212 12",
    };
    // console.log(selectedOption?.value);
    try {
      const response = await axios.post(
        "https://pdf.astrologyapi.com/v1/basic_horoscope_pdf",
        apiData,
        {
          auth: {
            username: 4545,
            password: "ByVOIaODH57QRVi6CqswHXGlcpDvj7tZBRoorY",
          },
        }
      );

      // console.log("API Response:", response.data);
      if (response.status === 200) {
        console.log("Generated PDF URL:", response.data.pdf_url);
        setPdfUrl(response.data.pdf_url);
        handleRazorpayPayment();
        setDownloadLinkVisible(true);
      } else {
        setTransactionError(true);
        console.log("PDF URL is not available");
      }
    } catch (error) {
      console.error("API Error (Full Error):", error);
      console.error("Complete Error Object:", error);
    } finally {
      setLoading(false);
    }
    // console.log(apiData);
  };

  return (
    <div className="mb-5">
      <div className="container shadow mb-4">
        <p className="text-danger">{t(selectedTranslation.labels.important)}</p>
        <div className="row">
          <form onSubmit={handleSubmit} ref={formref}>
            <div className="form-group">
              <div className="row text-center mb-3 product-heading">
                <div className="col-6">
                  <span className="fs-5">
                    {t(selectedTranslation.labels.title)}
                  </span>
                </div>
                <div className="col-6">
                  <b className="fs-5">
                    {t(selectedTranslation.labels.price)}:₹ 99
                  </b>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-6">
                  <label>{t(selectedTranslation.labels.name)}</label>
                  <input
                    type="text"
                    className={`form-control ${
                      !formData.validation.name.isValid ? "is-invalid" : ""
                    }`}
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  <p className="invalid-feedback text-danger">
                    {formData.validation.name.error}
                  </p>
                </div>
                <div className="col-6">
                  <label>{t(selectedTranslation.labels.gender)}</label>
                  <select
                    className={`form-control ${
                      !formData.validation.gender.isValid ? "is-invalid" : ""
                    }`}
                    name="gender"
                    value={formData.gender}
                    onChange={handleGenderChange}
                  >
                    <option value="">Gender*</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <p className="invalid-feedback text-danger">
                    {formData.validation.gender.error}
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-6">
                  <label>{t(selectedTranslation.labels.email)}</label>
                  <input
                    type="email"
                    className={`form-control ${
                      !formData.validation.email.isValid ? "is-invalid" : ""
                    }`}
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <p className="invalid-feedback text-danger">
                    {formData.validation.email.error}
                  </p>
                </div>
                <div className="col-6">
                  <label>{t(selectedTranslation.labels.phone)}</label>
                  <input
                    type="number"
                    className={`form-control ${
                      !formData.validation.phone.isValid ? "is-invalid" : ""
                    }`}
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                  <p className="invalid-feedback">
                    {formData.validation.phone.error}
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label className="form-label">
                    {t(selectedTranslation.labels.day)}
                  </label>
                  <select
                    className={`form-select ${
                      !formData.validation.day.isValid ? "is-invalid" : ""
                    }`}
                    id="day"
                    name="day"
                    value={formData.day}
                    onChange={handleDayChange}
                  >
                    <option className="form-label">Birth Day</option>
                    {days.map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                  <p className="invalid-feedback">
                    {formData.validation.day.error}
                  </p>
                </div>
                <div className="col-4">
                  <label className="form-label">
                    {t(selectedTranslation.labels.month)}
                  </label>
                  <select
                    className={`form-select ${
                      !formData.validation.month.isValid ? "is-invalid" : ""
                    }`}
                    id="month"
                    name="month"
                    value={formData.month}
                    onChange={handleMonthChange}
                  >
                    <option className="form-label">Birth Month</option>
                    {months.map((mon, index) => (
                      <option key={index} value={index + 1}>
                        {mon}
                      </option>
                    ))}
                  </select>
                  <p className="invalid-feedback">
                    {formData.validation.month.error}
                  </p>
                </div>
                <div className="col-4">
                  <label className="form-label">
                    {t(selectedTranslation.labels.year)}
                  </label>
                  <select
                    className={`form-select ${
                      !formData.validation.year.isValid ? "is-invalid" : ""
                    }`}
                    id="year"
                    name="year"
                    value={formData.year}
                    onChange={handleYearChange}
                  >
                    <option className="form-label">Birth Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <p className="invalid-feedback">
                    {formData.validation.year.error}
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label className="form-label">
                    {t(selectedTranslation.labels.hours)}
                  </label>
                  <select
                    className={`form-select ${
                      !formData.validation.hour.isValid ? "is-invalid" : ""
                    }`}
                    id="hour"
                    name="hour"
                    value={formData.hour}
                    onChange={handleHoursChange}
                  >
                    <option value="">Enter Hours</option>
                    {hours.map((h) => (
                      <option key={h} value={h}>
                        {h}
                      </option>
                    ))}
                  </select>
                  <p className="invalid-feedback text-danger">
                    {formData.validation.hour.error}
                  </p>
                </div>
                <div className="col-4">
                  <label className="form-label">
                    {t(selectedTranslation.labels.minutes)}
                  </label>
                  <select
                    className={`form-select ${
                      !formData.validation.minute.isValid ? "is-invalid" : ""
                    }`}
                    id="minutes"
                    name="minutes"
                    value={formData.minute}
                    onChange={handleMinutesChange}
                  >
                    <option value="">Enter Minutes</option>
                    {minutes.map((m, index) => (
                      <option key={index} value={index + 1}>
                        {m}
                      </option>
                    ))}
                  </select>
                  <p className="invalid-feedback text-danger">
                    {formData.validation.minute.error}
                  </p>
                </div>
                <div className="col-4">
                  <label className="form-label">
                    {t(selectedTranslation.labels.timeperiod)}
                  </label>
                  <select
                    className={`form-select ${
                      !formData.validation.timeperiod.isValid
                        ? "is-invalid"
                        : ""
                    }`}
                    id="timeperiod"
                    name="timeperiod"
                    value={formData.timeperiod}
                    onChange={handleAmorPmChange}
                  >
                    <option value="">AM/PM</option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                  <p className="invalid-feedback text-danger">
                    {formData.validation.timeperiod.error}
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12">
                  <label>{t(selectedTranslation.labels.place)}</label>
                  <input
                    className={`form-control ${
                      !formData.validation.place.isValid ? "is-invalid" : ""
                    }`}
                    ref={ref}
                    type="text"
                    name="place"
                    defaultValue={formData.place}
                    placeholder="Enter Location"
                  />

                  <p className="invalid-feedback text-danger">
                    {formData.validation.place.error}
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                {/* <div className="col-6">
                  <label className="form-label">
                    {t(selectedTranslation.labels.country)}
                  </label>
                  <select
                    className="form-select"
                    value={formData.country}
                    onChange={handleCountryChange}
                  >
                    <option>India</option>
                    <option>United States Of America</option>
                    <option>United Kingdom</option>
                    <option>Canada</option>
                    <option>Others</option>
                  </select>
                </div> */}
                <div className="col-6">
                  <label className="form-label">
                    {t(selectedTranslation.labels.language)}
                  </label>
                  {l && (
                    <Select
                      id="language"
                      name="language"
                      options={options}
                      value={selectedOption}
                      defaultValue={options.find(
                        (option) => option.value === formData.language
                      )}
                      onChange={handleChange}
                    />
                  )}
                  <p className="invalid-feedback text-danger">
                    {formData.validation.language.error}
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-6">
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="formCheck1"
                      onChange={() => setAgreeToTerms(!agreeToTerms)}
                      required
                    />
                    <label className="form-check-label" htmlFor="formCheck1">
                      <Link
                        to="/termsandconditions"
                        className="fw-bolder text-decoration-none"
                      >
                        {t(selectedTranslation.labels.terms)}
                      </Link>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row text-center mb-3 d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn checkout-btn mt-3 p-2 w-25"
                  style={{
                    backgroundColor: "tomato",
                    color: "white",
                  }}
                >
                  {loading
                    ? "PROCESSING..."
                    : t(selectedTranslation.labels.checkout)}
                </button>
              </div>
              <div className="row mb-3 text-center">
                <div className="col-12 fs-5">
                  {t(selectedTranslation.labels.detail10)}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="container-fluid text-center mt-4">
        <div className="row gx-3 justify-content-center">
          <div className="col-md-4 col-sm-6 col-12 custom-col">
            <p className="fs-5"> {t(selectedTranslation.labels.heading7)}</p>
            <p> {t(selectedTranslation.labels.detail7)}</p>
          </div>
          <div className="col-md-4 col-sm-6 col-12 custom-col">
            <p className="fs-5"> {t(selectedTranslation.labels.heading8)}</p>
            <p>{t(selectedTranslation.labels.detail8)}</p>
          </div>
          <div className="col-md-4 col-sm-6 col-12 custom-col">
            <p className="fs-5">{t(selectedTranslation.labels.heading9)}</p>
            <p>{t(selectedTranslation.labels.detail9)}</p>
          </div>
        </div>
      </div>
      {transactionSuccess && downloadLinkVisible && (
        <div className="overlay">
          <div className="modal-content">
            <div className="container text-center">
              <h4 style={{ color: "white" }}>
                {t(selectedTranslation.labels.paymentsuccess)}
              </h4>
              <a
                href={pdfUrl}
                download="horoscope.pdf"
                className="btn btn-success"
              >
                {t(selectedTranslation.labels.download)}
              </a>
            </div>
          </div>
        </div>
      )}
      {transactionError && (
        <div className="overlay">
          <div className="modal-content">
            <div className="container text-center">
              <h4 style={{ color: "white" }}>
                {t(selectedTranslation.labels.paymentfail)}
              </h4>
              <button
                className="btn btn-danger"
                onClick={() => setTransactionError(false)}
              >
                {t(selectedTranslation.labels.back)}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderNowForm;
