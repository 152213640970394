import React, { useEffect, useContext } from "react";
import { Link, useLocation,useParams } from "react-router-dom";
import { allShopItemsContext } from "../BharatWebShopContext/ShopTodaysDeals";
import Helmetcomponent from "../Helmet/helmetcomponent";

const CategoryItems = () => {
  const categoryItems = useLocation().state;
  console.log(categoryItems[0].category);
  const { loading, error } = useContext(allShopItemsContext);
  const  id =useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!categoryItems || categoryItems.length === 0) {
    return (
      <div>
        <h1>No items available for this category.</h1>
      </div>
    );
  }

  const getImageUrl = (imagePath) => {
    const baseUrl = "https://shop.bharatcalendars.in";
    const normalizedPath = imagePath.replace(/^(\.\.\/)+/, "");
    return `${baseUrl}/${normalizedPath}`;
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        marginTop: "120px",
        textAlign: "center",
        fontWeight: "bolder",
        cursor: "pointer",
      }}
      className="container"
    >
      <Helmetcomponent index={"SpecificCategory"} path={id.categoryId} data={categoryItems[0].category}/>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        {categoryItems.map((item) => (
          <div
            className="card shadow rounded me-2 p-3"
            key={item._id}
            style={{
              width: "200px",
              height: "270px",
              margin: "15px",
              marginLeft: "25px",
              padding: "9px",
            }}
          >
            <div className="card-img-top">
              {
                <Link to={`/item/${item._id}`}>
                  <img
                    src={getImageUrl(item.imageGallery[0])}
                    alt={item.itemName}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      margin: "5px",
                    }}
                  />
                </Link>
              }
            </div>
            <div className="card-body">
              <p>{item.itemName.slice(0, 20)}...</p>
              <p className="m-2">
                ₹{item.discPrice}{" "}
                <span className="text-secondary text-decoration-line-through">
                  ₹{item.price}
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryItems;
