import React from "react";
import parse from "html-react-parser";

import useKundliFetch from "../../../Hooks/useKundliFetch";
import { useTranslation } from "react-i18next";

const KalaSarpa = () => {
  const { t } = useTranslation();
  const url = "https://json.astrologyapi.com/v1/kalsarpa_details";
  const { data: kalaSarpa } = useKundliFetch(url);

  return (
    <>
      {kalaSarpa.length !== 0 && (
        <div>
          <div className="text-center details-header">{t("Kala Sarpa")}</div>
          <div className="details-body kundli-gradient-2 shadow-lg">
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("Is Kala Sarpa Dosha Present?")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {kalaSarpa?.present === true ? t("yes") : t("no")}
              </span>
            </div>
            {kalaSarpa?.type && (
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                  {t("Type")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {kalaSarpa?.type}
                </span>
              </div>
            )}
            <div className="pb-2 pb-lg-4">
              <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                {t("Analysis")}
              </h5>
              <span className="montserrat-font fw-semibold">
                {kalaSarpa?.one_line}
              </span>
            </div>
          </div>
          {kalaSarpa?.present === true && (
            <div>
              <div className="text-center details-header">{t("Details")}</div>
              <div className="details-body kundli-gradient-3 shadow-lg">
                <div className="pb-2 pb-lg-4">
                  <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                    {t("Name")}
                  </h5>
                  <span className="montserrat-font fw-semibold">
                    {kalaSarpa?.name}
                  </span>
                </div>
                <div className="pb-2 pb-lg-4">
                  <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                    {t("House Id")}
                  </h5>
                  <span className="montserrat-font fw-semibold">
                    {kalaSarpa?.report?.house_id}
                  </span>
                </div>
                <div className="pb-2 pb-lg-4">
                  <h5 className="montserrat-font text-white mb-0 mb-lg-2">
                    {t("Report")}
                  </h5>
                  <span className="montserrat-font fw-semibold">
                    {parse(kalaSarpa?.report?.report)}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default KalaSarpa;
