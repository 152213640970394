import React from "react";
import { useTranslation } from "react-i18next";
import GoogleAdSense from "../../GoogleAdSense/GoogleAdSense";

const HoroscopeHeading = () => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ marginBottom: "2vw", width: "100%" }}>
        <h1 className="route-heading">{t("Horoscope")}</h1>
        <p className="montserrat-font" style={{ textAlign: "justify" }}>
          {t("Horoscope Heading")}
        </p>

        <div style={{ margin: "25px" }}>
          <GoogleAdSense />
        </div>
      </div>
    </>
  );
};

export default HoroscopeHeading;
