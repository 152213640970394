import React from "react";
import { useTranslation } from "react-i18next";
import useHoroscopeFetch from "../../Hooks/useHoroscopeFetch";
import HoroscopeDetails from "./HoroscopeDetails";
import HoroscopeButtons from "./HoroscopeButtons";

const Monthly = () => {
  const rashi = localStorage.getItem("rashi");
  const getShortMonth = new Date().toLocaleString("default", {
    month: "short",
  });
  const getShortYear = new Date().toLocaleString("default", {
    year: "2-digit",
  });
  const getFullMonth = new Date().toLocaleString("default", { month: "long" });
  const getFullYear = new Date().toLocaleString("default", { year: "numeric" });
  const month = `${getShortMonth}-${getShortYear}`;
  const { t } = useTranslation();

  const { data, refetch } = useHoroscopeFetch(month);

  const handleRashi = (rashi) => {
    localStorage.setItem("rashi", rashi);
    refetch();
  };

  return (
    <div>
      <HoroscopeButtons handleRashi={handleRashi} />

      <h5 className="montserrat-font mt-3 mt-lg-5">
        {t("Monthly Horoscope")} : {getFullMonth} {getFullYear}
      </h5>
      {data && <HoroscopeDetails data={data} rashi={rashi} />}
    </div>
  );
};

export default Monthly;
