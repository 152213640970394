import React, { useState } from "react";
import ManglikDosh from "./ManglikDosh";
import KalaSarpa from "./KalaSarpa";
import SadhesatiCurrentStatus from "./SadhesatiCurrentStatus";
import SadhesatiLifeDetails from "./SadhesatiLifeDetails";
import PitraDosha from "./PitraDosha";
import KundliInput from "../KundliInput";
import BasicDetails from "../BasicDetails";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./KalaSarpaDosha.scss";

import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { useContext } from "react";
import { KundliContext } from "../Kundli";
import { useTranslation } from "react-i18next";

const KalaSarpaDosha = () => {
  const { userInfo } = useContext(KundliContext);
  const { t } = useTranslation();

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <FaArrowCircleLeft
        className={className}
        style={{
          ...style,
          color: "#483f50",
          height: "23px",
          width: "23px",
        }}
        onClick={onClick}
      />
    );
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <FaArrowCircleRight
        className={className}
        style={{ ...style, color: "#483f50", height: "23px", width: "23px" }}
        onClick={onClick}
      />
    );
  };
  const settings = {
    infinite: true,

    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  const [buttonName, setButtonName] = useState("kalaSarpa");
  const [kalaSarpaClicked, setKalaSarpaClicked] = useState(true);
  const [manglikDoshClicked, setManglikDoshClicked] = useState(false);
  const [sadhesatiCurrentStatusClicked, setSadhesatiCurrentStatusClicked] =
    useState(false);
  const [sadhesatiLifeDetailsClicked, setSadhesatiLifeDetailsClicked] =
    useState(false);
  const [pitraDoshaClicked, setPitraDoshaClicked] = useState(false);

  const handleButtons = (setButtonClicked, buttonName) => {
    setKalaSarpaClicked(false);
    setManglikDoshClicked(false);
    setSadhesatiCurrentStatusClicked(false);
    setSadhesatiLifeDetailsClicked(false);
    setPitraDoshaClicked(false);
    setButtonClicked(true);
    setButtonName(buttonName);
  };
  return (
    <div>
      <KundliInput />
      {userInfo && Object.keys(userInfo).length !== 0 && (
        <>
          <BasicDetails />
          <Slider {...settings}>
            <button
              onClick={() => handleButtons(setKalaSarpaClicked, "kalaSarpa")}
              type="button"
              className={`kalaSarpa-button w-100 ${
                buttonName === "kalaSarpa"
                  ? "kalaSarpa-clicked"
                  : "kalaSarpa-not-clicked"
              }`}
            >
              <small>{t("Kala Sarpa")}</small>
            </button>
            <button
              onClick={() =>
                handleButtons(setManglikDoshClicked, "manglikDosh")
              }
              type="button"
              className={`kalaSarpa-button w-100 ${
                buttonName === "manglikDosh"
                  ? "kalaSarpa-clicked"
                  : "kalaSarpa-not-clicked"
              }`}
            >
              <small>{t("Manglik Dosh")}</small>
            </button>
            <button
              onClick={() =>
                handleButtons(
                  setSadhesatiCurrentStatusClicked,
                  "sadhesatiCurrentStatus"
                )
              }
              type="button"
              className={`kalaSarpa-button w-100 ${
                buttonName === "sadhesatiCurrentStatus"
                  ? "kalaSarpa-clicked"
                  : "kalaSarpa-not-clicked"
              }`}
            >
              <small>{t("Sadhesati Current Status")}</small>
            </button>
            <button
              onClick={() =>
                handleButtons(
                  setSadhesatiLifeDetailsClicked,
                  "sadhesatiLifeDetails"
                )
              }
              type="button"
              className={`kalaSarpa-button w-100 ${
                buttonName === "sadhesatiLifeDetails"
                  ? "kalaSarpa-clicked"
                  : "kalaSarpa-not-clicked"
              }`}
            >
              <small>{t("Sadhesati Life Details")}</small>
            </button>
            <button
              onClick={() => handleButtons(setPitraDoshaClicked, "pitraDosha")}
              type="button"
              className={`kalaSarpa-button w-100 ${
                buttonName === "pitraDosha"
                  ? "kalaSarpa-clicked"
                  : "kalaSarpa-not-clicked"
              }`}
            >
              <small>{t("Pitra Dosha")}</small>
            </button>
          </Slider>
          <div className="pt-3 pt-lg-5">
            {kalaSarpaClicked && <KalaSarpa />}
            {manglikDoshClicked && <ManglikDosh />}
            {sadhesatiCurrentStatusClicked && <SadhesatiCurrentStatus />}
            {sadhesatiLifeDetailsClicked && <SadhesatiLifeDetails />}
            {pitraDoshaClicked && <PitraDosha />}
          </div>
        </>
      )}
    </div>
  );
};

export default KalaSarpaDosha;
