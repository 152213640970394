import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

const apiBaseUrl = "https://api.exaweb.in:5200";

export const allShopItemsContext = createContext();

function ShopApiContext({ children }) {
  const [shopData, setShopData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const lang = localStorage.getItem("lang");
  // console.log(lang);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/items?lang=${lang}`);
        // console.log(response.data);
        setShopData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/category`);
        setCategories(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
    fetchCategories();
  }, [lang]);

  const fetchTodayDealsData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/items/todayDeals`);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching today's deals: ${error.message}`);
    }
  };

  const fetchNewlyAddedItemsData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/items/newly-added-items`);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching newly added items: ${error.message}`);
    }
  };

  const fetchPopularItemsData = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/items/popular-items`);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching popular items: ${error.message}`);
    }
  };

  const getItemDetails = async (itemId) => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/items/${itemId}?lang=${lang}`
      );
      const itemDetails = response.data;
      // console.log(`${apiBaseUrl}/items/${itemId}?`);

      // Update itemDetails with fully qualified image URLs

      // const fullyQualifiedImageGallery = itemDetails.imageGallery.map(
      //   (imageName) =>
      //     `${apiBaseUrl}/Assets/img/items/${itemDetails.itemName}/${imageName}`
      // );

      // Update itemDetails with fully qualified image URLs
      // itemDetails.imageGallery = fullyQualifiedImageGallery;

      return itemDetails;
    } catch (error) {
      throw new Error(
        `Error fetching item details for item ID ${itemId}: ${error.message}`
      );
    }
  };

  return (
    <allShopItemsContext.Provider
      value={{
        shopData,
        categories,
        loading,
        error,
        fetchTodayDealsData,
        fetchNewlyAddedItemsData,
        fetchPopularItemsData,
        getItemDetails,
      }}
    >
      {children}
    </allShopItemsContext.Provider>
  );
}

export default ShopApiContext;
