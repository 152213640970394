import React from "react";
import "./KundaliShopStyles.scss";
import logo from "../../img/logo/CalenderIcon.jpg";
import kundaliBook from "../../img/kundalinipdf.png";
import { LuIndianRupee } from "react-icons/lu";
import customercare from "../../img/customercare.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Translations from "../Translations/Translations.json";
import { useTranslation } from "react-i18next";
import Helmetcomponent from "../Helmet/helmetcomponent";

function KundaliBuyIndex() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const storedLanguage = localStorage.getItem("lang") || "en";
  const selectedTranslation = Translations[storedLanguage] || Translations.en;

  return (
    <div className="container px-3">
      <Helmetcomponent index={"KundliBuy"} path={""} data={""} />
      <div className="mt-3 mb-4 kundalishop-section">
        <div className="row gx-5">
          <div className="shadow col-md-9">
            <div className="mt-3 mb-2 heading-div">
              <h1 className="fs-3">
                {t(selectedTranslation.labels.title)}
              </h1>
            </div>
            <div className="mt-4 mb-3 row product-section">
              <div className="text-center d-flex flex-column align-items-center justify-content-start col-md-4 col-12">
                <div className="product-image">
                  <img
                    src={kundaliBook}
                    alt="Kundali Book - Beautiful colored Kundli with more than 25 pages describing life in detail."
                    width="180px"
                    height="220px"
                  />
                </div>
                <div
                  className="p-3 mt-3 btn order-btn w-50"
                  style={{ backgroundColor: "blue", color: "white" }}
                  onClick={() => navigate("/checkout")}
                >
                  {t(selectedTranslation.labels.order)}
                </div>
              </div>
              <div className="col-md-8 col-12">
                <ul className="product-details-list">
                  <li className="mb-2 detail text-dark fs-5 d-flex align-items-start justify-content-start">
                    <img src={logo} alt="bullet" width="30px" height="30px" className="me-2" />
                    <div>
                      <strong>{t(selectedTranslation.labels.heading1)}</strong>
                      <p className="mb-0 text-wrap">
                        {t(selectedTranslation.labels.detail1)}
                      </p>
                    </div>
                  </li>
                  <li className="mb-2 detail text-dark fs-5 d-flex align-items-start justify-content-start">
                    <img src={logo} alt="bullet" width="30px" height="30px" className="me-2" />
                    <div>
                      <strong>{t(selectedTranslation.labels.heading2)}</strong>
                      <p className="mb-0 text-wrap">
                        {t(selectedTranslation.labels.detail2)}
                      </p>
                    </div>
                  </li>
                  <li className="mb-2 detail text-dark fs-5 d-flex align-items-start justify-content-start">
                    <img src={logo} alt="bullet" width="30px" height="30px" className="me-2" />
                    <div>
                      <strong>{t(selectedTranslation.labels.heading3)}</strong>
                      <p className="mb-0 text-wrap">
                        {t(selectedTranslation.labels.detail3)}
                      </p>
                    </div>
                  </li>
                  <li className="mb-2 detail text-dark fs-5 d-flex align-items-start justify-content-start">
                    <img src={logo} alt="bullet" width="30px" height="30px" className="me-2" />
                    <div>
                      <strong>{t(selectedTranslation.labels.heading4)}</strong>
                      <p className="mb-0 text-wrap">
                        {t(selectedTranslation.labels.detail4)}
                      </p>
                    </div>
                  </li>
                  <li className="mb-2 detail text-dark fs-5 d-flex align-items-start justify-content-start">
                    <img src={logo} alt="bullet" width="30px" height="30px" className="me-2" />
                    <div>
                      <strong>{t(selectedTranslation.labels.heading5)}</strong>
                      <p className="mb-0 text-wrap">
                        {t(selectedTranslation.labels.detail5)}
                      </p>
                    </div>
                  </li>
                  <li className="mb-2 detail text-dark fs-5 d-flex align-items-start justify-content-start">
                    <img src={logo} alt="bullet" width="30px" height="30px" className="me-2" />
                    <div>
                      <strong>{t(selectedTranslation.labels.heading6)}</strong>
                      <p className="mb-0 text-wrap">
                        {t(selectedTranslation.labels.detail6)}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="text-center shadow col-md-3 col-12"
            style={{ backgroundColor: "wheat", color: "brown" }}
          >
            <div className="mt-4">
            <div className="m-2 actual-price fs-3 d-flex align-items-center justify-content-center">
              <div>
              <strong> {t(selectedTranslation.labels.price)}:</strong>{" "}
              </div>
              <div>
              <span className="text d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center ">
                <LuIndianRupee /> <span className="text-decoration-line-through">599</span>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                <LuIndianRupee /> <span>99</span>
                </div>
              </span>
              </div>
            </div>
            <div className="discount-offer">
              <p className="text-danger fs-3">
                {" "}
                {t(selectedTranslation.labels.save)} : ₹ 500 (83.47%)
              </p>
              <span> {t(selectedTranslation.labels.inclusive)}</span>
            </div>
            <div
              className="p-2 mt-3 btn order-btn w-50"
              style={{ backgroundColor: "blue", color: "white" }}
              onClick={() => navigate("/checkout")}
            >
              {t(selectedTranslation.labels.order)}
            </div>
            <p className="mt-2"> {t(selectedTranslation.labels.delivery)}</p>
            <hr className="mt-3 mb-3 text-warning" />
            <div className="customer-care d-flex flex-column align-items-center justify-content-center">
              <img
                src={customercare}
                alt="customercare"
                width="80px"
                height="80px"
                style={{ borderRadius: "100%" }}
              />
              <p className="care-heading fs-3">
                {" "}
                {t(selectedTranslation.labels.customer)}
              </p>
              <div className="contact-information">
                <b className="fs-4">+91 8139975010</b>
                <p>(9AM to 6PM IST)</p>
              </div>
              <div className="mt-2 customer-care-email">
                customercare@bharatcalendars.com
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KundaliBuyIndex;
