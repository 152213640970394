import React, { useContext, useEffect, useState } from "react";
import { allShopItemsContext } from "../BharatWebShopContext/ShopTodaysDeals";
import { useParams } from "react-router-dom";
import { IoIosArrowDropright } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import Helmetcomponent from "../Helmet/helmetcomponent";
//import "./ShopItemDetails.scss";

function ItemDetails() {
  const { getItemDetails } = useContext(allShopItemsContext);
  const [ItemDetails, setItemDetails] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1");
  const { itemId } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        const details = await getItemDetails(itemId);
        setItemDetails(details);
        if (details.imageGallery && details.imageGallery.length > 0) {
          setSelectedImage(details.imageGallery[0]);
        }
      } catch (error) {
        console.error("Error fetching item details:", error);
      }
    };
    fetchItemDetails();
  }, [getItemDetails, itemId]);

  if (!ItemDetails) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return <div>Loading Item Details...</div>;
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getImageUrl = (imagePath) => {
    // Assuming the base URL is constant
    // const baseUrl = "https://shop.exaweb.in:5200";
    const baseUrl = "https://api.bharatcalendars.in";
    // If the imagePath starts with "../../", remove it
    const normalizedPath = imagePath.replace(/^(\.\.\/)+/, "");

    // Concatenate the base URL with the normalized image path
    //console.log(`${baseUrl}/${normalizedPath}`);
    return `${baseUrl}/${normalizedPath}`;
  };

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  console.log("ItemDetails:", ItemDetails.itemName);
  console.log("ItemDetails:", ItemDetails._id);

  return (
    <div className="product-details-container" style={{ marginTop: "89px" }}>
      <Helmetcomponent index={"SpecificItem"} path={ItemDetails._id} data={ItemDetails.itemName}/>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          {/* <img
            src={getImageUrl(ItemDetails.imageGallery[0])}
            alt="itemdetails"
            width="100%"
            height="590px"
            style={{
              border: "2px solid gray",
              borderRadius: "4px",
              padding: "5px",
            }}
          /> */}
          <div className="large-image-container">
            <img
              src={getImageUrl(selectedImage)}
              alt="Selected Item"
              className="large-image"
            />
          </div>
          <div className="thumbnail-container">
            {ItemDetails.imageGallery.map((image, index) => (
              <img
                key={index}
                src={getImageUrl(image)}
                alt={`Thumbnail ${index + 1}`}
                className={`thumbnail-image ${
                  selectedImage === image ? "selected" : ""
                }`}
                onClick={() => handleThumbnailClick(image)}
              />
            ))}
          </div>
        </div>
        <div className="col-lg-6">
          <h1>{ItemDetails.itemName}</h1>
          <div className="py-3" style={{ fontSize: "25px" }}>
            Price:
            <span className="d-inline d-md-none">
              <br />
            </span>
            <span>
              <FaRupeeSign />
              {ItemDetails.discPrice}
            </span>
            <span className="text text-decoration-line-through text-secondary">
              <FaRupeeSign />
              {ItemDetails.price}
            </span>
            Inclusive of all taxes
          </div>

          <div
            className="d-flex py-2 gap-3"
            style={{ fontSize: "17px", fontWeight: "bolder" }}
          >
            <p className="text-danger">
              <IoIosArrowDropright />
            </p>
            <p>{ItemDetails.note}</p>
          </div>
          <div
            className="d-flex gap-3"
            style={{ fontSize: "17px", fontWeight: "bolder" }}
          >
            <p className="text-danger">
              <IoIosArrowDropright />
            </p>
            <p>Cash on Delivery Available (+ ₹50 Shipping Charges Extra)</p>
          </div>
          <div
            className="d-flex gap-3"
            style={{ fontSize: "17px", fontWeight: "bolder" }}
          >
            <p className="text-danger">
              <IoIosArrowDropright />
            </p>
            <p>Delivery : Sun Nov 26 2023 - Tue Nov 28 2023</p>
          </div>
          <div className="d-flex mt-2 align-items-center">
            <p className="fs-4 me-3 mb-0">Share this product:</p>

            <div className="d-flex align-items-center gap-2">
              <button className="mx-2 fs-3 text-primary">
                <FaFacebook />
              </button>
              <button className="mx-2 fs-3 text-success">
                <FaWhatsapp />
              </button>
              <button className="mx-2 fs-3 text-primary">
                <FaTelegram />
              </button>
              <button className="mx-2 fs-3 text-primary">
                <FaTwitter />
              </button>
            </div>
          </div>
          <div>
            <div className="d-flex fs-5 my-3">
              Availabilty:
              <span>
                {ItemDetails.availability ? (
                  <p className="text-success">In Stock</p>
                ) : (
                  <p className="text-danger">Out Of Stock</p>
                )}
              </span>
            </div>
            <div className="d-flex fs-5 my-3">
              Payment Method:
              <p className="fw-bold">
                Cash on Delivery / Debit / Credit / ATM Card / Net Banking /
                Other UPI Apps
              </p>
            </div>
            <div className="d-md-none mt-2">
              <button className="action-button-buy mb-2">BUY NOW</button>
              <button className="action-button-add">ADD TO CART</button>
            </div>

            <div className="d-none d-md-block">
              <div className="action-buttons">
                <button className="action-button-buy me-2">BUY NOW</button>
                <button className="action-button-add">ADD TO CART</button>
              </div>
            </div>

            <div className="col-12">
              <ul className="nav nav-tabs mt-4">
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "tab1" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("tab1")}
                  >
                    {ItemDetails.tab1}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "tab2" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("tab2")}
                  >
                    {ItemDetails.tab2}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      activeTab === "tab3" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("tab3")}
                  >
                    {ItemDetails.tab3}
                  </button>
                </li>
              </ul>
              <div className="tab-content mt-2">
                <div
                  className={`tab-pane fade ${
                    activeTab === "tab1" ? "show active" : ""
                  }`}
                >
                  {ItemDetails.tab1 && <p>{ItemDetails.description}</p>}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "tab2" ? "show active" : ""
                  }`}
                >
                  {ItemDetails.tab2 && <p>{ItemDetails.about}</p>}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "tab3" ? "show active" : ""
                  }`}
                >
                  {ItemDetails.tab3 && (
                    <ul>
                      {ItemDetails.more.map((info, index) => (
                        <li key={index}>{info}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetails;
