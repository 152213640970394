import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../../Components/Card/card";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
const PopularEvents = ({ popularEvents }) => {
  const { t } = useTranslation();
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <MdKeyboardArrowLeft
        className={className}
        style={{
          ...style,
          zIndex: 50,
          color: "#483f50",
          height: "4.026vw",
          width: "4.026vw",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <MdKeyboardArrowRight
        className={className}
        style={{
          ...style,
          color: "#483f50",
          height: "4.026vw",
          width: "4.026vw",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default to 3 slides
    slidesToScroll: 1,
    initialSlide: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ],
  };

  const slideStyle = { padding: "0 10px" };
  return (
    <div>
      <h1 className="events-section-header">{t("Popular Events")}</h1>
      <div className="events-div" style={{ background: "#CBCBCB" }}>
        {popularEvents?.length > 0 ? (
          <Slider {...settings} style={slideStyle}>
            {popularEvents?.map((event, index) => (
              <Card key={index} event={event} index={index} />
            ))}
          </Slider>
        ) : (
          <span className="text-center events-section-header">
            There is no Popular Event Right Now
          </span>
        )}
      </div>
    </div>
  );
};

export default PopularEvents;
