import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";

const Festivals = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <div>
      <div
        className="text-center details-header"
        style={{ fontSize: "2.85vw" }}
      >
        {t("Day Festivals and Events")}
      </div>
      <div className="details-body panchang-gradient-6">
        <div className="row mx-auto w-50">
          {panchangDetails?.festivals ? (
            panchangDetails?.festivals?.map((festival, index) => (
              <p
                key={index}
                className={`montserrat-font fw-semibold text-center ${
                  panchangDetails.festivals.length === 1 ? "col-12" : "col-6"
                }`}
              >
                {festival}
              </p>
            ))
          ) : (
            <p
              style={{ fontSize: "13px" }}
              className="montserrat-font fw-semibold text-center"
            >
              None
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Festivals;
