import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import Moonrise from "../../img/icons/moonrise.svg";
import Moonset from "../../img/icons/moonset.svg";
import Sunrise from "../../img/icons/sunrise.svg";
import Sunset from "../../img/icons/sunset.svg";
import Pournimanta from "../../img/icons/pournimanta.svg";
import Tithi from "../../img/icons/tithi.svg";
import Paksha from "../../img/icons/paksha.svg";
import Vikramsamvat from "../../img/icons/vikram.svg";
import translations from "../../Components/Translations/Translations";



const Homepanchang = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const date = moment(new Date()).format("DD/MM/YY");
  const app_language = "en";
  const data_language = i18n.language;
  const storedLanguage = localStorage.getItem("lang") || "en";
  const selectedTranslation = translations[storedLanguage] || translations.en;
  console.log(date);

  const url = `https://api.exaweb.in:5000/panchang/daily?date=${date}&app_language=${app_language}&data_language=${data_language}`;

  const { data: panchangDetails, isLoading } = useQuery(
    [`panchangDetails`, date, app_language, data_language],
    () =>
      axios.get(url).then((data) => {
        return data.data;
      })
  );

  const handleTruncateText = (text, limit) => {
    return text.length > limit ? `${text.substring(0, limit)}...` : text;
  };

  const panchang_paksha=localStorage.getItem("lang") === 'te' ? panchangDetails?.Pakshamulu : panchangDetails?.Paksha || "None";
  const panchang_tithi=localStorage.getItem("lang") === 'te' ? panchangDetails?.Tithulu?.[1] : panchangDetails?.Tithi?.[1] || "None";

  return (
    <div className=" bg-[#353A74] flex flex-col items-center justify-center" style={{ height: "515px" }}>

      {isLoading ? (
        <Loading />
      ) : (
        <div>
        <div className="md:hidden">
        <div className="flex items-center justify-center mb-4">
            <h2 className="text-lg font-bold text-white">
            {t(selectedTranslation.labels.todays)} {t("Panchang")}, <span className="text-lg font-normal">{moment().format("DD")} {t(selectedTranslation.labels[moment().format("MMMM")])}, <span>
                {storedLanguage === "en"
                  ? moment().format("dddd")
                  : t(selectedTranslation.labels[moment().format("dddd").toLowerCase()])}
              </span></span>
            </h2>
          </div>
          <div className="p-3 bg-white shadow-md rounded-xl " >
            <div className="grid grid-cols-2 gap-2" >
              <div className="flex flex-row items-center gap-3 text-xs">
                <img src={Moonrise} alt={t("Moonrise")} className="w-10 h-10" />
                <div className="text-left">
                  <p className="mt-3">
                    <span className="font-semibold">{panchangDetails?.Moonrise || "None"}</span><br />
                    <span className="text-sm">{t("Moonrise")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-3">
                <img src={Moonset} alt={t("Moonset")} className="w-10 h-10" />
                <div className="text-left">
                  <p className="mt-3 text-sm ">
                    <span className="font-semibold">{panchangDetails?.Moonset || "None"}</span><br />
                    <span className="font-sm font">{t("Moonset")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-3">
                <img src={Sunrise} alt={t("Sunrise")} className="w-10 h-10" />
                <div className="text-left">
                  <p className="mt-3 text-sm ">
                    <span className="font-semibold">{panchangDetails?.Sunrise || "None"}</span><br />
                    <span className="font-sm">{t("Sunrise")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-3">
                <img src={Sunset} alt={t("Sunset")} className="w-10 h-10" />
                <div className="text-left">
                  <p className="mt-3 text-sm ">
                    <span className="font-semibold">{panchangDetails?.Sunset || "None"}</span><br />
                    <span className="font-sm">{t("Sunset")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-3">
                <img src={Pournimanta} alt={t("Pournimanta")} className="w-10 h-10" />
                <div className="mt-2 text-left">
                  <p className="text-sm">
                    <span className="font-semibold">{panchangDetails?.Chandramasa?.[0]?.split("-")[0] || "None"}</span><br />
                    <span className="font-sm">{t("Purnimanta")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-3">
                <img src={Tithi} alt={t("Tithi")} className="w-10 h-10" />
                <div className="mt-2 text-left">
                  <p className="text-sm">
                    <span className="font-semibold">{panchang_tithi}</span><br />
                    <span className="font-sm">{t(selectedTranslation.labels.tithi)}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-3">
                <img src={Paksha} alt={t("Paksha")} className="w-10 h-10" />
                <div className="mt-2 text-left">
                  <p className="text-sm">
                    <span className="font-semibold">{panchang_paksha}</span><br />
                    <span className="font-sm">{t("Paksha")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-3">
                <img src={Vikramsamvat} alt={t("Vikram Samvat")} className="w-10 h-10" />
                <div className="mt-2 text-left ">
                  <p className="text-sm">
                    <span className="font-semibold">
                      {panchangDetails?.["Vikram Samvat"]
                        ? panchangDetails?.["Vikram Samvat"].map((data, index) => (
                          <span key={index}>{data}</span>
                        ))
                        : "None"}
                    </span>
                    <br />
                    <span className="font-sm">{t("Vikram Samvat")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              className="block px-6 py-2 mx-auto mt-6 text-white bg-[#FF695F] rounded-full font-medium text-sm"
              onClick={() => navigate("/panchang")}
            >
              {handleTruncateText(selectedTranslation.labels.see,localStorage.getItem("lang") === 'en' ? 16 : 10)}
            </button>
          </div>
        </div>
        
        <div className="hidden md:block">
          <div className="mb-4">
            <h2 className="text-xl font-bold text-white">
            {t(selectedTranslation.labels.todays)} {t("Panchang")}, <span className="text-lg font-normal">{moment().format("DD")} {t(selectedTranslation.labels[moment().format("MMMM")])}, <span>
                {storedLanguage === "en"
                  ? moment().format("dddd")
                  : t(selectedTranslation.labels[moment().format("dddd").toLowerCase()])}
              </span></span>
            </h2>
          </div>
          <div className="p-10 bg-white shadow-md rounded-xl w-fit" >
            <div className="grid grid-cols-4 gap-16" style={{ marginLeft: "25px" }}>
              <div className="flex flex-row items-center gap-4">
                <img src={Moonrise} alt={t("Moonrise")} className="w-16 h-16" />
                <div className="text-left">
                  <p className="mt-3">
                    <span className="font-semibold">{panchangDetails?.Moonrise || "None"}</span><br />
                    <span className="text-sm">{t("Moonrise")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <img src={Moonset} alt={t("Moonset")} className="w-16 h-16" />
                <div className="text-left">
                  <p className="mt-3 text-sm ">
                    <span className="font-semibold">{panchangDetails?.Moonset || "None"}</span><br />
                    <span className="font-sm font">{t("Moonset")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <img src={Sunrise} alt={t("Sunrise")} className="w-16 h-16" />
                <div className="text-left">
                  <p className="mt-3 text-sm ">
                    <span className="font-semibold">{panchangDetails?.Sunrise || "None"}</span><br />
                    <span className="font-sm">{t("Sunrise")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <img src={Sunset} alt={t("Sunset")} className="w-16 h-16" />
                <div className="text-left">
                  <p className="mt-3 text-sm ">
                    <span className="font-semibold">{panchangDetails?.Sunset || "None"}</span><br />
                    <span className="font-sm">{t("Sunset")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <img src={Pournimanta} alt={t("Pournimanta")} className="w-16 h-16" />
                <div className="mt-2 text-left">
                  <p className="text-sm">
                    <span className="font-semibold">{panchangDetails?.Chandramasa?.[0]?.split("-")[0] || "None"}</span><br />
                    <span className="font-sm">{t("Purnimanta")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <img src={Tithi} alt={t("Tithi")} className="w-16 h-16" />
                <div className="mt-2 text-left">
                  <small className="text-sm font-semibold">{ }</small>
                  <p className="text-sm">
                    <span className="font-semibold">{panchang_tithi}</span><br />
                    <span className="font-sm">{t(selectedTranslation.labels.tithi)}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <img src={Paksha} alt={t("Paksha")} className="w-16 h-16" />
                <div className="mt-2 text-left">
                  <p className="text-sm">
                    <span className="font-semibold">{panchang_paksha}</span><br />
                    <span className="font-sm">{t("Paksha")}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <img src={Vikramsamvat} alt={t("Vikram Samvat")} className="w-16 h-16" />
                <div className="mt-2 text-left ">
                  <p className="text-sm">
                    <span className="font-semibold">
                      {panchangDetails?.["Vikram Samvat"]
                        ? panchangDetails?.["Vikram Samvat"].map((data, index) => (
                          <span key={index}>{data}</span>
                        ))
                        : "None"}
                    </span>
                    <br />
                    <span className="font-sm">{t("Vikram Samvat")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              className="block px-6 py-2 mx-auto mt-6 text-white bg-[#FF695F] rounded-full font-medium"
              onClick={() => navigate("/panchang")}
            >
              {t(selectedTranslation.labels.see)}
              {/* {handleTruncateText(selectedTranslation.labels.see,localStorage.getItem("lang") === 'en' ? 18 :12)} */}
            </button>
          </div>
        </div>
        </div>
      )}
    </div>
  );
};

export default Homepanchang;
