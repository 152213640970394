import { toast } from "react-toastify";
import httpService from "../../common/http.service";
import { fileAction } from "../slice/fileSlice";
// const regIMG = /\.(jpg|jpeg|png|webp|avif|gif|svg|bmp|apng|image|tif)$/;

export const uploadImageApi = (data) => async (dispatch) => {
  const form = new FormData();
  form.append("file", data);
  try {
    const response = await httpService.post("upload", {}, form);
    dispatch(fileAction.uploadImage(response.data));
    toast.success("Image Uploaded Successfully");
  } catch (err) {
    throw new Error(err);
  }
};

export const uploadFileApi = (data) => async (dispatch) => {
  const form = new FormData();
  form.append("file", data);
  try {
    const response = await httpService.post("upload", {}, form);
    dispatch(fileAction.uploadFile(response?.data?.files[0]));
    toast.success("File Uploaded Successfully");
  } catch (err) {
    throw new Error(err);
  }
};

export const uploadApi = (data) => async (dispatch) => {
  const form = new FormData();
  form.append("file", data);
  try {
    const response = await httpService.post("upload", {}, form);
    dispatch(fileAction.uploadProfile(response?.data?.files[0]));
    toast.success("Profile Picture Uploaded Successfully");
  } catch (err) {
    throw new Error(err);
  }
};

export const showImage = (state) => state?.file;
export const showFile = (state) => state?.file?.file;
