import { toast } from "react-toastify";
import httpService from "../../common/http.service";
import { authAction } from "../slice/authSlice";
import { usersAction } from "../slice/userSlice";

export const getSearchUserData = (data) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `user/find?name=${data.name}&language=${data.lang}`
    );
    if (response && response?.data) {
      dispatch(usersAction.getSearchUserSuccess(response?.data));
    }
  } catch (err) {
    dispatch(usersAction.getSearchUserFailed(err));
  }
};

export const getUserData =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = await httpService.get(
        `user?searchKey=_id&searchValue=${id}`
      );
      if (response && response?.data) {
        dispatch(usersAction.getUserDataSuccess(response?.data?.data?.data[0]));
      }
    } catch (err) {
      dispatch(usersAction.getUserDataFailed(err));
    }
  };

export const getUserList = (data) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `eventparticipant/${data.eventId}?user=${data.user}&status=${data.status}&userId=${data.userId}`
    );
    if (response && response?.data) {
      dispatch(usersAction.getUserListSuccess(response?.data));
    }
  } catch (err) {
    dispatch(usersAction.getUserListFailed(err));
  }
};

export const getFollowUser = (data) => async (dispatch) => {
  try {
    const response = await httpService.put("user/follow", {}, data);
    if (response && response?.data) {
      dispatch(usersAction.getFollowUserSuccess(response?.data));
      dispatch(
        authAction.addFollowingUser({ userId: response?.data?.followerId?._id })
      );
    }
  } catch (err) {
    dispatch(usersAction.getFollowUserFailed(err));
  }
};

export const getUserFollowing = (data) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `user/list?userId=${data.userId}&following=${data.following}`
    );
    if (response && response?.data) {
      dispatch(usersAction.getUserFollowingSuccess(response?.data));
    }
  } catch (err) {
    dispatch(usersAction.getUserFollowingFailed(err));
  }
};

export const getUserFollowers = (data) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `user/list?userId=${data.userId}&following=${data.following}`
    );
    if (response && response?.data) {
      dispatch(usersAction.getUserFollowersSuccess(response?.data));
    }
  } catch (err) {
    dispatch(usersAction.getUserFollowersFailed(err));
  }
};

export const getSuggestedAccount = (data) => async (dispatch) => {
  try {
    const response = await httpService.post("user/search", {}, data);
    if (response && response?.data) {
      dispatch(usersAction.getSuggestedAccountSuccess(response?.data));
    }
  } catch (err) {
    dispatch(usersAction.getSuggestedAccountFailed(err));
  }
};

export const getInvitedUsers = (data) => async (dispatch) => {
  try {
    const response = await httpService.get(
      `eventparticipant/${data.eventId}?user=${data.user}&status=${data.status}`
    );
    if (response && response?.data) {
      dispatch(usersAction.getInvitedUsersSuccess(response?.data));
    }
  } catch (err) {
    dispatch(usersAction.getInvitedUsersFailed(err));
  }
};

export const getInviteFollowers = (data) => async (dispatch) => {
  try {
    const response = await httpService.post("user/invitefollowers", {}, data);
    if (response && response?.data) {
      dispatch(usersAction.getInviteFollowersSuccess(response?.data));
    }
  } catch (err) {
    dispatch(usersAction.getInviteFollowersFailed(err));
  }
};
