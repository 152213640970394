import React from 'react';
import list from './zodiaclist.js';
import { useTranslation } from "react-i18next";
import translations from "../../Components/Translations/Translations.json";
import { Link } from "react-router-dom";
import { useState } from 'react';
import { FaChevronDown } from "react-icons/fa";




const Zodiac = () => {
  const { t } = useTranslation();
  const limit = "25";
  const storedLanguage = localStorage.getItem("lang") || "en";
  const selectedTranslation = translations[storedLanguage] || translations.en;
  const [showAll, setShowAll] = useState(false);
  const handleSeeMore = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };
  const itemsToShow = showAll ? list : list.slice(0, 4);
  return (
    <div>
      <div className='flex flex-wrap justify-center md:hidden'>
        <hr className="w-full border-grey-700" style={{ marginLeft: '50px', marginRight: '50px' }} />
        {itemsToShow.map((zodiac, index) => (
          <div key={index} className="mt-0 mb-0 text-center" style={{ margin: '15px' }}>
            <Link to="/horoscope" rel="noopener noreferrer" className="no-underline">
              <img
                src={zodiac.url}
                alt={zodiac.name}
                className="mx-auto rounded-full zodiac-image"
              />
              <h1 className="mt-2 text-sm text-black md:text-base">
                {selectedTranslation.labels[zodiac.key]}
              </h1>
            </Link>
          </div>
        ))}
        {list.length > 4 && (
          <div className="flex items-center justify-center w-full mt-1 mb-1 text-center">
            <span className='text-xs'>{showAll ? 'See Less' : 'See More'}</span>
            <FaChevronDown
              className={`transition-transform text-xs duration-300 ml-1 ${showAll ? 'rotate-180' : 'rotate-0'
                }`}
              onClick={handleSeeMore}
            />
          </div>
        )}
      </div>
      <div className="flex-wrap justify-center hidden mb-0 bg-white font-poppins md:flex">
        <hr className="w-full border-grey-700" style={{ marginLeft: "50px", marginRight: "50px" }} />
        {list.map((zodiac, index) => (
          <div key={index} className="text-center" style={{ margin: "15px" }}>
            <Link to={`/horoscope?rashi=${zodiac.title}`} className="no-underline">
              <img
                src={zodiac.url}
                alt={zodiac.name}
                className="mx-auto rounded-full zodiac-image"
              />
              <h1 className="mt-2 text-sm text-black md:text-base">{selectedTranslation.labels[zodiac.key]}</h1>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Zodiac;