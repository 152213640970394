import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import translations from "./Terms.json";
import { useNavigate } from "react-router-dom";
import HelemtComponent from "../../Components/Helmet/helmetcomponent";

const Terms = () => {
  const { t } = useTranslation();
  const storedLanguage = localStorage.getItem("lang") || "en";
  const selectedTranslation = translations[storedLanguage] || translations.en;
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  document.title = 'Bharat Calendars - Terms and Conditions'

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mb-3">
      <HelemtComponent index={"Terms"} path={""} data={""} />
      <div className="p-3 m-3" style={{ backgroundColor: "tomato" }}>
        <h1 className="text-center font-semibold text-3xl text-white">
          {t(selectedTranslation.title)}
        </h1>
      </div>
      <div className="px-8 mt-4 text-justify text-secondary">
        <p>{t(selectedTranslation.content)}</p>
      </div>
      <button className="btn btn-danger p-2" onClick={handleGoBack}>
        {t(selectedTranslation.back)}
      </button>
    </div>
  );
};

export default Terms;
