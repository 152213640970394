import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import "./ArticlesCategories.scss";

const ArticlesCategories = ({ handleArticleId }) => {
  const { i18n } = useTranslation();
  const language = i18n.language.toUpperCase();

  // const REACT_APP_DEV_ENDPOINT = process.env.REACT_APP_DEV_ENDPOINT;
  const REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const url = `${REACT_APP_ENDPOINT}articlecategory?language=${language}&status=1`;

  const { data: categories } = useQuery([`categories`, language], () =>
    axios.get(url).then((data) => data.data.data)
  );

  return (
    <div>
      <h2
        className="mt-1 mb-3"
        style={{ color: "#f16334", textAlign: "center", fontFamily: "fantasy" }}
      >
        Categories
      </h2>
      <div className="row g-3 mb-3">
        {categories &&
          categories.map((category, index) => (
            <button
              key={index}
              onClick={() => handleArticleId(category?._id)}
              className="col-4 col-lg-2 col-md-4 category-card-button"
            >
              <div className="category-card shadow">
                <img
                  alt="categories"
                  src={category.image}
                  className="category-card-img"
                />
                <div
                  className="category-title-div"
                  style={{ backgroundColor: "#f16334" }}
                >
                  <p className="category-title">{category.name}</p>
                </div>
              </div>
            </button>
          ))}
      </div>
    </div>
    // <ArticleListing articleId={articleId} />
  );
};

export default ArticlesCategories;
