import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MainMatrimony from "./MainMatrimony";
import Helmetcomponent from "../Helmet/helmetcomponent";

const Matrimony = () => {
  const { t } = useTranslation();
  window.scrollTo(0, 0);
  return (
    <div
      style={{
        padding: "5.3%",
        marginTop: "7px",
        backgroundColor: "#FFE8D8",
      }}
    >
      <Helmetcomponent index={"Matrimony"} path={""} data={""}/>
      <Container>
        <div>
          <h1
            className="route-heading"
            style={{ fontFamily: "revert", fontWeight: "bolder" }}
          >
            {t("Matrimonial")}
          </h1>
          <b>
            Launch offer price for Matrimonial advt on Bharath Calendars (upto
            March 31, 2023)
          </b>
          <p>
            1. Charges for MATRIMONIAL advertisement is Rs.99/- (Or Eq currency)
            (Including GST)
            <br />
            2. Your advt. will be online for 6 months
            <br />
            3. Please inform matrimonial matter on WhatsApp to: 8139975010
            <br />
            4. Publication is subject to approval.
            <br />
            Rights reserved by Bharath Calendars
          </p>
        </div>
        <div>
          <MainMatrimony />
        </div>
        <br />
      </Container>
    </div>
  );
};

export default Matrimony;
