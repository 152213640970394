export const ekadashiTranslations = [
    "Ekadashi",     // English
    "અગિયારશ",       // Gujarati
    "एकादशी",       // Hindi
    "एकादशी",       // Marathi
    "ಏಕಾದಶೀ",       // Kannada
    "ஏகாதசி",       // Tamil
    "একাদশী",       // Bengali
    "ഏകാദശി",       // Malayalam
    "ఏకాదశి",       // Telugu
    "ଏକାଦଶୀ",       // Odia
];

export const amavasyaTranslations = [
    "Amavasya",     // English
    "અમાસ",    // Gujarati
    "अमावस्या",    // Hindi
    "अमावस्या",    // Marathi
    "ಅಮಾವಾಸ್ಯೆ",   // Kannada
    "அமாவாசை",     // Tamil
    "চতুর্দশী",    // Bengali
    "അമാവാസി",    // Malayalam
    "అమావాస్యఏ",    // Telugu
    "ଅମାବାସ୍ୟା",   // Odia
];

export const purnimaTranslations = [
    "Purnima",      // English
    "પૂનમ",      // Gujarati
    "पूर्णिमा",     // Hindi
    "पौर्णिमा",     // Marathi
    "ಹುಣ್ಣಿಮೆ",     // Kannada
    "பௌர்ணமி",      // Tamil
    "পূর্ণিমা",     // Bengali
    "പൌർണമി",     // Malayalam
    "పౌర్ణమి",      // Telugu
    "ପୂର୍ଣ୍ଣିମା",      // Odia
];