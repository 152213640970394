import React from "react";
import { useTranslation } from "react-i18next";
import { GoCheck } from "react-icons/go";
import { IoCloseSharp } from "react-icons/io5";
import useMatchMakingFetch from "../../../Hooks/useMatchMakingFetch";

const MatchDashakootPoints = () => {
  const { t } = useTranslation();
  const url = `https://json.astrologyapi.com/v1/match_dashakoot_points`;
  const { data: dashakootPoints } = useMatchMakingFetch(url);

  let keys;
  if (dashakootPoints.length !== 0) {
    keys = Object.keys(dashakootPoints);
  }

  return (
    <>
      {dashakootPoints && (
        <>
          <div className="text-center details-header">
            {t("Match Dashakoot Points")}
          </div>
          <table className="table table-hover border-top details-body kundli-gradient-2">
            <thead>
              <tr
                style={{
                  fontFamily: "Playfair Display",
                  backgroundColor: "#483f50",
                  fontWeight: 700,
                  fontSize: "1.47vw",
                  color: "#e28e00",
                }}
              >
                <th scope="col">{t("Male Koot")}</th>
                <th scope="col">{t("Female Koot")}</th>
                <th scope="col">{t("Attributes")}</th>
                <th className="text-center" scope="col">
                  {t("total")}
                </th>
                <th className="text-center" scope="col">
                  {t("Received")}
                </th>
                <th className="text-center" scope="col">
                  {t("Matched")}
                </th>
              </tr>
            </thead>
            {keys?.map((key, index) => (
              <tbody
                key={index}
                className="montserrat-font text-white"
                style={{ fontSize: "1.17vw" }}
              >
                <tr>
                  <td>{dashakootPoints[key]?.male_koot_attribute}</td>
                  <td>{dashakootPoints[key]?.female_koot_attribute}</td>
                  <td
                    className={`text-uppercase `}
                    style={{ fontSize: key === "total" && "1.75vw" }}
                  >
                    {t(`${key}`)}
                  </td>
                  <td
                    className={`text-center `}
                    style={{ fontSize: key === "total" && "1.75vw" }}
                  >
                    {dashakootPoints[key]?.total_points}
                  </td>
                  <td
                    className={`text-center `}
                    style={{ fontSize: key === "total" && "1.75vw" }}
                  >
                    {dashakootPoints[key]?.received_points}
                  </td>
                  <td className="text-center">
                    {dashakootPoints[key]?.total_points ===
                    dashakootPoints[key]?.received_points ? (
                      <GoCheck
                        style={{
                          height: `${key === "total" ? "2.56vw" : "1.47vw"}`,
                          width: `${key === "total" ? "2.56vw" : "1.47vw"}`,
                        }}
                      />
                    ) : (
                      <IoCloseSharp
                        style={{
                          height: `${key === "total" ? "2.56vw" : "1.47vw"}`,
                          width: `${key === "total" ? "2.56vw" : "1.47vw"}`,
                        }}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </>
      )}
    </>
  );
};

export default MatchDashakootPoints;
