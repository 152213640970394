import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./i18n";
import "./styles/global.scss";
import Header from "./Components/Header/header";
import Footer from "./Components/Footer/footer";
import { useSelector } from "react-redux";
import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Router from "./router.jsx";
import { HelmetProvider } from "react-helmet-async";
import Nav from "./Newdesign/Navbar/nav.js";
import Newfooter from "./Newdesign/footer/footer.js";
import Download from "./Newdesign/download/download.js";
// import { Helmet } from "react-helmet";

const MEASUREMENT_ID = process.env.REACT_APP_GA_TRACKING;

function App() {
  const userData = useSelector((state) => state?.user);

  const paramsdata = "en.localhost";

  const [showMenu, setShowMenu] = useState(false);

  const pathname = useLocation().pathname;

  useEffect(() => {
    const currentLng = paramsdata?.split(".");
    // localStorage.setItem("lang", currentLng[0]);
  }, []);

  useEffect(() => {
    if (showMenu) {
      setShowMenu(false);
    }
  }, [pathname]);

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  // useEffect(() => {
  //   window.ga_debug = { trace: true };
  //   return () => {
  //     window.ga_debug = undefined;
  //   };
  // }, []);

  // ReactGA.initialize(MEASUREMENT_ID);

  useEffect(() => {
    // Enable debug mode
    window.ga_debug = { trace: true };

    // Initialize Google Analytics
    ReactGA.initialize(MEASUREMENT_ID);

    // Set up pageview tracking
    ReactGA.pageview(window.location.pathname + window.location.search);

    return () => {
      window.ga_debug = undefined;
    };
  }, []);

  return (
    <HelmetProvider>
      <div className="app">
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          {/* <Helmet>
        <title>Bharat Calenders App</title>
        <meta
          name="description"
          content="Discover the cultural richness and vibrant traditions of Bharat with Bharat Calendars - your go-to application for staying connected with festivals, events, and important dates across the country."
        />
      </Helmet> */}
          <Suspense fallback={null}>
            {/* <Header showMenu={showMenu} setShowMenu={setShowMenu} /> */}
            <Nav />
            <Router userData={userData} />
            {/* <Footer /> */}
            <Download />
            <Newfooter />
          </Suspense>
          <ToastContainer />
        </GoogleOAuthProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
