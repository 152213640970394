import React from "react";
import AnandadiAndTamilYoga from "./AnandadiAndTamilYoga";
import AuspiciousTimings from "./AuspiciousTimings";
import ChandrabalamAndTarabalam from "./ChandrabalamAndTarabalam";
import Festivals from "./Festivals";
import InauspiciousTimings from "./InauspiciousTimings";
import LunarMonthAndSamvat from "./LunarMonthAndSamvat";
import NivasAndShool from "./NivasAndShool";
import OtherCalendarsAndEpoch from "./OtherCalendarsAndEpoch";
import PanchakaAndLagna from "./PanchakaAndLagna";
import RashiAndNakshatra from "./RashiAndNakshatra";
import RituAndAyana from "./RituAndAyana";
import Panchang from "./Panchang";

const PanchangDetails = () => {
  return (
    <div>
      <Panchang />
      <LunarMonthAndSamvat />
      <RashiAndNakshatra />
      <RituAndAyana />
      <AuspiciousTimings />
      <InauspiciousTimings />
      <AnandadiAndTamilYoga />
      <NivasAndShool />
      <OtherCalendarsAndEpoch />
      <ChandrabalamAndTarabalam />
      <PanchakaAndLagna />
      <Festivals />
    </div>
  );
};

export default PanchangDetails;
