import React from "react";
import { useTranslation } from "react-i18next";
import useKundliFetch from "../../../Hooks/useKundliFetch";

const PitraDosha = () => {
  const { t } = useTranslation();
  const url = "https://json.astrologyapi.com/v1/pitra_dosha_report";
  const { data: pitraDosha } = useKundliFetch(url);

  return (
    <>
      {pitraDosha.length !== 0 && (
        <>
          <div className="shadow-lg rounded-5">
            <div className="text-center details-header">{t("Pitra Dosha")}</div>
            <div className="details-body kundli-gradient-3">
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white">
                  {t("What is Pitra Dosha?")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {pitraDosha?.what_is_pitri_dosha}
                </span>
              </div>
              <div className="card-body py-1 py-lg-2">
                <h5 className="montserrat-font text-white">
                  {t("Is Pitra Dosha Present?")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {pitraDosha?.is_pitri_dosha_present === true
                    ? t("yes")
                    : t("no")}
                </span>
              </div>
            </div>
          </div>
          {pitraDosha?.effects !== null && (
            <div className="shadow-lg rounded-5">
              <div className="text-center details-header">
                {pitraDosha?.effects[0]}
              </div>
              <ul className="list-group list-group-flush details-body kundli-gradient-4">
                {pitraDosha?.effects?.slice(1)?.map((remedy, index) => (
                  <li key={index} className="list-group-item bg-transparent">
                    <span className="montserrat-font fw-semibold text-white">
                      {remedy}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {pitraDosha?.rules_matched.length !== 0 && (
            <div className="shadow-lg rounded-5">
              <div className="text-center details-header">
                {t("Rule Matched")}
              </div>
              <div className="details-body kundli-gradient-2">
                {pitraDosha?.rules_matched?.map((rule, index) => (
                  <span key={index} className="montserrat-font fw-semibold">
                    {rule}
                  </span>
                ))}
              </div>
            </div>
          )}
          {pitraDosha?.remedies !== null && (
            <div className="shadow-lg rounded-5">
              <div className="text-center details-header">
                {pitraDosha?.remedies[0]}
              </div>
              <ul className="list-group list-group-flush details-body kundli-gradient-6">
                {pitraDosha?.remedies?.slice(1)?.map((remedy, index) => (
                  <li key={index} className="list-group-item bg-transparent">
                    <span className="montserrat-font fw-semibold text-white">
                      {remedy}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="shadow-lg rounded-5">
            <div className="text-center details-header">{t("Conclusion")}</div>
            <div className="details-body kundli-gradient-4">
              <span className="montserrat-font fw-semibold">
                {pitraDosha?.conclusion}
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PitraDosha;
