import React from "react";
import moment from "moment";
import useHoroscopeFetch from "../../Hooks/useHoroscopeFetch";
import HoroscopeDetails from "./HoroscopeDetails";
import HoroscopeButtons from "./HoroscopeButtons";
import { useTranslation } from "react-i18next";

const Tomorrow = () => {
  const date = moment(new Date()).add(1, "days").format("DD-MM-YYYY");
  const { t } = useTranslation();
  const { data, refetch } = useHoroscopeFetch(date);

  const handleRashi = (rashi) => {
    localStorage.setItem("rashi", rashi);
    refetch();
  };

  return (
    <div>
      <HoroscopeButtons handleRashi={handleRashi} />

      <h5 className="montserrat-font mt-3 mt-lg-5">
        {t("Tomorrow's Horoscope")} : {date}
      </h5>
      {data && <HoroscopeDetails data={data} />}
    </div>
  );
};

export default Tomorrow;
