import React from 'react';
import content from "../../content.json";
import { Helmet } from "react-helmet-async";

const Helmetcomponent = ({index,path,data}) => {
  return (
    <Helmet>
      <title>{content.prefix+content[index].title+data+content.suffix}</title>
      <meta name="description" content={content[index].description} />
      <meta property="og:title" content={content[index].ogtitle} />
      <meta property="og:type" content={content[index].ogtype} />
      <meta property="og:image" content={content[index].ogimage} />
      <meta property="og:url" content={process.env.REACT_APP_URL+content[index].ogurl+path} />
      <meta property="og:description" content={content[index].ogdescription} />
      <meta name="keywords" content={content[index].keywords} />
    </Helmet>
  )
}

export default Helmetcomponent;