import React from "react";
import { useTranslation } from "react-i18next";
import useMatchMakingFetch from "../../../Hooks/useMatchMakingFetch";

const MatchDetailedReport = () => {
  const { t } = useTranslation();
  const url = `https://json.astrologyapi.com/v1/match_making_detailed_report`;
  const { data: matchDetailedReport } = useMatchMakingFetch(url);

  let ashtakootaKeys;
  if (matchDetailedReport.length !== 0) {
    ashtakootaKeys = Object.keys(matchDetailedReport?.ashtakoota).filter(
      (key) => key !== "conclusion" && key !== "total"
    );
  }

  return (
    <>
      {matchDetailedReport && (
        <>
          <div className="montserrat-font fs-2 fw-semibold pb-2 pb-lg-4">
            {t("Ashtakoota Details")}
          </div>
          <div className="row">
            {ashtakootaKeys?.map((ashtakootaKey, index) => (
              <div key={index} className="col-12 col-md-6 col-lg-6">
                <div className="card bg-transparent rounded-5 border-0 w-100 h-100">
                  <div className="text-center details-header">
                    {t(`${ashtakootaKey}`)}
                  </div>
                  <div
                    className={`shadow details-body card-body kundli-gradient-${
                      index >= 6 ? index - 5 : index + 1
                    }`}
                  >
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item bg-transparent">
                        <span className="montserrat-font text-white">
                          {t("Description")}:{" "}
                        </span>
                        <span className="montserrat-font fw-semibold">
                          {
                            matchDetailedReport?.ashtakoota[ashtakootaKey]
                              ?.description
                          }
                        </span>
                      </li>
                      <li className="list-group-item bg-transparent">
                        <span className="montserrat-font text-white">
                          {"Male Koot"}:{" "}
                        </span>
                        <span className="montserrat-font fw-semibold">
                          {
                            matchDetailedReport?.ashtakoota[ashtakootaKey]
                              ?.male_koot_attribute
                          }
                        </span>
                      </li>
                      <li className="list-group-item bg-transparent">
                        <span className="montserrat-font text-white">
                          {"Female Koot"}:{" "}
                        </span>
                        <span className="montserrat-font fw-semibold">
                          {
                            matchDetailedReport?.ashtakoota[ashtakootaKey]
                              ?.female_koot_attribute
                          }
                        </span>
                      </li>
                      <li className="list-group-item bg-transparent">
                        <span className="montserrat-font text-white">
                          {"total"} {t("Points")}:{" "}
                        </span>
                        <span className="montserrat-font fw-semibold">
                          {
                            matchDetailedReport?.ashtakoota[ashtakootaKey]
                              ?.total_points
                          }
                        </span>
                      </li>
                      <li className="list-group-item bg-transparent">
                        <span className="montserrat-font text-white">
                          {"Received"} {t("Points")}:{" "}
                        </span>
                        <span className="montserrat-font fw-semibold">
                          {
                            matchDetailedReport?.ashtakoota[ashtakootaKey]
                              ?.received_points
                          }
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
            <div className="my-2 col-12">
              <div className="card rounded-5 bg-transparent border-0 h-100 w-100">
                <div className="text-center details-header">{t("total")}</div>
                <div className="shadow details-body card-body kundli-gradient-3">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item bg-transparent">
                      <span className="montserrat-font text-white">
                        {t("total")} {t("Points")}:{" "}
                      </span>
                      <span className="montserrat-font fw-semibold">
                        {matchDetailedReport?.ashtakoota?.total?.total_points}
                      </span>
                    </li>
                    <li className="list-group-item bg-transparent">
                      <span className="montserrat-font text-white">
                        {t("Received")} {t("Points")}:{" "}
                      </span>
                      <span className="montserrat-font fw-semibold">
                        {
                          matchDetailedReport?.ashtakoota?.total
                            ?.received_points
                        }
                      </span>
                    </li>
                    <li className="list-group-item bg-transparent">
                      <span className="montserrat-font text-white">
                        {t("Minimum Required")}:{" "}
                      </span>
                      <span className="montserrat-font fw-semibold">
                        {
                          matchDetailedReport?.ashtakoota?.total
                            ?.minimum_required
                        }
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="my-2 col-12">
              <div className="card rounded-5 bg-transparent border-0 h-100 w-100">
                <div className="text-center details-header">
                  {t("Conclusion")}
                </div>
                <div className="shadow details-body card-body kundli-gradient-4">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item bg-transparent">
                      <span className="montserrat-font text-white">
                        {t("Status")}:{" "}
                      </span>
                      <span className="montserrat-font fw-semibold">
                        {matchDetailedReport?.ashtakoota?.conclusion?.status
                          ? t("true")
                          : t("false")}
                      </span>
                    </li>
                    <li className="list-group-item bg-transparent">
                      <span className="montserrat-font text-white">
                        {t("Report")}:{" "}
                      </span>
                      <span className="montserrat-font fw-semibold">
                        {matchDetailedReport?.ashtakoota?.conclusion?.report}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <>
            <div className="text-center details-header">{t("Status")}</div>
            <div className="shadow details-body card-body kundli-gradient-5">
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white">
                  {t("Manglik Status")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {matchDetailedReport?.manglik?.status
                    ? t("true")
                    : t("false")}
                </span>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white">
                  {t("Manglik Male Percentage")}
                </h5>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-black"
                    role="progressbar"
                    style={{
                      width: `${matchDetailedReport?.manglik?.male_percentage}%`,
                      fontFamily: "montserrat",
                      fontSize: "13px",
                    }}
                    aria-valuenow={
                      matchDetailedReport?.manglik?.male_percentage
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {matchDetailedReport?.manglik?.male_percentage}%
                  </div>
                </div>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white">
                  {t("Manglik Female Percentage")}
                </h5>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-black"
                    role="progressbar"
                    style={{
                      width: `${matchDetailedReport?.manglik?.female_percentage}%`,
                      fontFamily: "montserrat",
                      fontSize: "13px",
                    }}
                    aria-valuenow={
                      matchDetailedReport?.manglik?.female_percentage
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {matchDetailedReport?.manglik?.female_percentage}%
                  </div>
                </div>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white">
                  {t("Rajju Dosha Status")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {matchDetailedReport?.rajju_dosha?.status
                    ? t("true")
                    : t("false")}
                </span>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white">
                  {t("Vedha Dosha Status")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {matchDetailedReport?.vedha_dosha?.status
                    ? t("true")
                    : t("false")}
                </span>
              </div>
              <div className="pb-2 pb-lg-4">
                <h5 className="montserrat-font text-white">
                  {t("Conclusion")}
                </h5>
                <span className="montserrat-font fw-semibold">
                  {matchDetailedReport?.conclusion?.match_report}
                </span>
              </div>
            </div>
          </>
        </>
      )}
    </>
  );
};

export default MatchDetailedReport;
