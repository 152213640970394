import "./card.scss";
import { useState } from "react";
import ShareModal from "../Modal/Share/Sharemodal";
import moment from "moment";
import { Link } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "../../img/icons/calendar.svg";
import { ReactComponent as LocationIcon } from "../../img/icons/location.svg";
import { ReactComponent as PublicIcon } from "../../img/icons/public.svg";
import { ReactComponent as PrivateIcon } from "../../img/icons/private.svg";

const Card = ({ event, index }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // console.log(event)

  return (
    <>
      <div
        className="shadow event-card p-2 mx-auto me-5 bg-light"
        style={{ borderRadius: "25px", marginBottom: "32px" }}
        key={index}
      >
        <Link to={`/event/${event?._id}`}>
          {event.isPrivate ? (
            <PrivateIcon
              className="event-icon"
              style={{ height: "30px", width: "30px" }}
            />
          ) : (
            <PublicIcon
              className="event-icon"
              style={{ height: "30px", width: "30px" }}
            />
          )}
          <img
            className="card-img-top img-fluid"
            style={{
              cursor: "pointer",
            }}
            src={event?.imageURL ? `${event?.imageURL}` : "/img/no-event-icon.jpg"}
            // src={event?.imageURL ? event?.imageURL : "/img/no-event-icon.jpg"}
            alt={event?.title}
          />
        </Link>
        <div className="card-body">
          {/* {console.log(event?.imageURL)} */}
          <div
            className={`d-flex justify-content-between ${event?.title.length < 30 && "mb-2"
              }`}
          >
            <h5 className="card-title">{event?.title}</h5>
            <div
              className="d-flex align-items-center py-4 text-center event-type"
              style={{
                background: `${index === 0
                    ? "#85D3FF"
                    : index === 1
                      ? "#7EFD5E"
                      : index === 2
                        ? "#FF8FE0"
                        : "#CBFF37"
                  }`,
              }}
            >
              {event?.categoryId?.categoryName}
            </div>
          </div>
          {event?.userStatus && (
            <div className="d-flex status-div ms-auto">
              <span className="d-flex justify-content-center align-items-center w-100 status-heading">
                Status
              </span>
              <span className="d-flex justify-content-center align-items-center w-100 status-value">
                {event?.userStatus}
              </span>
            </div>
          )}
          <p className="card-text d-flex gap-2">
            <CalendarIcon style={{ height: "15px", width: "15px" }} />
            <span className="event-time">
              {moment(event?.startDate).format("dddd | ll")} |
              {moment(event?.startDate).format(" hh:mm A")}
            </span>
          </p>
          <p className="card-text event-address d-flex gap-2">
            <LocationIcon style={{ height: "15px", width: "15px" }} />
            {event?.address2}
          </p>
        </div>
      </div>

      {/* <div key={index} className="col">
        <div className="card event-card mx-auto">
          <Link to={`/event/${event?._id}`}>
            <img
              className="card-img-top img-fluid"
              style={{
                cursor: "pointer",
              }}
              src={event?.imageURL ? event?.imageURL : "/img/no-event-icon.jpg"}
              alt="event-img"
            />
          </Link>
          <div className="card-body">
            <div
              className={`d-flex justify-content-between ${
                event?.title.length < 30 && "mb-2"
              }`}
            >
              <h5 className="card-title">{event?.title}</h5>
              <div
                className="d-flex align-items-center event-type"
                style={{
                  backgroundColor: `${
                    event?.categoryId?.categoryName === "Temple Event"
                      ? "#7efd5e"
                      : event?.categoryId?.categoryName === "Family Event"
                      ? "#FF8FE0"
                      : event?.categoryId?.categoryName === "Social Event"
                      ? "#85D3FF"
                      : "#CBFF37"
                  }`,
                }}
              >
                {event?.categoryId?.categoryName}
              </div>
            </div>
            {event?.userStatus && (
              <div className="d-flex status-div ms-auto">
                <span className="d-flex justify-content-center align-items-center w-100 status-heading">
                  Status
                </span>
                <span className="d-flex justify-content-center align-items-center w-100 status-value">
                  {event?.userStatus}
                </span>
              </div>
            )}
            <p className="card-text d-flex gap-2">
              <Calendar style={{ height: "13px", width: "13px" }} />
              <span className="event-time">
                {moment(event?.startDate).format("dddd | ll")} |
                {moment(event?.startDate).format(" hh:mm A")}
              </span>
            </p>
            <p className="card-text event-address d-flex gap-2">
              <Location style={{ height: "13px", width: "13px" }} />
              {event?.address2}
            </p>
          </div>
        </div>
      </div> */}
      <ShareModal show={show} close={handleClose} />
    </>
  );
};

export default Card;
