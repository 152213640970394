import React, { useEffect, useRef } from "react";

const GoogleAdSense = () => {
  const adsbygoogleRef = useRef(false);

  // useEffect(() => {
  //   window.adsbygoogle = window.adsbygoogle || [];
  //   window.adsbygoogle.push({});
  // }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (!adsbygoogleRef.current) {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});

  //       adsbygoogleRef.current = true;
  //     }
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <div>
      {/* <ins
        className="adsbygoogle"
        style={{ display: "block", textAlign: "center" }}
        data-ad-format="auto"
        data-ad-client="ca-pub-7950328943419082"
        data-ad-slot="9118527911"
        data-full-width-responsive="true"
        data-adtest="on"
      ></ins> */}
    </div>
  );
};

export default GoogleAdSense;
