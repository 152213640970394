import React, { useContext } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Autocomplete from "react-google-autocomplete";
import { MatchMakingContext } from "./MatchMaking";
import { useTranslation } from "react-i18next";

const MatchMakingInput = () => {
  const { formik, setMaleLocationData, setFemaleLocationData } =
    useContext(MatchMakingContext);
  const { t } = useTranslation();

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const minutes = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59,
  ];

  const { data: years } = useQuery([`data`], () =>
    axios.get("/jsons/years.json").then((data) => data.data)
  );
  return (
    <div className="d-flex gap-2">
      <form
        onSubmit={formik.handleSubmit}
        className=" d-flex flex-column gap-3 gap-lg-4 w-100"
      >
        <div className="row">
          {/* Male Input */}
          <div className="col-12 col-md-6 col-lg-6 d-flex flex-column gap-2 gap-lg-4">
            <h4
              style={{
                color: "#e28e00",
              }}
              className="montserrat-font fs-2 text-center"
            >
              {t("Male Details")}
            </h4>
            {/* Name Input */}
            <div className="kundli-input-div">
              <label htmlFor="m_name" className="form-label montserrat-font">
                {t("Name")}
              </label>
              <input
                style={{ height: "58px" }}
                type="text"
                className="form-control montserrat-font"
                id="m_name"
                name="m_name"
                placeholder={t("Enter Male Name")}
                onChange={formik.handleChange}
                value={formik.values?.m_name}
              />
              {formik.errors.m_name ? (
                <small className="text-danger fw-bold">
                  {formik.errors.m_name}
                </small>
              ) : null}
            </div>
            {/*Date of Birth */}
            <div className="kundli-input-div">
              <h6 className="form-label label montserrat-font my-1 mb-lg-3">
                {t("Date of Birth")}
              </h6>
              <div className="row g-4">
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="m_day"
                      name="m_day"
                      onChange={formik.handleChange}
                      value={formik.values.m_day}
                    >
                      {days?.map((day, index) => (
                        <option key={index}>{day}</option>
                      ))}
                    </select>
                    <label htmlFor="m_day">Day</label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="m_month"
                      name="m_month"
                      onChange={formik.handleChange}
                      value={formik.values.m_month}
                    >
                      {months?.map((month, index) => (
                        <option key={index}>{month}</option>
                      ))}
                    </select>
                    <label htmlFor="m_month">Month</label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="m_year"
                      name="m_year"
                      onChange={formik.handleChange}
                      value={formik.values.m_year}
                    >
                      {years?.map((year, index) => (
                        <option key={index}>{year.year}</option>
                      ))}
                    </select>
                    <label htmlFor="m_year">Year</label>
                  </div>
                </div>
              </div>
            </div>
            {/*Time of Birth */}
            <div className="kundli-input-div">
              <h6 className="form-label label montserrat-font my-1 mb-lg-3">
                {t("Time of Birth")}
              </h6>
              <div className="row g-4">
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="m_hour"
                      name="m_hour"
                      onChange={formik.handleChange}
                      value={formik.values.m_hour}
                    >
                      {hours?.map((hour, index) => (
                        <option key={index}>{hour}</option>
                      ))}
                    </select>
                    <label htmlFor="m_hour">Hour</label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="m_min"
                      name="m_min"
                      onChange={formik.handleChange}
                      value={formik.values.m_min}
                    >
                      {minutes?.map((minute, index) => (
                        <option key={index}>{minute}</option>
                      ))}
                    </select>
                    <label htmlFor="m_min">Minute</label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="m_clockTime"
                      name="m_clockTime"
                      onChange={formik.handleChange}
                      value={formik.values.m_clockTime}
                    >
                      <option>AM</option>
                      <option>PM</option>
                    </select>
                    <label htmlFor="m_clockTime">AM / PM</label>
                  </div>
                </div>
              </div>
            </div>
            {/*Birth Place*/}
            <div className="kundli-input-div">
              <label htmlFor="m_place" className="form-label montserrat-font">
                {t("Birth Place")}
              </label>
              <Autocomplete
                style={{ height: "74px" }}
                type="text"
                className="form-control montserrat-font"
                id="m_place"
                name="m_place"
                placeholder={t("Enter Male Birth Place")}
                apiKey={googleMapsApiKey}
                onChange={formik.handleChange}
                value={formik.values.m_place}
                onPlaceSelected={(place) => {
                  setMaleLocationData(place);
                  formik.setFieldValue("m_place", place.formatted_address);
                }}
              />
              {formik.errors.m_place ? (
                <small className="text-danger fw-bold">
                  {formik.errors.m_place}
                </small>
              ) : null}
            </div>
          </div>
          {/* Female Input */}
          <div className="col-12 col-md-6 col-lg-6 d-flex flex-column gap-2 gap-lg-4 mt-3 mt-lg-0">
            <h4
              style={{
                color: "#e28e00",
              }}
              className="montserrat-font fs-2 text-center"
            >
              {t("Female Details")}
            </h4>
            {/* Name Input */}
            <div className="kundli-input-div">
              <label htmlFor="f_name" className="form-label montserrat-font">
                {t("Name")}
              </label>
              <input
                style={{ height: "58px" }}
                type="text"
                className="form-control montserrat-font"
                id="f_name"
                name="f_name"
                placeholder={t("Enter Female Name")}
                onChange={formik.handleChange}
                value={formik.values?.f_name}
              />
              {formik.errors.f_name ? (
                <small className="text-danger fw-bold">
                  {formik.errors.f_name}
                </small>
              ) : null}
            </div>
            {/*Date of Birth */}
            <div className="kundli-input-div">
              <h6 className="form-label label montserrat-font my-1 mb-lg-3">
                {t("Date of Birth")}
              </h6>
              <div className="row g-4">
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="f_day"
                      name="f_day"
                      onChange={formik.handleChange}
                      value={formik.values.f_day}
                    >
                      {days?.map((day, index) => (
                        <option key={index}>{day}</option>
                      ))}
                    </select>
                    <label htmlFor="f_day">Day</label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="f_month"
                      name="f_month"
                      onChange={formik.handleChange}
                      value={formik.values.f_month}
                    >
                      {months?.map((month, index) => (
                        <option key={index}>{month}</option>
                      ))}
                    </select>
                    <label htmlFor="f_month">Month</label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="f_year"
                      name="f_year"
                      onChange={formik.handleChange}
                      value={formik.values.f_year}
                    >
                      {years?.map((year, index) => (
                        <option key={index}>{year.year}</option>
                      ))}
                    </select>
                    <label htmlFor="f_year">Year</label>
                  </div>
                </div>
              </div>
            </div>
            {/*Time of Birth */}
            <div className="kundli-input-div">
              <h6 className="form-label label montserrat-font my-1 mb-lg-3">
                {t("Time of Birth")}
              </h6>
              <div className="row g-4">
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="f_hour"
                      name="f_hour"
                      onChange={formik.handleChange}
                      value={formik.values.f_hour}
                    >
                      {hours?.map((hour, index) => (
                        <option key={index}>{hour}</option>
                      ))}
                    </select>
                    <label htmlFor="f_hour">Hour</label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="f_min"
                      name="f_min"
                      onChange={formik.handleChange}
                      value={formik.values.f_min}
                    >
                      {minutes?.map((minute, index) => (
                        <option key={index}>{minute}</option>
                      ))}
                    </select>
                    <label htmlFor="f_min">Minute</label>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-floating">
                    <select
                      style={{ height: "74px" }}
                      className="form-select montserrat-font"
                      id="f_clockTime"
                      name="f_clockTime"
                      onChange={formik.handleChange}
                      value={formik.values.f_clockTime}
                    >
                      <option>AM</option>
                      <option>PM</option>
                    </select>
                    <label htmlFor="f_clockTime">AM / PM</label>
                  </div>
                </div>
              </div>
            </div>
            {/*Birth Place*/}
            <div className="kundli-input-div">
              <label htmlFor="f_place" className="form-label montserrat-font">
                {t("Birth Place")}
              </label>
              <Autocomplete
                style={{ height: "74px" }}
                type="text"
                className="form-control montserrat-font"
                id="f_place"
                name="f_place"
                placeholder={t("Enter Female Birth Place")}
                apiKey={googleMapsApiKey}
                onChange={formik.handleChange}
                value={formik.values.f_place}
                onPlaceSelected={(place) => {
                  setFemaleLocationData(place);
                  formik.setFieldValue("f_place", place.formatted_address);
                }}
              />
              {formik.errors.f_place ? (
                <small className="text-danger fw-bold">
                  {formik.errors.f_place}
                </small>
              ) : null}
            </div>
          </div>
        </div>
        {/* Submit Button */}
        <div className="kundli-input-div">
          <button
            style={{
              height: "74px",
              backgroundColor: "#e28e00",
            }}
            className="btn montserrat-font text-white w-100"
            type="submit"
            disabled
            id="submit-button"
          >
            {t("Match Kundli")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MatchMakingInput;
