import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
//import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const allFestivalsContext = createContext();

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate
  .toLocaleString("default", { month: "long" })
  .toLowerCase();
//console.log(currentDate, currentYear, currentMonth);

function FestivalContext({ children }) {
  const [festivals, setFestivals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { i18n } = useTranslation();
  const data_language = i18n.language;
  //const url = `https://api.exaweb.in:3004/api/panchang/festival?year=${currentYear}&month=${currentMonth}&app_language=en&data_language=${data_language}`;
  const url = `https://api.exaweb.in:3004/api/panchang/festival?year=${
    Number.isNaN(currentYear) ? "" : currentYear
  }&month=${currentMonth}&app_language=en&data_language=${data_language}`;

  useEffect(() => {
    const fetchFestivals = async () => {
      try {
        const response = await axios.get(url);

        //console.log("API Response:", response.data);

        if (response.data && Array.isArray(response.data)) {
          const flattenedFestivals = response.data.reduce(
            (acc, day) => [
              ...acc,
              {
                date: day.date,
                festivals: day.festivals.filter(
                  (festival) => festival !== "NA"
                ),
              },
            ],
            []
          );

          //console.log("Flattened Festivals:", flattenedFestivals);

          const filteredFestivals = flattenedFestivals.filter(
            (day) => day.festivals.length > 0
          );

          //console.log("Filtered Festivals:", filteredFestivals);

          setFestivals(filteredFestivals);
        } else {
          setError(new Error("Invalid data structure"));
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchFestivals();
  }, [data_language]);

  return (
    <allFestivalsContext.Provider value={{ festivals, loading, error }}>
      {children}
    </allFestivalsContext.Provider>
  );
}

export default FestivalContext;
