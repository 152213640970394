import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PanchangContext } from "./PanchangHome";
import "./Panchang.scss";

const PanchangBasicDetails = () => {
  const panchangDetails = useContext(PanchangContext);
  const { t } = useTranslation();
  return (
    <div className="panchang-details-container bg-white">
      <div className="d-flex gap-lg-0 gap-2 justify-content-between">
        <div className="d-flex flex-column gap-2 gap-md-4 gap-lg-4">
          <div className="d-flex align-items-center gap-2 gap-lg-4 panchang-basic-details-div">
            <img src="/img/panchang/sunrise.png" alt="" />
            <div className="d-flex flex-column">
              <small className="panchang-basic-detail-name">
                {t("Sunrise")}
              </small>
              <small className="panchang-basic-detail-value">
                {panchangDetails?.Sunrise || "None"}
              </small>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-4 panchang-basic-details-div">
            <div>
              <img src="/img/panchang/sunset.png" alt="" />
            </div>
            <div className="d-flex flex-column">
              <small className="panchang-basic-detail-name">
                {t("Sunset")}
              </small>
              <small className="panchang-basic-detail-value">
                {panchangDetails?.Sunset || "None"}
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-2 gap-md-4 gap-lg-4">
          <div className="d-flex align-items-center gap-2 gap-lg-4 panchang-basic-details-div">
            <div>
              <img src="/img/panchang/moonrise.png" alt="" />
            </div>
            <div className="d-flex flex-column">
              <small className="panchang-basic-detail-name">
                {t("Moonrise")}
              </small>
              <small className="panchang-basic-detail-value">
                {panchangDetails?.Moonrise || "None"}
              </small>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-4 panchang-basic-details-div">
            <div>
              <img src="/img/panchang/moonset.png" alt="" />
            </div>
            <div className="d-flex flex-column">
              <small className="panchang-basic-detail-name">
                {t("Moonset")}
              </small>
              <small className="panchang-basic-detail-value">
                {panchangDetails?.Moonset || "None"}
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-2 gap-md-4 gap-lg-4">
          <div className="d-flex flex-column justify-content-center panchang-basic-details-div">
            <small className="panchang-basic-detail-name w-50 mx-auto">
              {t("Purnimanta")}
            </small>
            <small className="w-50 mx-auto panchang-basic-detail-value">
              {panchangDetails?.Chandramasa?.[0]?.split("-")[0] || "None"}
            </small>
          </div>
          <div className="d-flex flex-column justify-content-center panchang-basic-details-div">
            <small className="w-50 mx-auto panchang-basic-detail-name">
              {t("tithi")}
            </small>
            <small className="w-50 mx-auto panchang-basic-detail-value">
              {panchangDetails?.Tithi?.[1] || "None"}
            </small>
          </div>
        </div>
        <div className="d-flex flex-column gap-2 gap-md-4 gap-lg-4">
          <div className="d-flex flex-column justify-content-center panchang-basic-details-div">
            <small className="panchang-basic-detail-name">{t("Paksha")}</small>
            <small className="panchang-basic-detail-value">
              {panchangDetails?.Paksha || "None"}
            </small>
          </div>
          <div className="d-flex flex-column justify-content-center panchang-basic-details-div">
            <small className="panchang-basic-detail-name">
              {t("Vikram Samvat")}
            </small>
            <small className="panchang-basic-detail-value">
              {panchangDetails?.["Vikram Samvat"]
                ? panchangDetails?.["Vikram Samvat"].map((data, index) => (
                    <span key={index}>{data}</span>
                  ))
                : "None"}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanchangBasicDetails;
