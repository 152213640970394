import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import ChooseDate from "./ChooseDate";
import PanchangBasicDetails from "./PanchangBasicDetails";
import PanchangDetails from "./PanchangDetails";
import { useTranslation } from "react-i18next";
import PanchangHeading from "./PanchangHeading";
import "./Panchang.scss";
import Loading from "../Loading/Loading";
import { Helmet } from "react-helmet-async";
import Helmetcomponent from "../Helmet/helmetcomponent";

export const PanchangContext = createContext("");



const PanchangHome = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [startDate, setStartDate] = useState(new Date());
  const date = moment(startDate).format("DD/MM/YY");

  const { i18n } = useTranslation();
  const data_language = i18n.language;
  const app_language =
    localStorage.getItem("REACT_APP_LANGUAGE") === "MS"
      ? "en"
      : localStorage.getItem("REACT_APP_LANGUAGE");
  // const app_language = "en";

  const url = `${process.env.REACT_APP_ENDPOINT}panchang/daily?date=${date}&app_language=${app_language}&data_language=${data_language}`;

  const { data: panchangDetails, isLoading } = useQuery(
    [`panchangDetails`, date, app_language, data_language],
    () =>
      axios.get(url).then((data) => {
        return data.data;
      })
  );

  const handlePreviousDay = () => {
    const date = new Date(startDate.setDate(startDate.getDate() - 1));
    setStartDate(date);
  };
  const handleToday = () => {
    setStartDate(new Date());
  };
  const handleNextDay = () => {
    const date = new Date(startDate.setDate(startDate.getDate() + 1));
    setStartDate(date);
  };

  
  

  return (
    <PanchangContext.Provider value={panchangDetails}>
      <div
        style={{
          padding: "5.3%",
          marginTop: "8px",
          backgroundColor: "#FFE8D8",
        }}
      >
        <Helmetcomponent index={"Panchang"} path={""} data={""}/>
        <PanchangHeading />
        <ChooseDate
          startDate={startDate}
          setStartDate={setStartDate}
          handlePreviousDay={handlePreviousDay}
          handleToday={handleToday}
          handleNextDay={handleNextDay}
        />
        {isLoading ? <Loading></Loading> : <PanchangBasicDetails />}

        {isLoading ? <Loading></Loading> : <PanchangDetails></PanchangDetails>}
      </div>
    </PanchangContext.Provider>
  );
};

export default PanchangHome;
