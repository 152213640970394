import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "user",
  initialState: {
    userId: "",
    isLoading: false,
    isAuthenticated: false,
    userdetail: {},
    token: "",
    newUser: "",
  },
  reducers: {
    getLoginUser: (state, action) => {
      if (action?.payload?.user) {
        state.userdetail = action?.payload?.user;
        state.userId = action?.payload?.user?._id;
        state.isAuthenticated = true;
        state.token = action?.payload?.token;
        state.newUser = action?.payload?.newUser;
      } else {
        state.userdetail = action?.payload;
      }
    },
    getUpdateUser: (state, action) => {
      state.isLoading = false;
    },
    getUpdateUserSuccess: (state, action) => {
      state.isLoading = true;
      state.userdetail = action?.payload;
      state.isAuthenticated = true;
      state.token = action?.payload?.token;
      state.newUser = action?.payload?.newUser
        ? action?.payload?.newUser
        : false;
    },
    ClearLoading: (state, action) => {
      state.isLoading = false;
    },
    getUpdateUserFailed: (state, action) => {
      state.isLoading = false;
    },
    getLoginUserFailed: (state, action) => {
      state.userdetail = "";
      state.isAuthenticated = false;
      state.token = "";
      state.newUser = "";
      state.userId = "";
    },
    userLogout: (state, action) => {
      state.userdetail = "";
      state.isAuthenticated = false;
      state.token = "";
      state.newUser = "";
      state.userId = "";
    },
    addFollowingUser: (state, action) => {
      const index = state.userdetail?.followings.findIndex(
        (data) => data?.userId === action.payload?.userId
      );
      if (index !== -1) {
        state.userdetail?.followings.splice(index, 1);
      } else {
        state.userdetail?.followings?.push(action.payload);
      }
    },

    getUserSuccess: (state, action) => {
      state.userdetail = action?.payload;
    },
  },
});

export const authAction = authSlice.actions;
export default authSlice;
