import React, { useEffect } from "react";
import { useState } from "react";
import ArticleListing from "./ArticleListing";
import ArticlesCategories from "./ArticlesCategories";
import GoogleAdSense from "../../GoogleAdSense/GoogleAdSense";
import Helmetcomponent from "../Helmet/helmetcomponent";
import { use } from "i18next";

const Articles = () => {
  const [articleId, setArticleId] = useState("");
  const handleArticleId = (id) => {
    // console.log(id);
    setArticleId(id);
    
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <div
        style={{ paddingTop: "4.2%", margin: "20px" }}
        className="mx-auto w-75"
      >
        <Helmetcomponent index="Articles" path={""} data={""}/>
        <ArticlesCategories handleArticleId={handleArticleId} />
        <div style={{ margin: "8px" }}>
          <GoogleAdSense />
        </div>
        <ArticleListing articleId={articleId} />
      </div>
    </>
  );
};

export default Articles;
