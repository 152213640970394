import { AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { ImFacebook } from "react-icons/im";
import { BsGoogle } from "react-icons/bs";
import "./shareFriends.scss";

function Sharefriends() {
  return (
    <>
      <a
        href="https://www.facebook.com/sharer/sharer.php?u=http://eventz.clonegranny.com/event/great-barrier-reef-national-park-tour-9547461591"
        className="social-button social-logo-detail"
      >
        <span>
          <ImFacebook />
        </span>
      </a>
      <a
        href="https://twitter.com/intent/tweet?text=Great%20Barrier%20Reef%20National%20Park%20Tour&amp;url=http://eventz.clonegranny.com/event/great-barrier-reef-national-park-tour-9547461591"
        className="social-button social-logo-detail"
      >
        <span>
          <AiOutlineTwitter />
        </span>
      </a>
      <a
        href="http://www.linkedin.com/shareArticle?mini=true&amp;url=http://eventz.clonegranny.com/event/great-barrier-reef-national-park-tour-9547461591&amp;title=Great%20Barrier%20Reef%20National%20Park%20Tour&amp;summary=Event%20At%20-%20Great%20Barrier%20Reef,%20QLD,%20Australia"
        className="social-button social-logo-detail"
      >
        <span>
          <FaLinkedinIn />
        </span>
      </a>
      <a
        href="https://plus.google.com/share?url=http://eventz.clonegranny.com/event/great-barrier-reef-national-park-tour-9547461591"
        className="social-button social-logo-detail"
      >
        <span>
          <BsGoogle />
        </span>
      </a>
    </>
  );
}
export default Sharefriends;
