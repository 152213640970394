import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import authSlice from "./slice/authSlice";
import eventSlice from "./slice/eventSlice";
import { fileSlice } from "./slice/fileSlice";
import usersSlice from "./slice/userSlice";
import statusSlice from "./slice/statusSlice";
import eventsSlice from "./slice/eventsSlice";

const reducers = combineReducers({
  event: eventSlice.reducer,
  user: authSlice.reducer,
  file: fileSlice.reducer,
  SearchUser: usersSlice.reducer,
  status: statusSlice.reducer,
  userEvents: eventsSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;
